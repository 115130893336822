import React, {useEffect, useState} from 'react';
import {Box, Button} from '@mui/material';
import {useSelector} from 'react-redux';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';

import {loadTossPayments} from '@tosspayments/payment-sdk';
import logo from 'asset/image/logo_keewit.png';
import CryptoJS from 'crypto-js';
import {NumberUtils} from 'common/utils/NumberUtils';
import {StringUtils} from 'common/utils/StringUtils';
const clientKey = process.env.REACT_APP_TOSS_CLIENT_ID;
const tossSuccessURL = process.env.REACT_APP_TOSS_SUCCESS_URI;
const tossFailURL = process.env.REACT_APP_TOSS_FAIL_URI;

const PurchasePage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const navigate = useNavigate();
  //   const authReducer = useSelector(state => state.authReducer);
  const parameter = useParams();
  const [nameText, setNameText] = useState('');
  const [amount, setAmount] = useState(0);
  //   const [cashText, setcashText] = useState('cash test');
  //   const [koreanCashText, setKoreanCashText] = useState('');

  function getAmountText() {
    const amountComma = NumberUtils.getThousandComma(amount);
    return '￦ ' + amountComma;
  }
  function getAmountTextKorean() {
    var hanA = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구', '십'];
    var danA = [
      '',
      '십',
      '백',
      '천',
      '',
      '십',
      '백',
      '천',
      '',
      '십',
      '백',
      '천',
      '',
      '십',
      '백',
      '천',
    ];
    let result = '';
    const amountString = String(amount);
    for (let i = 0; i < amountString.length; i++) {
      let str = '';
      let han = hanA[amountString.charAt(amountString.length - (i + 1))];
      if (han != '') str += han + danA[i];
      if (i == 4) str += '만';
      if (i == 8) str += '억';
      if (i == 12) str += '조';
      result = str + result;
    }
    if (amountString != 0) {
      result = '(금' + result + '원)';
    }
    return result;
  }

  function tossPurchase(item_id, amount, purchaseName, institutionName) {
    APIUtils.LoggerLog(
      authReducer.user_id,
      'keewit',
      'info',
      window.location.pathname,
      'click_event_open_toss_popup',
    );
    const timestamp = new Date().getTime();
    const orderID = 1 + '_' + item_id + '_' + timestamp;
    loadTossPayments(clientKey).then(tossPayments => {
      tossPayments.requestPayment('카드', {
        amount: amount, // 가격
        orderId: orderID, // 주문 id
        orderName: purchaseName, // 결제 이름
        customerName: institutionName, // 판매자, 판매처 이름
        successUrl:
          tossSuccessURL +
          '?itemId=' +
          item_id +
          '&institutionName=' +
          institutionName, // 성공시 리다이렉트 주소
        failUrl: tossFailURL, // 실패시 리다이렉트 주소
      });
    });
  }
  useEffect(() => {
    try {
      const inputVal = parameter.key.toString().replaceAll('$keewi$', '/');
      // var Base64CBC = '1PY+9igfN9OHWkehA2mjQ8NDIYMkDKXuGxGd1M2WX28=';
      const iv = CryptoJS.enc.Utf8.parse('0000000000000000');
      const key = CryptoJS.enc.Utf8.parse('T2B0A1I9T2B0A1I9');
      const decrypted = CryptoJS.AES.decrypt(inputVal, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedJSON = decrypted.toString(CryptoJS.enc.Utf8);
      const parsedJSON = JSON.parse(decryptedJSON);
      if (StringUtils.getDateDiff(parsedJSON.date) < 0) {
        navigate('/404');
        return;
      }
      setNameText(parsedJSON.institution_name);
      setAmount(parsedJSON.amount);
    } catch (err) {
      navigate('/404');
    }
  }, []);
  return (
    <Box
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundColor: '#FEF9EC',
      }}
    >
      <img
        src={logo}
        alt={'logo'}
        style={{
          width: '30%',
          //   marginTop: '5rem',
        }}
      />
      <Box
        style={{
          marginTop: '2rem',
          fontSize: '3rem',
        }}
      >
        <span style={{fontWeight: 'bold'}}>{nameText}</span>
        {'님'}
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          fontSize: '3rem',
        }}
      >
        <span style={{fontWeight: 'bold', marginRight: '1rem'}}>
          {getAmountText()}
        </span>
        {getAmountTextKorean()}
      </Box>
      <Box
        style={{
          marginTop: '2rem',
        }}
      >
        <Button
          style={{
            color: 'white',
            backgroundColor: '#ED8E00',
            paddingLeft: '4rem',
            paddingRight: '4rem',
            borderRadius: '0',
            fontSize: 'calc(0.7rem + 0.5vw)',
            fontWeight: 'bold',
          }}
          onClick={() => tossPurchase(50000, amount, 'KEEwiT 결제', nameText)}
        >
          결제하기
        </Button>
      </Box>
    </Box>
  );
};
export default PurchasePage;
