export const loginBox = {
  coloredBox: function (mycolor) {
    return {
      color: 'black',
      width: '100%',
      backgroundColor: mycolor,
      paddingTop: '1rem',
      paddingBottom: '1rem',
      fontSize: 'calc(0.7rem + 0.5vw)',
    };
  },
};
export const wholeLoginPage = {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
};
export const loginTitle1 = {
  marginTop: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  fontWeight: 'normal',
  fontSize: 'calc(0.7rem + 0.7vw)',
};
export const loginTitle2 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  fontWeight: 'bold',
  fontSize: 'calc(1rem + 2vw)',
};

export const loginKakaoBox = {
  marginTop: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
export const loginNaverBox = {
  marginTop: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
export const loginGoogleBox = {
  marginTop: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '4rem',
};
