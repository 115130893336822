import React, {useState} from 'react';
import {Box} from '@mui/material';
import HeaderView from 'view/common/Header';
import FooterView from 'view/common/Footer';
import SidebarView from 'view/admin/SidebarView';
import FragmentHome from 'view/admin/FragmentHome';
import FragmentTeacher from 'view/admin/FragmentTeacher';

const MyPage = () => {
  const [fragmentStatus, setFragmentStatus] = useState(1);
  function showFragment() {
    switch (fragmentStatus) {
      case 1:
        return <FragmentHome />;
      case 2:
        return <FragmentTeacher />;

      default:
        return <FragmentHome />;
    }
  }
  function SidebarClickEvent(option) {
    setFragmentStatus(option);
  }

  return (
    <Box
      style={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HeaderView />
      <Box
        style={{
          marginLeft: '5%',
          width: '95%',
          flex: 1,
          display: 'flex',
        }}
      >
        <Box style={{backgroundColor: 'white', paddingTop: '5%'}}>
          <SidebarView
            currentFragment={fragmentStatus}
            clickEvent={SidebarClickEvent}
          />
        </Box>
        <Box
          style={{
            flex: 1,
            paddingTop: '5%',
            paddingLeft: '5%',
            paddingRight: '5%',
            backgroundColor: '#f9f9f9',
            paddingBottom: '2%',
          }}
        >
          {showFragment()}
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};

export default MyPage;
