import React, {useState, useEffect} from 'react';
import {Box, Button, IconButton} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import {StringUtils} from 'common/utils/StringUtils';
import APIUtils from 'common/utils/APIUtils';
import {useSelector} from 'react-redux';

const ItemView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const [studentList, setStudentList] = useState([]);
  function getUnsubmitStudents() {
    const getUnsubmitStudentApi = async () => {
      try {
        const response = await APIUtils.TaskStudentUnsubmit(
          authReducer.user_id,
          props.taskID,
        );
        setStudentList(
          response.data.student_list.sort((a, b) => {
            if (a.student_name > b.student_name) {
              return 1;
            }
            if (a.student_name < b.student_name) {
              return -1;
            }
            return 0;
          }),
        );
      } catch (err) {
        console.log(err);
      }
    };
    getUnsubmitStudentApi();
  }
  useEffect(() => {
    getUnsubmitStudents();
  }, []);
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box>
          <Box style={{display: 'flex', justifyContent: 'space-between'}}>
            <Box
              style={{
                padding: '1rem 0rem',
                fontSize: '1.375rem',
                fontWeight: 'bold',
                flex: 1,
                borderBottom: '1px solid #7d7d7d',
              }}
            >
              과제 미제출자
            </Box>
            <IconButton onClick={props.cancelEvent}>
              <img src={IconCancel} />
            </IconButton>
          </Box>
          <Box
            style={{
              fontWeight: 'bold',
              marginTop: '1rem',
              fontSize: '1.125rem',
            }}
          >
            {props.titleText}
          </Box>
          <Box style={{color: '#68737D', fontSize: '0.875rem'}}>
            {StringUtils.getDateKr(props.startTime) +
              '~' +
              StringUtils.getDateKr(props.endTime)}
          </Box>

          <Box
            style={{
              textAlign: 'end',
              marginTop: '1rem',
              fontSize: '0.875rem',
              color: '#4b4d4c',
            }}
          >
            {'총 '}
            <span style={{color: '#ff9900', fontWeight: '500'}}>
              {studentList.length + '명'}
            </span>
            의 미제출자가 있습니다.
          </Box>
          <Box
            style={{
              border: '2px solid #ff9900',
              marginTop: '0.5rem',
              borderRadius: '0.43rem',
              padding: '1rem',
              maxHeight: '20rem',
              overflowY: 'auto',
            }}
          >
            <Box
              style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}
            >
              {studentList.map((val, index) => {
                return (
                  <Box
                    key={'student' + index}
                    style={{
                      display: 'flex',
                      fontSize: '0.875rem',
                      fontWeight: '500',
                      alignItems: 'center',
                    }}
                  >
                    <Box>{val.student_name}</Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box style={{marginTop: '2rem', display: 'flex'}}>
            <Button
              style={{
                flex: '3',
                whiteSpace: 'nowrap',
                color: 'white',
                backgroundColor: '#ff9900',
                fontSize: '1.125rem',
                fontWeight: 'bold',
                padding: '0.9rem 0rem',
                marginLeft: '1rem',
                borderRadius: '6.25rem',
              }}
              onClick={() => props.cancelEvent()}
            >
              닫기
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  taskID: PropTypes.number,
  titleText: PropTypes.string,
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date),
  cancelEvent: PropTypes.func,
};
export default ItemView;
