import {Box, Button, tabsClasses} from '@mui/material';
import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FragmentLibraryTab1 from 'view/mypage/FragmentLibraryTab1';
import FragmentLibraryTab2 from 'view/mypage/FragmentLibraryTab2';
import APIUtils from 'common/utils/APIUtils';
import LibraryCreatePopup from 'view/popup/LibraryCreatePopup';
import LibraryGPTCreatePopup from 'view/popup/LibraryGPTCreatePopup';

export const FragmentView = () => {
  const authReducer = useSelector(state => state.authReducer);
  const libraryRef = useRef();
  function refreshLibraryList() {
    if (libraryRef.current) {
      libraryRef.current.getLibraryList();
    }
  }
  const [tabStatus, setTabStatus] = useState(1);

  const [libraryCreateVisibility, setLibraryCreateVisibility] = useState(false);
  function libraryCreateCancel() {
    setLibraryCreateVisibility(false);
  }
  function libraryCreateConfirm(
    task_name,
    task_message,
    task_janre,
    target_age,
  ) {
    const createLibraryApi = async () => {
      try {
        const response = await APIUtils.LibraryCreate(
          authReducer.user_id,
          task_name,
          task_message,
          task_janre,
          target_age,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    createLibraryApi().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        refreshLibraryList();
      }
      setLibraryCreateVisibility(false);
    });
  }
  const [libraryGPTCreateVisibility, setLibraryGPTCreateVisibility] =
    useState(false);
  function libraryGPTCreateCancel() {
    setLibraryGPTCreateVisibility(false);
  }
  function libraryGPTCreateConfirm(
    task_name,
    task_message,
    task_janre,
    target_age,
  ) {
    const createLibraryApi = async () => {
      try {
        const response = await APIUtils.LibraryCreate(
          authReducer.user_id,
          task_name,
          task_message,
          task_janre,
          target_age,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    createLibraryApi().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        refreshLibraryList();
      }
      setLibraryGPTCreateVisibility(false);
    });
  }
  function showTab() {
    switch (tabStatus) {
      case 1:
        return <FragmentLibraryTab1 ref={libraryRef} />;
      case 2:
        return <FragmentLibraryTab2 />;
      default:
        return <FragmentLibraryTab1 ref={libraryRef} />;
    }
  }

  return (
    <Box>
      <LibraryCreatePopup
        visibility={libraryCreateVisibility}
        cancelEvent={libraryCreateCancel}
        confirmEvent={libraryCreateConfirm}
      />
      <LibraryGPTCreatePopup
        visibility={libraryGPTCreateVisibility}
        cancelEvent={libraryGPTCreateCancel}
        confirmEvent={libraryGPTCreateConfirm}
      />
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>라이브러리</Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '1.75rem',
        }}
      />
      <Box style={{display: 'flex', gap: '0.8rem'}}>
        <Box
          style={{
            color: '#87929D',
            fontSize: '0.875rem',
            flex: 1,
            overflow: 'hidden',
            maxHeight: '3rem',
          }}
        >
          자주 사용하는 과제를 저장하거나 공유할 수 있습니다.
        </Box>
        <Button
          style={{
            whiteSpace: 'nowrap',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: '#49545C',
            borderRadius: '6.25rem',
            padding: '0.75rem 2.25rem',
            maxHeight: '3rem',
          }}
          onClick={() => {
            setLibraryCreateVisibility(true);
          }}
        >
          과제 생성 +
        </Button>
        <Button
          style={{
            whiteSpace: 'nowrap',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: '#49545C',
            borderRadius: '6.25rem',
            padding: '0.75rem 2.25rem',
            maxHeight: '3rem',
          }}
          onClick={() => {
            setLibraryGPTCreateVisibility(true);
          }}
        >
          GPT 과제 생성 +
        </Button>
      </Box>
      <Box style={{display: 'flex', marginTop: '2rem'}}>
        <Button
          style={{
            color: tabStatus == 1 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 1 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(1);
          }}
        >
          저장한 과제
        </Button>
        <Button
          style={{
            color: tabStatus == 2 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 2 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
            textTransform: 'none',
          }}
          onClick={() => {
            setTabStatus(2);
          }}
        >
          공유 과제
        </Button>
      </Box>
      {showTab()}
    </Box>
  );
};
export default FragmentView;
