import React, {useState, useEffect} from 'react';
import {Box, Button, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import Tagview from 'view/mypage/TagView2';
import APIUtils from 'common/utils/APIUtils';
import AgeLabelView from 'view/mypage/AgeLabelView';
import ApiLoading from 'view/common/ApiLoading2';

const ItemView = props => {
  const [loading, setLoading] = useState(true);

  const [titleText, setTitleText] = useState('');
  const [tagList, setTagList] = useState([]);
  const [ageSelect, setAgeSelect] = useState(0);
  const [janreText, setJanreText] = useState('');
  const [messageText, setMessageText] = useState('');
  const [teacherText, setTeacherText] = useState('');
  const [groupText, setGroupText] = useState('');

  function getLibraryDetail() {
    const getLibrary = async () => {
      try {
        const response = await APIUtils.LibraryDetail(props.selectedLibraryID);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getLibrary().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setTitleText(res.data.task_name);
        setAgeSelect(res.data.target_age);
        setJanreText(res.data.task_janre);
        setMessageText(res.data.task_message);
        setTagList(res.data.tags);
        setTeacherText(res.data.teacher_name);
        setGroupText(res.data.group_name);
      }
      setLoading(false);
    });
  }
  useEffect(() => {
    if (props.visibility && props.selectedLibraryID != 0) {
      getLibraryDetail();
    }
    if (!props.visibility) {
      setLoading(true);
      setTitleText('');
      setAgeSelect(0);
      setJanreText('');
      setMessageText('');
      setTagList([]);
      setTeacherText('');
      setGroupText('');
    }
  }, [props.visibility, props.selectedLibraryID]);
  if (!props.visibility) {
    return <></>;
  }
  if (loading) {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 5,
          backgroundColor: 'rgba(55,55,55,0.5)',
        }}
      >
        <ApiLoading />
      </Box>
    );
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box style={{display: 'flex', marginTop: '2rem'}}>
          <Box
            style={{
              flex: 1,
              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: 'black',
              borderBottom: '1px solid black',
            }}
          >
            {titleText}
          </Box>
          <Button
            onClick={() => {
              props.cancelEvent();
            }}
            style={{
              color: '#717171',
              fontSize: '1.5rem',
              height: '2.8rem',
            }}
          >
            ✖
          </Button>
        </Box>
        <Box style={{display: 'flex', gap: '0.5rem', marginTop: '1.5rem'}}>
          <AgeLabelView ageSelect={ageSelect} />
          <Box
            style={{
              border: 'solid 1px #D8DCDE',
              padding: '0.2rem 0.5rem',
              borderRadius: '0.5rem',
              color: '#68737D',
              whiteSpace: 'nowrap',
            }}
          >
            {janreText}
          </Box>
          <Box style={{flex: 1}} />
          <Box style={{fontWeight: 500}}>
            {teacherText +
              ' 선생님(' +
              (groupText == '' ? '개인' : groupText) +
              ')'}
          </Box>
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            border: '1px solid #34B19F',
            padding: '1rem',
            borderRadius: '0.5rem',
            whiteSpace: 'pre-wrap',
          }}
        >
          {messageText}
        </Box>

        <Box style={{marginTop: '2rem', fontWeight: 500}}>관련 태그</Box>
        <Box
          style={{
            display: 'flex',
            minHeight: '1.5rem',
            marginTop: '0.5rem',
            gap: '0.5rem',
            flexWrap: 'wrap',
          }}
        >
          {tagList.map((item, idx) => (
            <Tagview key={'tagView' + idx} tag={item} />
          ))}
        </Box>
        <Box style={{marginTop: '2rem', display: 'flex'}}>
          <Button
            disabled={tagList.length == 0}
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: tagList.length == 0 ? '#49545C' : '#35D2BB',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => {
              props.downloadEvent(tagList);
            }}
          >
            과제 저장하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  selectedLibraryID: PropTypes.number,
  cancelEvent: PropTypes.func,
  downloadEvent: PropTypes.func,
};
export default ItemView;
