import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React from 'react';
import {StringUtils} from 'common/utils/StringUtils';
import {useSelector} from 'react-redux';

export const TicketView = props => {
  const authReducer = useSelector(state => state.authReducer);
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        paddingBottom: '0.7rem',
        textAlign: 'center',
        width: '80%',
      }}
    >
      <Box style={{flex: 1}}>{props.teacher_info.name}</Box>
      <Box style={{flex: 2}}>{props.teacher_info.email}</Box>
      <Box style={{flex: 1}}>{props.teacher_info.auth_key}</Box>
      <Box style={{flex: 1}}>{props.teacher_info.student_num + '명'}</Box>
      <Box style={{flex: 1}}>
        {StringUtils.getDateString(props.teacher_info.last_login_time)}
      </Box>
      <Box style={{flex: 1}}>
        <Button
          style={{
            display:
              authReducer.user_id == props.teacher_info.user_id ? 'none' : '',
            color: '#4b4d4c',
            border: '2px solid #4b4d4c',
            borderRadius: '1rem',
            fontSize: '0.825rem',
            fontWeight: '500',
            padding: '0.2rem 1rem',
          }}
          onClick={() => {
            props.delete_teacher(props.teacher_info.user_id);
          }}
        >
          내보내기
        </Button>
      </Box>
    </Box>
  );
};
TicketView.propTypes = {
  teacher_info: PropTypes.object,
  delete_teacher: PropTypes.func,
};
export default TicketView;
