import React, {useEffect, useState} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import {StringUtils} from './StringUtils';
import {saveCloseDate} from 'common/reducer/popup';
import {useDispatch, useSelector} from 'react-redux';
import NoticePopup from 'view/popup/Notice';

export function GetRunningPopup() {
  const dispatch = useDispatch();
  const popupReducer = useSelector(state => state.popupReducer);

  const dateOnly = StringUtils.getTodayDateOnly();
  const dateToMin = StringUtils.getTodayDateMinOnly();

  const ACTIVE_POPUP = gql`
    query GetPopups{
       popups(
        filters: {
          expireAt: {gte: "${dateToMin}"}
          launchAt: {lte: "${dateToMin}"}
        }
      ) {
        content
        expireAt
        id
        launchAt
      }
    }
  `;
  const [visibility, setVisibility] = useState(false);
  const {loading, error, data} = useQuery(ACTIVE_POPUP);
  function popupCancel() {
    setVisibility(false);
  }
  function popupCancelForDay() {
    setVisibility(false);

    dispatch(
      saveCloseDate({
        close_date: dateOnly,
      }),
    );
  }

  useEffect(() => {
    if (
      loading ||
      error ||
      popupReducer.close_date == undefined ||
      popupReducer.close_date == dateOnly
    ) {
      setVisibility(false);
    } else {
      setVisibility(true);
    }
  }, [loading, error, data]);

  if (
    loading ||
    error ||
    data == undefined ||
    !visibility ||
    data.popups.length == 0
  )
    return <></>;

  return (
    <NoticePopup
      visibility={visibility}
      popupInfo={data.popups[0]}
      cancelEvent={popupCancel}
      cancelDayEvent={popupCancelForDay}
    />
  );
}
export function GetZipStatus(taskID) {
  const REPORT_STATUS = gql`
    query Task($taskId: ID!) {
      task(pk: $taskId) {
        id
        zipStatus
        zipUrl
      }
    }
  `;
  const {loading, error, data} = useQuery(REPORT_STATUS, {
    variables: {taskId: taskID},
    pollInterval: 3000,
    fetchPolicy: 'cache-and-network',
  });
  return data;
}
