import React from 'react';
import {Navigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
const PrivateWrapper = props => {
  const authReducer = useSelector(state => state.authReducer);
  const isAdmin = authReducer.is_group_admin;
  return isAdmin ? props.children : <Navigate to="/" replace />;
};
PrivateWrapper.propTypes = {
  children: PropTypes.element,
};

export default PrivateWrapper;
