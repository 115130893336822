import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';

const ItemView = props => {
  const [memoText, setMemoText] = useState('');
  const handleMemo = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 30) {
      inputVal = inputVal.substring(0, 30);
    }
    setMemoText(inputVal);
  };
  useEffect(() => {
    if (props.modifyingMemo != undefined) {
      setMemoText(props.modifyingMemo.student_memo);
    }
  }, [props.visibility]);
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box style={{display: 'flex', justifyContent: 'end'}}>
          <IconButton onClick={props.cancelEvent}>
            <img src={IconCancel} />
          </IconButton>
        </Box>
        <Box
          style={{
            fontSize: '1.75rem',
            fontWeight: 'bold',
            marginBottom: '0.5rem',
            marginTop: '1.5rem',
            color: 'black',
          }}
        >
          학생 메모 수정
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }}
        >
          <TextField
            autoComplete="off"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #EBEEF4',
                  borderRadius: '0.625rem',
                },
                '&:hover fieldset': {
                  border: '1px solid #EBEEF4',
                  borderRadius: '0.625rem',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid #EBEEF4',
                  borderRadius: '0.625rem',
                },
              },
            }}
            variant="outlined"
            fullWidth
            rows={3}
            label={memoText == '' ? '학생 정보를 간단하게 작성해 주세요.' : ''}
            InputLabelProps={{
              style: {
                color: '#A8B1B7',
              },
              shrink: false,
            }}
            InputProps={{
              style: {
                backgroundColor: '#F7F8FA',
                fontWeight: 500,
              },
            }}
            multiline
            value={memoText}
            onChange={handleMemo}
            onKeyDown={e => {
              if (e.key == 'Enter') {
                e.preventDefault();
              }
            }}
          ></TextField>
          <Box
            style={{
              color: '#A8B1B7',
            }}
          >
            <span style={{marginRight: '0.2rem', color: '#ff9900'}}>
              {memoText.length}
            </span>
            {'/30'}
          </Box>
        </Box>
        <Box
          style={{marginTop: '1rem', display: 'flex', justifyContent: 'center'}}
        >
          <Button
            fullWidth
            style={{
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: '#35D2BB',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 6rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => props.confirmEvent(memoText)}
          >
            변경하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  cancelEvent: PropTypes.func,
  confirmEvent: PropTypes.func,
  modifyingMemo: PropTypes.object,
};
export default ItemView;
