import {Box, Button} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState} from 'react';
import Pagination from 'view/mypage/Pagination';
import APIUtils from 'common/utils/APIUtils';
import TaskView2 from './TaskView2';
import TaskCreatePopup from 'view/popup/TaskCreatePopup';
// import TaskRunningPopup from 'view/popup/TaskRunningPopup';
import TaskModifyPopup from 'view/popup/TaskModifyPopup';
import TaskRemovePopup from 'view/popup/TaskRemovePopup';
import {StringUtils} from 'common/utils/StringUtils';
import PropTypes from 'prop-types';
import {useNavigate, useLocation} from 'react-router-dom';
import NoticketPopup from 'view/popup/NoTicket';
import ApiLoading from 'view/common/ApiLoading2';

export const FragmentView = props => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);

  const [loading, setLoading] = useState(true);
  const [taskList, setTaskList] = useState([]);
  const [originalTaskList, setOriginalTaskList] = useState([]);
  const countPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [tabStatus, setTabStatus] = useState(1);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [removingTask, setRemovingTask] = useState(undefined);

  const [allCount, setAllCount] = useState(0);
  const [waitingCount, setWaitingCount] = useState(0);
  const [runningCount, setRunningCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);

  const [passStatus, setPassStatus] = useState('none');

  const [noticketVisibility, setNoticketVisibility] = useState(false);
  function noticketCancel() {
    setNoticketVisibility(false);
  }
  function noticketComplete() {
    APIUtils.LoggerLog(
      authReducer.user_id,
      'keewit',
      'info',
      window.location.pathname,
      'navigate_purchase',
    );
    setNoticketVisibility(false);
    navigate('/purchase');
  }

  const [taskRemoveVisibility, setTaskRemoveVisibility] = useState(false);
  function taskRemoveCancel() {
    setTaskRemoveVisibility(false);
  }
  function taskRemoveConfirm() {
    const deleteTaskApi = async () => {
      try {
        const response = await APIUtils.TaskDelete(
          authReducer.user_id,
          removingTask.task_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    deleteTaskApi().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        getTaskList();
      }
      setTaskRemoveVisibility(false);
    });
  }

  const [taskCreateVisibility, setTaskCreateVisibility] = useState(false);
  function taskCreateCancel() {
    setTaskCreateVisibility(false);
  }
  function taskCreateConfirm(
    task_name,
    task_message,
    task_janre,
    task_start_time,
    task_end_time,
    task_type,
    class_id_list,
    student_id_list,
    length_check,
    min_length,
    max_length,
    keyword_check,
    keyword,
    pre_keewichat,
  ) {
    const createTaskApi = async () => {
      try {
        const response = await APIUtils.TaskCreate(
          authReducer.user_id,
          task_name,
          task_message,
          task_janre,
          StringUtils.getFormattedString(task_start_time),
          StringUtils.getFormattedString(task_end_time),
          task_type,
          class_id_list,
          student_id_list,
          length_check,
          min_length,
          max_length,
          keyword_check,
          keyword,
          pre_keewichat,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    createTaskApi().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        getTaskList();
      }
      setTaskCreateVisibility(false);
    });
  }

  const [taskModifyVisibility, setTaskModifyVisibility] = useState(false);
  function taskModifyCancel() {
    setTaskModifyVisibility(false);
  }
  function taskModifyConfirm(
    task_name,
    task_message,
    task_janre,
    task_start_time,
    task_end_time,
    task_type,
    include_class_id_list,
    exclude_class_id_list,
    include_student_id_list,
    exclude_student_id_list,
    length_check,
    min_length,
    max_length,
    keyword_check,
    keyword,
    pre_keewichat,
  ) {
    const modifyTaskApi = async inputTaskID => {
      try {
        const response = await APIUtils.TaskModify(
          authReducer.user_id,
          inputTaskID,
          task_name,
          task_message,
          task_janre,
          StringUtils.getFormattedString(task_start_time),
          StringUtils.getFormattedString(task_end_time),
          task_type,
          include_class_id_list,
          exclude_class_id_list,
          include_student_id_list,
          exclude_student_id_list,
          length_check,
          min_length,
          max_length,
          keyword_check,
          keyword,
          pre_keewichat,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    modifyTaskApi(selectedTask.task_id).then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        getTaskList();
      }
      setTaskModifyVisibility(false);
    });
  }

  // const [taskRunningVisibility, setTaskRunningVisibility] = useState(false);
  // function taskRunningCancel() {
  //   setTaskRunningVisibility(false);
  // }
  // function taskRunningConfirm(
  //   include_class_id_list,
  //   exclude_class_id_list,
  //   include_student_id_list,
  //   exclude_student_id_list,
  // ) {
  //   const modifyTaskApi = async inputTask => {
  //     try {
  //       const response = await APIUtils.TaskModify(
  //         authReducer.user_id,
  //         inputTask.task_id,
  //         inputTask.task_name,
  //         inputTask.task_message,
  //         inputTask.task_janre,
  //         inputTask.start_time,
  //         inputTask.end_time,
  //         inputTask.task_type,
  //         include_class_id_list,
  //         exclude_class_id_list,
  //         include_student_id_list,
  //         exclude_student_id_list,
  //       );
  //       return response;
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   modifyTaskApi(selectedTask).then(res => {
  //     if (res.status == 200 && res.data.ret_code == 1000) {
  //       getTaskList();
  //     }
  //     setTaskRunningVisibility(false);
  //   });
  // }

  // function endTask() {
  //   const endTaskApi = async inputTaskID => {
  //     try {
  //       const response = await APIUtils.TaskEnd(
  //         authReducer.user_id,
  //         inputTaskID,
  //       );
  //       return response;
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   endTaskApi(selectedTask.task_id).then(res => {
  //     if (res.status == 200 && res.data.ret_code == 1000) {
  //       getTaskList();
  //     }
  //     setTaskRunningVisibility(false);
  //   });
  // }

  function clickTaskDetail(newTask) {
    setSelectedTask(newTask);
    if (newTask.status == '종료') {
      props.finishedTaskClickEvent(newTask.task_id);
    } else if (newTask.status == '대기') {
      setTaskModifyVisibility(true);
    } else {
      // setTaskRunningVisibility(true);
      props.runningTaskClickEvent(newTask.task_id);
    }
  }

  function paginationClickEvent(page) {
    setCurrentPage(page);
  }
  function getTaskList() {
    const getTask = async () => {
      try {
        const response = await APIUtils.TaskView(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getTask().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        const tempTaskList = res.data.task_list;
        setOriginalTaskList(tempTaskList);
        setAllCount(tempTaskList.length);
        setWaitingCount(
          tempTaskList.filter(val => val.status == '대기').length,
        );
        setRunningCount(
          tempTaskList.filter(val => val.status == '진행중').length,
        );
        setClosedCount(tempTaskList.filter(val => val.status == '종료').length);
      }
      setLoading(false);
    });
  }

  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.PassCheck(authReducer.user_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          if (response.data.status != 'none') {
            setPassStatus(response.data.status);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }
  function removeTask(delTask) {
    setRemovingTask(delTask);
    setTaskRemoveVisibility(true);
  }
  useEffect(() => {
    getTaskList();
    getPassInfo();
  }, [tabStatus]);
  useEffect(() => {
    let filteredTask = [];
    if (tabStatus == 1) {
      filteredTask = originalTaskList;
    } else if (tabStatus == 2) {
      filteredTask = originalTaskList.filter(value => value.status == '대기');
    } else if (tabStatus == 3) {
      filteredTask = originalTaskList.filter(value => value.status == '진행중');
    } else if (tabStatus == 4) {
      filteredTask = originalTaskList.filter(value => value.status == '종료');
    }
    filteredTask.sort(function (a, b) {
      if (a.status == '진행중' && b.status != '진행중') return -1;
      if (a.status != '진행중' && b.status == '진행중') return 1;
      if (a.status == '대기' && b.status != '대기') return -1;
      if (a.status != '대기' && b.status == '대기') return 1;
      return new Date(b.end_time) - new Date(a.end_time);
    });
    setTaskList(filteredTask);
    setTotalPage(Math.floor((filteredTask.length - 1) / countPerPage + 1));
  }, [originalTaskList, tabStatus]);
  if (loading) {
    return <ApiLoading />;
  }
  return (
    <Box>
      <TaskCreatePopup
        visibility={taskCreateVisibility}
        cancelEvent={taskCreateCancel}
        confirmEvent={taskCreateConfirm}
      />
      <TaskModifyPopup
        visibility={taskModifyVisibility}
        task_id={selectedTask?.task_id ?? undefined}
        startEditable={true}
        cancelEvent={taskModifyCancel}
        confirmEvent={taskModifyConfirm}
      />
      {/* <TaskRunningPopup
        visibility={taskRunningVisibility}
        task_id={selectedTask?.task_id ?? undefined}
        endEvent={() => {
          endTask();
        }}
        cancelEvent={taskRunningCancel}
        confirmEvent={taskRunningConfirm}
      /> */}
      <TaskRemovePopup
        visibility={taskRemoveVisibility}
        task={removingTask}
        cancelEvent={taskRemoveCancel}
        confirmEvent={taskRemoveConfirm}
      />
      <NoticketPopup
        visibility={noticketVisibility}
        cancelEvent={noticketCancel}
        completeEvent={noticketComplete}
      />
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>과제 관리</Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '1.75rem',
        }}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box style={{color: '#87929D', fontSize: '0.875rem'}}>
          종료된 과제는 1년동안 저장됩니다.
        </Box>
        <Button
          style={{
            whiteSpace: 'nowrap',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: '#49545C',
            borderRadius: '6.25rem',
            padding: '0.75rem 2.25rem',
          }}
          onClick={() => {
            if (passStatus == 'none') {
              setNoticketVisibility(true);
            } else {
              setTaskCreateVisibility(true);
            }
          }}
        >
          과제 생성 +
        </Button>
      </Box>
      <Box
        style={{
          display: 'flex',
          marginTop: '2rem',
          borderBottom: '1px solid #F4F6F6',
        }}
      >
        <Button
          style={{
            color: tabStatus == 1 ? '#262C31' : '#ADB4BA',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            borderBottom: tabStatus == 1 ? '2px solid black' : '',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(1);
          }}
        >
          전체
          <span
            style={{
              marginLeft: '0.5rem',
              color: tabStatus == 1 ? '#35D2BB' : '',
            }}
          >
            {allCount}
          </span>
        </Button>
        <Button
          style={{
            color: tabStatus == 2 ? '#262C31' : '#ADB4BA',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            borderBottom: tabStatus == 2 ? '2px solid black' : '',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(2);
          }}
        >
          대기
          <span
            style={{
              marginLeft: '0.5rem',
              color: tabStatus == 2 ? '#35D2BB' : '',
            }}
          >
            {waitingCount}
          </span>
        </Button>
        <Button
          style={{
            color: tabStatus == 3 ? '#262C31' : '#ADB4BA',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            borderBottom: tabStatus == 3 ? '2px solid black' : '',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(3);
          }}
        >
          진행중
          <span
            style={{
              marginLeft: '0.5rem',
              color: tabStatus == 3 ? '#35D2BB' : '',
            }}
          >
            {runningCount}
          </span>
        </Button>
        <Button
          style={{
            color: tabStatus == 4 ? '#262C31' : '#ADB4BA',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            borderBottom: tabStatus == 4 ? '2px solid black' : '',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(4);
          }}
        >
          종료
          <span
            style={{
              marginLeft: '0.5rem',
              color: tabStatus == 4 ? '#35D2BB' : '',
            }}
          >
            {closedCount}
          </span>
        </Button>
      </Box>
      <Box>
        {taskList
          .filter((value, index) => {
            if (Math.floor(index / countPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((value, index) => {
            return (
              <TaskView2
                key={'task' + index}
                detailEvent={clickTaskDetail}
                removeEvent={removeTask}
                task={value}
              />
            );
          })}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};
FragmentView.propTypes = {
  finishedTaskClickEvent: PropTypes.func,
  runningTaskClickEvent: PropTypes.func,
};
export default FragmentView;
