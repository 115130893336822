import React, {useState} from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Switch,
  Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import {styled} from '@mui/material/styles';

const ItemView = props => {
  const [tabStatus, setTabStatus] = useState(1);

  function selectableList() {
    if (tabStatus == 2) {
      return (
        <Box style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
          {props.studentList.map((val, index) => {
            return (
              <Box
                key={'student' + index}
                style={{
                  display: 'flex',
                  fontSize: '0.875rem',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                <Checkbox
                  checked={props.selectedStudentList.includes(val.student_id)}
                  onChange={() => {
                    props.studentCheckEvent(index);
                  }}
                  style={{
                    color: '#35D2BB',
                    padding: '0',
                  }}
                />
                <Box>{val.student_name}</Box>
              </Box>
            );
          })}
        </Box>
      );
    }
    return (
      <Box style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
        {props.classList.map((val, index) => {
          return (
            <Box
              key={'class' + index}
              style={{
                display: 'flex',
                fontSize: '0.875rem',
                alignItems: 'center',
                gap: '0.5rem',
              }}
            >
              <Checkbox
                checked={props.selectedClassList.includes(val.class_id)}
                onChange={() => {
                  props.classCheckEvent(index);
                }}
                style={{
                  color: '#35D2BB',
                  padding: '0',
                }}
              />
              <Box>{val.class_name}</Box>
            </Box>
          );
        })}
      </Box>
    );
  }

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '1rem',
          gap: '5rem',
        }}
      >
        <Box
          style={{
            flex: 1,
            fontSize: '1.5rem',
            fontWeight: 'bold',
            borderBottom: '2px solid #A7A7A7',
            paddingRight: '1rem',
          }}
        >
          {props.titleText}
        </Box>
        <IconButton onClick={props.cancelEvent}>
          <img src={IconCancel} />
        </IconButton>
      </Box>

      <Box style={{display: 'flex', alignItems: 'end', marginTop: '1rem'}}>
        <Box
          style={{
            cursor: 'pointer',
            borderBottom:
              tabStatus == 1 ? '2px solid black' : '2px solid #A7A7A7',
            padding: '0.5rem 2rem',
            color: tabStatus == 1 ? 'black' : ' #A7A7A7',
          }}
          onClick={() => {
            setTabStatus(1);
          }}
        >
          class 목록
        </Box>
        <Box
          style={{
            cursor: 'pointer',
            borderBottom:
              tabStatus == 2 ? '2px solid black' : '2px solid #A7A7A7',
            padding: '0.5rem 2rem',
            color: tabStatus == 2 ? 'black' : ' #A7A7A7',
          }}
          onClick={() => {
            setTabStatus(2);
          }}
        >
          학생 목록
        </Box>
        <Box style={{flex: 1, textAlign: 'end', color: '#35d2bb'}}>
          {props.totalStudentCount + '명'}
        </Box>
      </Box>
      <Box
        style={{
          border: '2px solid #35d2bb',
          marginTop: '0.5rem',
          borderRadius: '0.43rem',
          padding: '1rem',
          maxHeight: '20rem',
          overflowY: 'auto',
        }}
      >
        {selectableList()}
      </Box>
      <Box style={{marginTop: '2rem', display: 'flex'}}>
        <Button
          style={{
            flex: '1',
            whiteSpace: 'nowrap',
            color: '#35D2BB',
            backgroundColor: 'white',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            padding: '0.9rem 0rem',
            borderRadius: '6.25rem',
            border: '1px solid #35D2BB',
          }}
          onClick={() => props.previousEvent()}
        >
          {'< 이전'}
        </Button>
        <Button
          disabled={
            props.selectedClassList.length == 0 &&
            props.selectedStudentList.length == 0
          }
          style={{
            flex: '3',
            whiteSpace: 'nowrap',
            color: 'white',
            backgroundColor:
              props.selectedClassList.length == 0 &&
              props.selectedStudentList.length == 0
                ? '#A8B1B7'
                : '#35D2BB',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            padding: '0.9rem 0rem',
            marginLeft: '1rem',
            borderRadius: '6.25rem',
          }}
          onClick={() => props.confirmEvent()}
        >
          과제 생성
        </Button>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  titleText: PropTypes.string,
  totalStudentCount: PropTypes.number,
  classList: PropTypes.array,
  selectedClassList: PropTypes.array,
  classCheckEvent: PropTypes.func,
  studentList: PropTypes.array,
  selectedStudentList: PropTypes.array,
  studentCheckEvent: PropTypes.func,
  previousEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
  confirmEvent: PropTypes.func,
};
export default ItemView;
