import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, TextField} from '@mui/material';
import APIUtils from 'common/utils/APIUtils';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import {useDispatch, useSelector} from 'react-redux';

const ItemView = props => {
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);
  const [codeText, setCodeText] = useState('');
  const [codeState, setCodeState] = useState(0);

  const [groupAdminEmail, setAdminEmail] = useState('');
  const [groupName, setGroupName] = useState('');
  const handleCode = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setCodeText(inputVal);
    setCodeState(0);
  };
  function validateCode() {
    const validateResult = async inputCode => {
      try {
        const response = await APIUtils.GroupCodeInfo(inputCode);
        if (response.data.ret_code == 1000) {
          setCodeState(1);
          setAdminEmail(response.data.group_admin_email ?? '미배정');
          setGroupName(response.data.group_name);
        } else {
          // UNKNOWN_GROUP: 2501,
          setCodeState(2);
        }
      } catch (err) {
        console.log(err);
      }
    };
    validateResult(codeText);
  }
  useEffect(() => {
    setCodeText('');
    setCodeState(0);
  }, [props.visibility]);
  function groupEnter() {
    const enterGroup = async inputCode => {
      try {
        const res = await APIUtils.GroupEnter(authReducer.user_id, inputCode);
        return res;
      } catch (err) {
        console.log(err);
      }
    };

    enterGroup(codeText).then(r => {
      props.confirmEvent();
    });
  }
  function showValidateResult() {
    if (codeState == 2) {
      return (
        <Box style={{marginTop: '1.5rem'}}>
          <Box>{'잘못된 코드입니다.'}</Box>
        </Box>
      );
    }
    if (codeState == 1) {
      return (
        <Box style={{marginTop: '1.5rem'}}>
          <Box style={{fontWeight: 'bold'}}>{'[그룹 정보]'}</Box>
          <Box>{groupName}</Box>
          <Box>{'그룹 관리자 : ' + groupAdminEmail}</Box>
        </Box>
      );
    }
  }

  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box style={{display: 'flex', justifyContent: 'end'}}>
          <IconButton onClick={props.cancelEvent}>
            <img src={IconCancel} />
          </IconButton>
        </Box>
        <Box
          style={{
            fontSize: '1.75rem',
            fontWeight: 'bold',
            marginBottom: '0.5rem',
            marginTop: '0.5rem',
          }}
        >
          그룹 등록하기
        </Box>
        <Box style={{color: '#68737D', fontSize: '0.875rem'}}>
          그룹 관리자에게 전달받은 그룹 코드를 입력해 주세요.
        </Box>
        <Box
          style={{
            width: '100%',
            height: '1px',
            backgroundColor: '#D8DCDE',
            marginTop: '1rem',
          }}
        ></Box>
        <Box style={{marginTop: '1.5rem', fontWeight: 'bold'}}>
          코드 입력 <span style={{color: '#35D2BB'}}>*</span>
        </Box>
        <Box style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
          <TextField
            autoComplete="off"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #EBEEF4',
                  borderRadius: '0.625rem',
                },
                '&:hover fieldset': {
                  border: '1px solid #EBEEF4',
                  borderRadius: '0.625rem',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid #EBEEF4',
                  borderRadius: '0.625rem',
                },
              },
            }}
            variant="outlined"
            fullWidth
            label={codeText == '' ? '그룹 코드를 입력하세요.' : ''}
            InputLabelProps={{
              style: {
                color: '#A8B1B7',
              },
              shrink: false,
            }}
            InputProps={{
              style: {
                backgroundColor: '#F7F8FA',
                fontWeight: 500,
              },
            }}
            value={codeText}
            onChange={handleCode}
          ></TextField>
          <Button
            style={{
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: '#35D2BB',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 3.5rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => validateCode()}
          >
            코드 확인
          </Button>
        </Box>
        {showValidateResult()}
        <Box style={{marginTop: '3rem'}}>
          <Button
            disabled={codeState != 1}
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: codeState != 1 ? '#A8B1B7' : '#35D2BB',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => groupEnter()}
          >
            등록하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  confirmEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
};
export default ItemView;
