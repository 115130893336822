import React from 'react';
import {Box, Button} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';
import {useNavigate} from 'react-router-dom';
import PDFTooltip from 'view/common/PDFTooltip';
import exclamation from 'asset/image/icon_exclamation.png';
import PropTypes from 'prop-types';
const ItemView = props => {
  const navigate = useNavigate();
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '28rem',
          textAlign: 'left',
          borderRadius: '20px',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box style={{textAlign: 'right'}}>
          <Button
            onClick={() => {
              props.cancelEvent();
            }}
            style={{color: '#717171', fontSize: '1.5rem'}}
          >
            ✖
          </Button>
        </Box>
        <Box>
          {
            <img
              src={exclamation}
              alt={'exclamation1'}
              style={{height: '30px'}}
            />
          }
        </Box>
        <Box
          style={{
            fontSize: '1.7rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            marginTop: '0.5rem',
          }}
        >
          로그인이 필요합니다.
        </Box>
        <Box style={{fontSize: '1.1rem'}}>
          결과를 편집하거나 저장하려면 로그인이 필요합니다. 로그인하면 리포트를
          편집하거나 PDF로 소장·배포하실 수 있습니다.
        </Box>
        <Box style={{marginTop: '1rem'}}>
          <PDFTooltip color={'#ff6928'} />
        </Box>
        <Box
          style={{
            borderTop: '2px dotted #717171',
            marginTop: '2rem',
          }}
        ></Box>
        <Button
          style={{
            marginTop: '1rem',
            backgroundColor: Palette.green8,
            width: '100%',
            color: 'white',
            fontSize: 'calc(1rem + 0.2vw)',
            fontWeight: 'bold',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            borderRadius: '10px',
          }}
          onClick={() => {
            props.completeEvent();
          }}
        >
          간편 로그인/가입
        </Button>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  completeEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
};
export default ItemView;
