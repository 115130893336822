import React, {useEffect, useState} from 'react';
import {Box, Button, Select, MenuItem, TextField} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '28rem',
          textAlign: 'left',
          borderRadius: '20px',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box
          style={{
            fontSize: '1.7rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            marginTop: '0.5rem',
          }}
        >
          뒤로 가기
        </Box>
        <Box>뒤로 가시면 작성하신 내용이 전부 사라집니다.</Box>

        <Box style={{borderTop: '1px dotted #717171', marginTop: '1rem'}}></Box>
        <Box
          style={{
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            style={{
              border: '1px solid black',
              width: '47%',
              color: 'black',
              fontSize: 'calc(1rem + 0.2vw)',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
            }}
            onClick={() => {
              props.cancelEvent();
            }}
          >
            취소
          </Button>
          <Button
            style={{
              backgroundColor: Palette.green8,
              width: '47%',
              color: 'white',
              fontSize: 'calc(1rem + 0.2vw)',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
            }}
            onClick={() => {
              props.confirmEvent();
            }}
          >
            확인
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  cancelEvent: PropTypes.func,
  confirmEvent: PropTypes.func,
};
export default ItemView;
