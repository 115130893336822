import React from 'react';
import styled, {keyframes} from 'styled-components';
import PropTypes from 'prop-types';

const animation = keyframes`
0% {
  transform: translate(0);
}

100% {
  transform: translate(-680%);
}
`;

const StyledWrapper = styled.div`
  animation: ${animation} 30s linear infinite backwards;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15rem;
`;
const Box = ({children, ...rest}) => {
  return <StyledWrapper {...rest}>{children}</StyledWrapper>;
};
Box.propTypes = {
  children: PropTypes.node,
};
export default Box;
