import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React from 'react';
import {StringUtils} from 'common/utils/StringUtils';
import AgeLabelView from 'view/mypage/AgeLabelView';

export const LibraryView = props => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        paddingBottom: '1.7rem',
        borderBottom: '2px solid #A8B1B7',
      }}
    >
      <Box style={{display: 'flex', flexDirection: 'column', gap: '0.75rem'}}>
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <AgeLabelView ageSelect={props.library.target_age} />
          <Box
            style={{
              border: 'solid 1px #D8DCDE',
              padding: '0.2rem 0.5rem',
              borderRadius: '0.5rem',
              color: '#68737D',
              whiteSpace: 'nowrap',
            }}
          >
            {props.library.task_janre}
          </Box>
        </Box>
        <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>
          {props.library.task_name}
        </Box>
        <Box style={{display: 'flex', gap: '0.5rem', color: '#636C73'}}>
          <span>
            {props.library.teacher_name +
              ' 선생님(' +
              (props.library.group_name == ''
                ? '개인'
                : props.library.group_name) +
              ')'}
          </span>
          <span>{'|'}</span>
          <span>{StringUtils.getDateString(props.library.shared_at)}</span>
          <span>{'|'}</span>
          <span>{'다운로드 수 ' + props.library.download_count}</span>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <Button
          style={{
            color: '#262C31',
            fontSize: '0.875rem',
            border: '1.5px solid #E9EBED',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1.5rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            props.detailEvent(props.library);
          }}
        >
          {'자세히 보기'}
        </Button>
      </Box>
    </Box>
  );
};
LibraryView.propTypes = {
  library: PropTypes.object,
  detailEvent: PropTypes.func,
};
export default LibraryView;
