import {Box, Button} from '@mui/material';
import {useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import APIUtils from 'common/utils/APIUtils';
import ClassCreatePopup from 'view/popup/ClassCreatePopup';
import ClassRemovePopup from 'view/popup/ClassRemovePopup';
import ClassDetailPopup from 'view/popup/ClassDetailPopup';
import ToastPopup from 'view/popup/ToastPopup';
import ClassViewItem from './ClassViewItem';

export const FragmentView = () => {
  const authReducer = useSelector(state => state.authReducer);
  const [classList, setClassList] = useState([]);

  const [selectedClass, setSelectedClass] = useState(0);

  const [removeClassVisibility, setRemoveClassVisibility] = useState(false);
  function removeClassCancel() {
    setRemoveClassVisibility(false);
  }
  const [createClassVisibility, setCreateClassVisibility] = useState(false);
  function createClassCancel() {
    setCreateClassVisibility(false);
  }
  const [detailClassVisibility, setDetailClassVisibility] = useState(false);
  function detailClassCancel() {
    setDetailClassVisibility(false);
  }
  const [toastVisibility, setToastVisibility] = useState(false);

  function createClass(className, classMemo) {
    const createClassApi = async (classInput, memo) => {
      try {
        const response = await APIUtils.ClassCreate(
          authReducer.user_id,
          classInput,
          memo,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    createClassApi(className, classMemo).then(res => {
      setCreateClassVisibility(false);
      getClassInformation();
    });
  }
  function removeClass() {
    const removeClassApi = async input_class_id => {
      try {
        const response = await APIUtils.ClassDelete(
          authReducer.user_id,
          input_class_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const class_id = classList[selectedClass].class_id;
    removeClassApi(class_id).then(res => {
      if (res.data.ret_code == 2602) {
        setToastVisibility(true);
        setTimeout(function () {
          setToastVisibility(false);
        }, 3000);
      }
      setRemoveClassVisibility(false);
      getClassInformation();
    });
  }
  function modifyClass(className, classMemo) {
    const modifyClassApi = async (
      input_class_id,
      input_class_name,
      input_class_memo,
    ) => {
      try {
        const response = await APIUtils.ClassModify(
          authReducer.user_id,
          input_class_id,
          input_class_name,
          input_class_memo,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const class_id = classList[selectedClass].class_id;
    modifyClassApi(class_id, className, classMemo).then(res => {
      setDetailClassVisibility(false);
      getClassInformation();
    });
  }
  function getClassInformation() {
    const getClassInfo = async () => {
      try {
        const response = await APIUtils.ClassView(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getClassInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        let sortedClass = [...res.data.class_list];
        sortedClass.sort((a, b) => {
          if (a.class_name > b.class_name) {
            return 1;
          }
          if (a.class_name < b.class_name) {
            return -1;
          }
          return 0;
        });
        setClassList(sortedClass);
      }
    });
  }
  // function removeClass() {}
  useEffect(() => {
    getClassInformation();
  }, []);

  return (
    <Box style={{marginTop: '0.5rem'}}>
      <ClassCreatePopup
        visibility={createClassVisibility}
        cancelEvent={createClassCancel}
        classList={classList.map(val => val.class_name)}
        createEvent={createClass}
      />
      <ClassRemovePopup
        visibility={removeClassVisibility}
        cancelEvent={removeClassCancel}
        removeEvent={removeClass}
      />
      <ClassDetailPopup
        visibility={detailClassVisibility}
        cancelEvent={detailClassCancel}
        classList={classList
          .filter((val, idx) => idx != selectedClass)
          .map(val => val.class_name)}
        modifyEvent={modifyClass}
        selectedClass={classList[selectedClass]}
      />
      <ToastPopup
        visibility={toastVisibility}
        text={
          '해당 class는 진행중인 과제가 있습니다.\n진행중인 과제가 종료된 후 삭제해 주세요.'
        }
      />
      <Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginBottom: '1.5rem',
          }}
        >
          <Button
            style={{
              backgroundColor: '#49545C',
              color: 'white',
              borderRadius: '6.25rem',
              padding: '0.75rem 2.25rem',
              textTransform: 'none',
            }}
            onClick={() => {
              setCreateClassVisibility(true);
            }}
          >
            class 생성 +
          </Button>
        </Box>
        <Box>
          {classList.map((val, idx) => {
            return (
              <ClassViewItem
                key={'classview' + idx}
                idx={idx}
                class_name={val.class_name}
                class_description={val.class_description}
                total_student={val.student_list.length}
                show_detail_event={index => {
                  setSelectedClass(index);
                  setDetailClassVisibility(true);
                }}
                remove_event={index => {
                  setSelectedClass(index);
                  setRemoveClassVisibility(true);
                }}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default FragmentView;
