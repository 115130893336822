export const mainColoredBtn = {
  marginTop: '2.5rem',
  padding: '0.9rem 1.6rem',
  backgroundColor: '#35d2bb',
  borderRadius: '6.25rem',
  color: 'white',
  fontWeight: '700',
  whiteSpace: 'nowrap',
};
export const iconBox = {
  width: '5.5rem',
  height: '5.5rem',
  borderRadius: '1.5625rem',
  border: '1px solid #F3F3F3',
  background: '#FFF',
  boxShadow: '0px 4px 20px 0px rgba(40, 173, 141, 0.15)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1.5rem',
};
