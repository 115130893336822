import React from 'react';
import {Box, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {useSelector} from 'react-redux';
const FailPage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const navigate = useNavigate();
  return (
    <>
      <Box>결제에 실패하였습니다.</Box>
      <Box>
        <Button
          onClick={() => {
            APIUtils.LoggerLog(
              authReducer.user_id,
              'keewit',
              'info',
              window.location.pathname,
              'click_event_home',
            );
            navigate('/');
          }}
        >
          홈으로 가기
        </Button>
      </Box>
    </>
  );
};
export default FailPage;
