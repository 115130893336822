import {Backdrop, Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import PropTypes, {number} from 'prop-types';
import {styled} from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

const LinearLoading = props => {
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    if (props.total == 0) {
      setPercent(0);
    } else {
      const per =
        parseInt(((props.total - props.current) / props.total) * 1000) / 10;
      setPercent(per);
    }
  }, [props.current]);
  const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    height: 20,
    borderRadius: 10,
    // [`&.${linearProgressClasses.colorPrimary}`]: {
    //   backgroundColor:
    //     theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    // },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }));
  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={true}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box style={{width: '80%'}}>
            <BorderLinearProgress variant="determinate" value={percent} />
          </Box>
          <Box
            style={{
              marginTop: '5rem',
              fontSize: 'calc(0.8rem + 1vw)',
              fontWeight: 'bold',
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
            }}
          >
            {percent + '%'}
            {'\n'}
            {'ㆍㆍAI가 글을 평가 후 업로드를 하는 중입니다ㆍㆍ'}
          </Box>
        </Box>
      </Backdrop>
    </>
  );
};

LinearLoading.propTypes = {
  total: number,
  current: number,
};

export default LinearLoading;
