import React from 'react';
import {Box} from '@mui/material';

const termStyle1 = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginTop: '3rem',
};
const termStyle2 = {
  fontSize: '1rem',
  marginTop: '1rem',
};

const termStyle3 = {
  fontSize: '1rem',
  marginTop: '1rem',
  marginLeft: '2rem',
};
const TermPage = () => {
  return (
    <Box style={{width: '100%', minHeight: '100vh', backgroundColor: 'white'}}>
      <Box
        style={{
          paddingTop: '3rem',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '10rem',
        }}
      >
        <Box style={termStyle1}>{'제1조 목적'}</Box>
        <Box style={termStyle2}>
          {
            '이 약관은 주식회사 투블럭에이아이(이하 ‘회사’라 한다)이 제공하는 서비스 이용과 관련하여 회사외 회원 간 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다. '
          }
        </Box>
        <Box style={termStyle2}> </Box>
        <Box style={termStyle1}>{'제2조 용어의 정의'}</Box>
        <Box style={termStyle2}>
          {
            '이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 본 약관에 명시되지 않은 용어는 관련 법령이 정하는 바에 따르며, 그 외에는 일반적인 상거래 관행에 의합니다. '
          }
        </Box>
        <Box style={termStyle2}>{'① 회사'}</Box>
        <Box style={termStyle2}>{'‘주식회사 투블럭에이아이’를 말합니다. '}</Box>
        <Box style={termStyle2}>{'② 서비스 '}</Box>
        <Box style={termStyle2}>
          {
            '투블럭에이아이 AI기술로 제공되는 글평가 및 글첨삭 서비스를 말합니다. 선생님용 키위티(KEEwiT)와 학생용 키위런(KEEwiLearn)를 말합니다. 키위런은 키위티에 종속된 서비스입니다. (키위티 URL : https://keewi-t.korean.ai )'
          }
        </Box>
        <Box style={termStyle2}>{'③ 유료서비스'}</Box>
        <Box style={termStyle2}>
          {
            '회사가 제공하는 서비스 중 회원이 회사에 일정 금액을 결제하거나, 회원이 회사 또는 제 3자와의 거래 혹은 약정 조건을 수락하는 경우에 이용할 수 있는 서비스를 말합니다. '
          }
        </Box>
        <Box style={termStyle2}>{'④  이용계약'}</Box>
        <Box style={termStyle2}>
          {'서비스 이용과 관련하여 회사와 회원간에 체결하는 계약을 말합니다. '}
        </Box>
        <Box style={termStyle2}>{'⑤  이용자'}</Box>
        <Box style={termStyle2}>
          {'서비스 이용하는 회원 및 비회원을 말합니다. '}
        </Box>
        <Box style={termStyle2}>{'⑥ 회원'}</Box>
        <Box style={termStyle2}>
          {
            '본 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 자를 말합니다.'
          }
        </Box>
        <Box style={termStyle2}>{'⑦ 비회원'}</Box>
        <Box style={termStyle2}>{'회원 이외의 이용자를 말합니다.'}</Box>
        <Box style={termStyle2}>{'⑧ 유료회원'}</Box>
        <Box style={termStyle2}>
          {'유료서비스를 이용하는 있는 회원을 말합니다. '}
        </Box>
        <Box style={termStyle2}>{'⑨ ID'}</Box>
        <Box style={termStyle2}>
          {
            '회원의 식별과 서비스 이용을 위하여 회원이 선정하고 회사가 승인한 이메일을 포함한 문자 또는 숫자의 조합을 말합니다. 카카오, 네이버, 구글 등의 간편 인증 서비스를 통해 가입하는 경우, 간편 인증을 통해 제공받은 정보 중 식별된 정보값을 ID로 지정합니다. '
          }
        </Box>
        <Box style={termStyle2}>{'⑩ 비밀번호'}</Box>
        <Box style={termStyle2}>
          {
            '회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여 회원 스스로가 설정하여 회사에 등록한 영문 또는 숫자의 조합을 말합니다. 카카오, 네이버, 구글 등의 간편 인증 서비스를 통해 가입하는 경우, 비밀번호는 회사가 관리하지 않습니다. '
          }
        </Box>
        <Box style={termStyle2}>{'⑪ 콘텐츠'}</Box>
        <Box style={termStyle2}>
          {
            '회사가 제공하는 디지털 콘텐츠 및 기타 관련정보 (부호·문자·음성·음향·이미지 또는 영상 등으로 표현된 자료 또는 정보로서, 그 보존 및 이용에 있어서 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것)를 말합니다. '
          }
        </Box>
        <Box style={termStyle2}>{'⑫ 리포트'}</Box>
        <Box style={termStyle2}>
          {
            '회원, 비회원이 입력한 텍스트(글)를 분석한 결과한 온라인 혹은 오프라인 콘텐츠를 말합니다. '
          }
        </Box>
        <Box style={termStyle2}>{'⑬ 이용권'}</Box>
        <Box style={termStyle2}>
          {
            '리포트를 수정, 저장 및 소장할 수 있는 권리가 담긴 이용권을 말합니다. 회원은 유료 결제 혹은 무료 지급을 통해 이용권을 획득할 수 있습니다. '
          }
        </Box>
        <Box style={termStyle1}>{'제3조 약관의 명시와 개정'}</Box>
        <Box style={termStyle2}>
          {
            '① 회사는 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호 등을 이용자가 쉽게 알 수 있도록 서비스 홈페이지의 하단 footer를 통해 게시합니다. 다만, 약관은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 회사는 온라인 디지털콘텐츠산업 발전법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '③ 회사가 약관을 개정할 경우에는 적용 일자 및 개정 사유를 명시하여 현행 약관과 함께 서비스 초기 화면에 그 적용 일자 7일 이전부터 적용일 후 상당한 기간 동안 공지하고, 기존회원에게는 개정약관을 전자우편주소로 발송합니다.다만, 이용자에게 불리하게 약관의 내용을 변경하는 경우에는 적용일자 30일 이전부터 공지합니다. '
          }
        </Box>
        <Box style={termStyle2}>
          {
            '④ 제3항에 따라 공지된 적용일자 이후에 회원이 회사의 서비스를 계속 이용하는 경우에는 개정된 약관에 동의하는 것으로 봅니다. 개정된 약관에 동의하지 아니하는 회원은 언제든지 자유롭게 서비스 이용계약을 해지할 수 있습니다. 유료서비스를 이용하는 회원이 약관의 개정에 동의하지 아니하여 해지할 경우 회사가 정한 환불정책에 따릅니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '⑤  이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한 전자거래소비자보호지침 및 관계법령 또는 일반 상 관례에 따릅니다.'
          }
        </Box>
        <Box style={termStyle1}>{'제4조 약관 외 준칙'}</Box>
        <Box style={termStyle2}>
          {
            '회원이 회사와 개별 계약을 체결하여 서비스를 이용하는 경우 회사는 개별 서비스에 대한 이용약관 또는 운영정책 등(이하 “개별 운영정책 등”)을 둘 수 있으며, 해당 내용이 본 약관과 상충되는 경우 개별 운영정책 등이 우선합니다. '
          }
        </Box>
        <Box style={termStyle1}>{'제5조 서비스 이용계약의 성립'}</Box>
        <Box style={termStyle2}>
          {
            '① 이용계약은 이용자의 약관동의와 이용신청에 대한 회사의 승낙으로 성립합니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 본 약관 및 회사의 개인정보처리방침에 대한 동의의 의사표시는 가입 신청자가 가입 신청 시 회원가입 버튼을 누름으로써 성립합니다. 이와 동시에 위 가입 신청자가 본 약관 및 개인정보처리방침에 대하여 동의한 것으로 간주합니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '③ 이용계약의 성립시기는 회사가 가입 완료 표시를 신청 절차 상에 표시한 시점으로 합니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '④ 회사는 가입 신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle3}>
          {
            '1. 가입신청자가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우'
          }
        </Box>
        <Box style={termStyle3}>
          {'2. 실명이 아니거나 타인의 명의를 이용한 가입신청의 경우'}
        </Box>
        <Box style={termStyle3}>
          {
            '3. 가입신청 시 허위의 정보를 기재하거나, 회사가 요구하는 내용을 기재하지 않은 경우'
          }
        </Box>
        <Box style={termStyle3}>
          {
            '4. 만 14세 미만 아동이 정보통신망 이용촉진 및 정보보호 등에 관한 법률에서 정한 개인정보 입력 시 법정대리인의 동의를 얻지 아니한 경우'
          }
        </Box>
        <Box style={termStyle3}>
          {'5. 가입신청자의 귀책사유로 인하여 승인이 불가능한 경우'}
        </Box>
        <Box style={termStyle3}>
          {'6. 가입신청자가 기타 규정한 제반 사항을 위반하며 신청하는 경우'}
        </Box>
        <Box style={termStyle2}>
          {
            '⑤ 회사는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle1}>{'제6조 이용자 정보의 제공'}</Box>
        <Box style={termStyle2}>
          {
            '① 회원으로 가입하여 서비스를 이용하고자 하는 이용자는 소셜 인증(카카오,네이버, 구글 등) 혹은 회사의 회원가입을 통해 서비스에서 요청하는 제반정보를 제공하여야 합니다. .'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 타인의 명의를 도용하여 이용 신청을 한 회원의 모든 ID는 삭제되며, 관계법령에 따라 처벌을 받을 수 있습니다.'
          }
        </Box>
        <Box style={termStyle1}>{'제7조 개인 정보의 보호 및 관리'}</Box>
        <Box style={termStyle2}>
          {
            '① 회사는 회원가입시 제공한 개인정보 중 비밀번호를 알 수 없으며  이와 관련된 부분은 서비스의 개인정보처리방침을 따릅니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 회사는 관계 법령이 정하는 바에 따라 계정정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보 보호 및 사용에 대해서는 회사가 별도로 고지하는 개인정보처리방침에 따릅니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '③ 단, 회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 정보에 대해서는 일체의 책임을 지지 않습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '④ 회사는 회원이 미풍양속에 저해되거나 국가안보에 위배되는 게시물 등 위법한 게시물을 등록 · 배포할 경우 관련기관의 요청이 있을시 회원의 자료를 열람 및 해당 자료를 관련기관에 제출할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle1}>{'제8조 회사의 의무'}</Box>
        <Box style={termStyle2}>
          {
            '① 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 가급적 빨리 처리하여야 합니다. 다만, 개인적인 사정으로 신속한 처리가 곤란한 경우에는 사후에 공지 또는 이용회원에게 전자우편 등의 가능한 방법으로  최선을 다합니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 회사는  계속적이고 안정적인 서비스 제공을 위하여 설비에 장애가 생기거나 유실된 때에는 이를 지체 없이 수리 또는 복구할 수 있도록 최선을 다합니다.다만, 천재지변 또는 회사에 부득이한 사유가 있는 경우, 서비스 운영을 일시 정지할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle1}>{'제9조 회원의 의무'}</Box>
        <Box style={termStyle2}>
          {
            '① 회원은 본 약관에서 규정하는 사항과 회사가 정한 제반규정, 공지사항 및 운영정책 등 서비스가 공지하는 사항 및 관계법령을 준수하여야 하며, 기타 서비스 업무에 방해가 되는 행위, 서비스의 명예를 손상시키는 행위를 해서는 안됩니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 회원은 서비스의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '③ 회원은 아이디 및 비밀번호 관리에 상당한 주의를 기울여야 하며, 회사의 동의 없이 제3자에게 아이디를 제공하여 이용하게 할 수 없습니다.'
          }
        </Box>
        <Box style={termStyle1}>{'제10조 회원에 대한 통지 및 공지'}</Box>
        <Box style={termStyle2}>
          {
            '① 회사가 회원에 대한 개별 통지(특정 회원의 개인적 사유를 이유로 한 통지를 의미하며, 이하 본조에서 “개별 통지”)를 하는 경우 본 약관에 별도 규정이 없는 한 회원이 계정으로 사용하는 이메일 주소로 할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 회사는 전항의 개별 통지 이외의 공지 사항에 대하여는 7일 이상 회사의 홈페이지 또는 공지사항 게시판에 게시함으로써 고지할 수 있습니다. 단, , 본 약관에 별도의 규정이 있는 경우 그에 따릅니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '③ 회사가 본조의 공지 또는 개별 통지를 하면서 특정일까지 회원이 거부 의사를 표시하지 아니할 경우 공지 또는 개별 통지 내용에 동의한 것으로 간주한다는 내용을 고지하였으나, 회원이 명시적으로 그에 대한 거부의사를 표시하지 아니하면, 회사는 회원이 특정일자 부로 공지 또는 개별 통지 내용에 동의한 것으로 간주합니다.'
          }
        </Box>
        <Box style={termStyle1}>{'제11조 서비스 이용 시간'}</Box>
        <Box style={termStyle2}>
          {
            '① 서비스 이용시간은 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 단, 시스템 정기점검, 증설 및 교체를 위해 회사가 정한 날이나 시간에 서비스를 일시중단 할 수 있으며 예정된 작업으로 인한 일시 중단은 서비스 홈페이지에 사전에 공지하오니 수시로 참고하시길 바랍니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 단, 다음 경우에 대하여 사전 공지나 예고없이 서비스를 일시적 혹은 영구적으로 중단할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle3}>
          {
            '1. 긴급한 시스템 점검, 증설, 교체, 고장 혹은 오동작을 일으키는 경우'
          }
        </Box>
        <Box style={termStyle3}>
          {'2. 국가비상사태, 정전, 천재지변 등의 불가항력적인 사유가 있는 경우'}
        </Box>
        <Box style={termStyle3}>
          {
            '3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한 경우'
          }
        </Box>
        <Box style={termStyle3}>
          {
            '4. 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '③ 전항에 의한 서비스 중단의 경우 공지사항 등을 통하여 회원에게 통지 합니다. 단, 회사가 통제할 수 없는 사유로 발생한 서비스의 중단에 대하여 사전공지가 불가능한 경우에는 사후공지로 대신합니다.'
          }
        </Box>
        <Box style={termStyle1}>{'제12조 정보의 제공 및 광고의 게재 '}</Box>
        <Box style={termStyle2}>
          {
            '① 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스 홈페이지에 게시하거나 이메일 등을 이용하여 회원에게 제공할 수 있습니다. 다만, 회원은 관련 법령에 따른 거래 관련 정보, 고객센터 답변 등을 제외한 정보의 전송에 대하여 언제든지 이메일 등을 통하여 수신거절의 의사표시를 할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 회사는 본 서비스 등을 유지하기 위하여 광고를 게재할 수 있으며, 회원은 서비스 이용 시 노출되는 광고게재에 대하여 동의합니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '③ 회사가 제공하는, 제3자가 주체인, 제2항의 광고에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해서 회사는 어떠한 책임도 부담하지 않습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '④ 회사는 적법하게 수집한 회원의 개인정보를 활용하여 제2항의 광고 등을 메일주소 등을 활용하여 발송할 수 있으며, 이용자가 원하지 않는 경우에는 언제든지 수신을 거부할 수 있습니다'
          }
        </Box>
        <Box style={termStyle1}>{'제13조 권리의 귀속'}</Box>
        <Box style={termStyle2}>
          {
            '① 서비스 및 서비스 내 콘텐츠 등(이하, “회사 콘텐츠 등”)에 대한 저작권 및 지식재산권 등 일체의 권리는 회사에 귀속됩니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 회원은 회사 콘텐츠 등을 무단 복제, 배포, 변경 등 어떠한 형태로든 사용하거나 제3자로 하여금 사용(이하, “회사 콘텐츠 부정사용”)하도록 할 수 없으며, 회사 콘텐츠 부정사용 시 발생한 손해에 대하여 회사에 배상할 책임을 부담합니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '③ 회원이 서비스를 이용하여 만든 리포트는 회원에게 저작권이 귀속됩니다. 단, 리포트 생성을 위해 활용한 원본 글의 저작권이 회원에게 있지 않다면, 리포트의 활용에서 법적 책임이 발생할 수 있습니다. 회원이 입력한 원본 글에 대한 저작권 확인 책임은 회원 본인에게 귀속되며 회사는 책임지지 않습니다. '
          }
        </Box>
        <Box style={termStyle2}>
          {
            '④ 회원이 입력한 텍스트 데이터(글)는 서비스 고도화를 위한 학습 모델의 원천 데이터로 회사가 활용할 수 있습니다. '
          }
        </Box>
        <Box style={termStyle1}>{'제14조 회원의 계약 해제와 해지 등 '}</Box>
        <Box style={termStyle2}>
          {
            '① 회원은 서비스 내 탈퇴 기능을 통해 탈퇴 신청을 할 수 있으며, 회사는 관련법령 등에서 정하는 바에 따라 이를 즉시 처리하여야 합니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {'③ 회원은 탈퇴 이후 동일한 이메일 주소로 다시 가입할 수 없습니다.'}
        </Box>
        <Box style={termStyle1}>{'제15조 서비스 이용 제한'}</Box>
        <Box style={termStyle2}>
          {
            '회원은 다음 각 호에 해당하는 행위를 하여서는 아니되며 해당 행위를 한 경우에 서비스는 회원의 이용 제한 및 적법한 조치를 취할 수 있으며 이용계약을 해지하거나 기간을 정하여 서비스를 중지할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {'회원 가입시 혹은 가입 후 정보 변경시 허위 내용을 등록하는 행위'}
        </Box>
        <Box style={termStyle2}>
          {'타인의 서비스 이용을 방해하거나 정보를 도용하는 행위'}
        </Box>
        <Box style={termStyle2}>
          {'서비스의 운영진, 직원 또는 관계자를 사칭하는 행위'}
        </Box>
        <Box style={termStyle2}>
          {
            '서비스, 기타 제3자의 인격권 또는 지적재산권을 침해하거나 업무를 방해하는 행위'
          }
        </Box>
        <Box style={termStyle2}>
          {'다른 회원의 ID를 부정하게 사용하는 행위'}
        </Box>
        <Box style={termStyle2}>
          {'다른 회원에 대한 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위'}
        </Box>
        <Box style={termStyle2}>
          {'범죄와 결부된다고 객관적으로 판단되는 행위'}
        </Box>
        <Box style={termStyle2}>{'기타 관련 법령에 위배되는 행위'}</Box>
        <Box style={termStyle1}>{'제16조 유료 서비스 이용 계약'}</Box>
        <Box style={termStyle2}>
          {
            '① 회사가 유료회원 이용신청을 승낙한 때(일부 서비스의 경우, 이용 신청 접수 후 사전 공지한 지정된 일자)로부터 유료서비스는 개시되며, 회사의 기술적 사유, 운영상 사유 등 기타 사정에 의하여 유료서비스를 개시할 수 없는 경우에는 제10조에 따라 회원에게 개별 통지 또는 공지합니다..'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 회사는 회원의 유료서비스 이용신청이 있는 경우, 해당 회원에게 이용신청의 내용을 통지합니다. 회사의 통지를 받은 회원은 의사표시의 불일치 등 오류가 있는 경우 회사에 정정 또는 수정을 요청하여야 하며, 회사는 회원의 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 이 약관의 청약 철회 등에 관한 규정에 따릅니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '③ 유료회원이 유/무선네트워크를 통하여 서비스에 접속하거나 유/무선네트워크가 연결된 상태의 기기 내에 탑재된 어플리케이션을 통하여 제반 서비스를 이용하는 경우, 유료회원과 유료회원이 가입한 해당 통신사간에 체결된 통신 요금제에 의한 별도의 데이터 통화료가 발생합니다. 이 경우 발생하는 데이터 통화료는 유료회원과 해당 통신사간에 체결된 통신요금제에 따라 과금/청구/수납되므로, 데이터 통화료에 대해서는 회사는 어떠한 책임도 지지 않습니다.'
          }
        </Box>
        <Box style={termStyle1}>{'제17조 유료서비스 청약 철회 및 환불 '}</Box>
        <Box style={termStyle2}>
          {
            '① 유료회원은 유료서비스 공급일 이후 유료서비스를 이용하지 않았다면, 7일 이내에 한해 회사에 결제 취소(청약 철회)를 요청할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 유료회원이 유료서비스를 이용하였거나, 청약 철회 가능 기간 이후 환불을 요청했을 경우는, 환불을 신청한 다음날부터 서비스를 이용하지 않은 잔여 일수를 계산해서 환불합니다. 따라서 환불 금액은 결제 금액에서 사용금액을 차감하여 환불됩니다. (특가 상품의 경우, 일 사용 금액은 정상가를 기준으로 차감됨을 고지해 드립니다.)'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '③ 청약의 철회는 유료회원이 서비스 홈페이지를 통해 할 수 있으며, 회사에 의사를 표시하여 회사에 도달될 때 그 효력이 발생하고, 회사는 유료회원의 의사표시를 수령한 후 지체 없이 이러한 사실을 유료회원에게 회신합니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '④ 회사는 유료회원이 청약 철회 신청을 확인 후 환불 금액이 있을 경우, 원칙적으로 유료회원의 해당 의사표시를 수령한 날로부터 3영업일 이내에 결제수단 별 사업자에게 대금의 청구 정지 내지 취소를 요청하고, 유료회원이 결제한 동일 결제수단으로 환불함을 원칙으로 합니다. 단, 회사가 사전에 유료회원에게 전자메일, 서비스 홈페이지로 공지한 경우 및 아래의 각 호의 경우와 같이 개별 결제 수단별 환불 방법, 환불 가능 기간 등이 차이가 있을 수 있습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '신용카드 등 수납 확인이 필요한 결제수단의 경우에는 수납 확인일로부터 3영업일 이내'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '결제수단 별 사업자가 회사와의 약정을 통하여 사전에 대금 청구 정지 내지 결제 취소 가능 기한 등을 미리 정하여 둔 경우로 해당 기한을 지난 환불의 경우'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '유료회원이 환불 처리에 필요한 정보 내지 자료를 회사에 즉시 제공하지 않는 경우(현금 환불 시 신청인의 계좌 및 신분증 사본을 제출하지 아니하거나, 타인 명의의 계좌를 제공하는 경우 등)'
          }
        </Box>
        <Box style={termStyle2}>
          {'해당 회원의 명시적 의사표시가 있는 경우'}
        </Box>
        <Box style={termStyle2}>
          {
            '⑤ 회사는 「콘텐츠산업 진흥법」제28조에 따라 제정된 「콘텐츠이용자보호지침」 등에 따라, 회사가 부담하였거나 부담할 부대비용, 수수료를 차감하여 환불할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '⑥ 회원이 유료서비스를 선물 받거나, 프로모션 등을 통해 무상으로 취득하는 등 회원이 직접 비용을 지불하지 아니하고 이용하는 유료서비스에 대하여 회사는 회원에게 유료서비스 결제 대금을 환불할 의무를 부담하지 않습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '⑦ 회원이 이 약관에서 정하는 회원의 의무를 위반하였을 경우, 계약을 해제, 해지하거나 서비스 이용 제한, 손해배상 청구 등의 조치를 취할 수 있으며, 계약 해지 후 환불하여야 할 금액이 있을 경우에는 일정한 범위 내에서 회원이 부담할 금액을 공제하고 환불합니다. 이 경우 회원은 해당 회사의 조치에 대하여 회사가 정한 절차에 따라 이의 신청을 할 수 있으며, 회사는 정당하다고 확인하는 경우 서비스 이용 재개 등을 할 수 있으며, 이용자가 자신의 고의, 과실이 없었음을 입증한 경우 회사는 서비스를 정지한 기간만큼 이용 기간을 연장합니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '⑧ 유료서비스를 이용 중인 유료회원이 탈퇴하는 경우 유료서비스는 즉시 해지되며 환불은 불가능합니다. 이 경우 유료회원의 정보와 서비스이용내용은 관련 법령이 정하는 경우를 제외하고는 약관에  따라 처리됩니다.'
          }
        </Box>
        <Box style={termStyle1}>{'제18조 유료서비스 내용 변경'}</Box>
        <Box style={termStyle2}>
          {
            '회사는 운영상, 기술상의 필요에 따라 공급하는 유료서비스 내용(가액, 제공 서비스 내역 등)을 변경할 수 있으며, 제10조에서 정한 방법에 따라 유료회원에게 사전공지합니다.'
          }
        </Box>
        <Box style={termStyle1}>{'제19조 과오금'}</Box>
        <Box style={termStyle2}>
          {
            '① 회사는 유료서비스 결제와 관련하여 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 과오금 전액을 환불합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 회사의 귀책사유로 과오금이 발생한 경우 과오금 전액을 환불합니다. 단, 회원의 귀책사유로 과오금이 발생한 경우, 회사가 과오금을 환불하는데 소요되는 비용은 합리적인 범위 내에서 이용자가 부담하여야 하며, 회사는 해당 비용을 차감 후 과오금을 환불할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '③ 회사는 회원이 과오금을 주장하며 환불을 요구하는 경우, 정당하게 유료서비스 요금이 부과되었음을 입증하면 환불을 거부할 수 있습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '④ 회사는 과오금의 세부 환불절차 및 기타 사항에 대하여 다음과 같이 「콘텐츠이용자보호지침」이 정하는 바에 따릅니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '회사나 회원이 과오금의 발생사실을 안 때에는 이메일, 서비스 홈페이지 등 회사가 제시한 방법에 따라 상대방에게 통보'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '회사는 회원에게 환불에 필요한 정보를 요청(회원 성명, 결제증빙서류, 전화번호, 환불 요청 계좌 등, 이하 “환불필요정보”)'
          }
        </Box>
        <Box style={termStyle2}>{'회원은 환불 필요 정보를 회사에 제공'}</Box>
        <Box style={termStyle2}>
          {'회사는 회원의 환불 필요 정보 제공일로부터 7일 이내 환불 처리 '}
        </Box>
        <Box style={termStyle1}>{'제20조 자료의 보관'}</Box>
        <Box style={termStyle2}>
          {
            '회사가 불가피한 사정으로 본 서비스를 중단하게 될 경우, 회원에게 사전 공지를 하고 유료회원의 경우, 저장된 자료들의 이전이 쉽도록 모든 조치를 취하기 위해 노력합니다. '
          }
        </Box>
        <Box style={termStyle1}>{'제21조 손해배상'}</Box>
        <Box style={termStyle2}>
          {
            '① 회사가 제공하는 유료서비스의 하자 등에 의하여 회원에게 피해가 발생한 경우, 회원에 대한 피해보상의 기준, 범위, 방법 및 절차에 관한 사항은 콘텐츠이용자보호지침에 따릅니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 회원이 본 약관 및/또는 본 서비스 이용규칙을 위반하거나 회원이 회사에 요청 또는 지시한 행위를 회사가 수행함으로 인하여 회사에 손해가 발생한 경우 또는 회원이 서비스의 이용과 관련하여 회사에 손해를 입힌 경우 회원은 회사에 대하여 손해를 배상하여야 합니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '③ 회원이 서비스를 이용함에 있어 행한 불법행위 및/또는 본 서비스 이용규칙을 위반한 행위 또는 회원의 요청 또는 지시로 인하여 회사가 행한 행위에 따라 회사가 당해 이용자 및/또는 제3자로부터 손해배상청구 또는 소송 등 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 이용자는 그로 인하여 회사에 발생한 모든 손해를 배상할 책임이 있습니다.'
          }
        </Box>
        <Box style={termStyle1}>{'제22조 책임의 한계'}</Box>
        <Box style={termStyle2}>
          {
            '①  회사는 회원이 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '② 회사는 천재지변 또는 국가 정책 등 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '③ 회사는 회원의 귀책사유로 인한 서비스 중지 또는 이용의 장애, 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에는 책임이 면제됩니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '④  회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '⑤  회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.'
          }
        </Box>
        <Box style={termStyle2}>
          {
            '⑥ 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법령에 특별한 규정이 없는 한 책임을 지지 않습니다.'
          }
        </Box>
        <Box style={termStyle1}>{'제22조 준거법 및 재판관할'}</Box>
        <Box style={termStyle2}>
          {'① 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.'}
        </Box>
        <Box style={termStyle2}>
          {
            '② 회사와 회원간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.'
          }
        </Box>
        <Box style={termStyle2}>{'부칙'}</Box>
        <Box style={termStyle2}>
          {'이 약관은 <2023년 1월 12일>부터 시행합니다.'}
        </Box>
      </Box>
    </Box>
  );
};
export default TermPage;
