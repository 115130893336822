import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React from 'react';
import {StringUtils} from 'common/utils/StringUtils';

export const TagView = props => {
  return (
    <Box
      style={{
        display: 'inline-block',
        backgroundColor: '#35D2BB',
        borderRadius: '0.6rem',
      }}
    >
      <Box style={{display: 'flex'}}>
        <Button
          onClick={() => {
            props.deleteEvent(props.tag);
          }}
          style={{
            color: 'white',
            fontSize: '1rem',
            height: '2rem',
          }}
        >
          <span
            style={{
              color: 'black',
              marginRight: '0.5rem',
              textTransform: 'none',
              fontSize: '0.8rem',
              whiteSpace: 'nowrap',
            }}
          >
            {props.tag}
          </span>
          ✖
        </Button>
      </Box>
    </Box>
  );
};
TagView.propTypes = {
  tag: PropTypes.string,
  deleteEvent: PropTypes.func,
};
export default TagView;
