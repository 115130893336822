import React, {useState, useEffect} from 'react';
import {Box, Button, TextField} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';
import {useSelector} from 'react-redux';
import APIUtils from 'common/utils/APIUtils';
import PropTypes from 'prop-types';
const ItemView = props => {
  const authReducer = useSelector(state => state.authReducer);

  const [codeText, setCodeText] = useState('');
  const [codeState, setCodeState] = useState(0);
  const [promotionName, setPromotionName] = useState('');
  const [promotionDate, setPromotionDate] = useState('');
  const [promotionAmount, setPromotionAmount] = useState(0);
  const handleCode = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setCodeText(inputVal);
    setCodeState(0);
  };
  function validateCode() {
    const validateResult = async inputCode => {
      try {
        const response = await APIUtils.PromotionInfoKeewi(inputCode);
        if (response.data.ret_code == 1000) {
          setPromotionName(response.data.promotion_name);
          setPromotionAmount(response.data.promotion_amount);
          setPromotionDate(response.data.promotion_period);
          setCodeState(1);
        } else if (response.data.ret_code == 2401) {
          setCodeState(3);
        } else if (response.data.ret_code == 2402) {
          setCodeState(4);
        } else if (response.data.ret_code == 2403) {
          setCodeState(5);
        } else if (response.data.ret_code == 2404) {
          setCodeState(6);
        } else if (response.data.ret_code == 2405) {
          setCodeState(7);
        } else {
          setCodeState(8);
        }
      } catch (err) {
        console.log(err);
      }
    };
    validateResult(codeText);
  }
  function showPromotionResult() {
    switch (codeState) {
      case 0:
        return '';
      case 1:
        return (
          <>
            <Box style={{color: '#717171'}}>[이용권 상세]</Box>
            <Box style={{color: '#ed8e00'}}>{promotionName}</Box>
            <Box style={{color: '#ed8e00'}}>
              기간: ~{promotionDate} | 키위런 {promotionAmount}명 등록 가능
            </Box>
          </>
        );
      case 2:
        return <Box style={{color: 'red'}}>코드를 먼저 확인해주세요.</Box>;
      case 3:
        return <Box style={{color: 'red'}}>존재하지 않는 코드입니다.</Box>;
      case 4:
        return <Box style={{color: 'red'}}>만료된 코드입니다.</Box>;
      case 5:
        return <Box style={{color: 'red'}}>오픈전인 코드입니다.</Box>;
      case 6:
        return <Box style={{color: 'red'}}>등록한도가 초과된 코드입니다.</Box>;
      case 7:
        return <Box style={{color: 'red'}}>이미 등록한 코드입니다.</Box>;
      case 8:
        return <Box style={{color: 'red'}}>정상적이지 않은 코드입니다.</Box>;
    }
  }
  function showCodeAcceptComplete() {
    if (codeState == 1) {
      const acceptResult = async inputCode => {
        try {
          const response = await APIUtils.PromotionAccpetKeewi(
            authReducer.user_id,
            inputCode,
          );
          if (response.data.ret_code == 1000) {
            alert('코드등록이 완료 되었습니다.');
          } else if (response.data.ret_code == 2401) {
            alert('존재하지 않는 코드입니다.');
          } else if (response.data.ret_code == 2402) {
            alert('만료된 코드입니다.');
          } else if (response.data.ret_code == 2403) {
            alert('오픈전인 코드입니다.');
          } else if (response.data.ret_code == 2404) {
            alert('등록한도가 초과된 코드입니다.');
          } else if (response.data.ret_code == 2405) {
            alert('이미 등록한 코드입니다.');
          } else if (response.data.ret_code == 2406) {
            alert('그룹이 있으면 무료 이용권을 사용하실 수 없습니다.');
          } else if (response.data.ret_code == 2407) {
            alert('이미 사용중인 이용권이 있습니다.');
          } else {
            alert('코드등록 실패');
          }
          props.cancelEvent();
        } catch (err) {
          console.log(err);
        }
      };
      acceptResult(codeText).then(r => {
        //do smt
      });
    } else {
      setCodeState(2);
    }
  }
  useEffect(() => {
    setCodeState(0);
    setCodeText('');
  }, [!props.visibility]);
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '28rem',
          textAlign: 'left',
          borderRadius: '20px',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box style={{textAlign: 'right'}}>
          <Button
            onClick={() => {
              props.cancelEvent();
            }}
            style={{color: '#717171', fontSize: '1.5rem'}}
          >
            ✖
          </Button>
        </Box>
        <Box
          style={{
            fontSize: '1.7rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
          }}
        >
          무료 이용권 등록하기
        </Box>
        <Box style={{fontSize: '1rem'}}>무료 이용권 코드를 입력해 주세요.</Box>
        <Box>
          사용 중인 이용권이 있는 경우, 무료 이용권은 사용하실 수 없습니다.
        </Box>
        <Box>
          <Box
            style={{display: 'flex', alignItems: 'center', marginTop: '2rem'}}
          >
            <TextField
              autoComplete="off"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '2px solid black',
                    borderRadius: '10px',
                  },
                  '&:hover fieldset': {
                    border: '2px solid black',
                    borderRadius: '10px',
                  },
                  '&.Mui-focused fieldset': {
                    border: '2px solid black',
                    borderRadius: '10px',
                  },
                },
              }}
              variant="outlined"
              fullWidth
              label={codeText == '' ? '코드를 입력하세요.' : ''}
              InputLabelProps={{
                style: {
                  color: Palette.grey1,
                  fontSize: 'calc(0.6rem + 0.3vw)',
                },
                shrink: false,
              }}
              InputProps={{
                style: {
                  fontSize: 'calc(0.8rem + 0.3vw)',
                  fontWeight: 500,
                },
              }}
              value={codeText}
              onChange={handleCode}
            ></TextField>
            <Button
              style={{
                whiteSpace: 'nowrap',
                backgroundColor: Palette.green8,
                color: 'white',
                fontSize: 'calc(0.7rem + 0.3vw)',
                fontWeight: 'bold',
                paddingLeft: '2rem',
                paddingRight: '2rem',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                marginLeft: '1rem',
                borderRadius: '10px',
              }}
              onClick={() => validateCode()}
            >
              코드 확인
            </Button>
          </Box>
        </Box>
        <Box
          style={{
            marginTop: '0.5rem',
            fontSize: '0.8rem',
          }}
        >
          {showPromotionResult()}
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '3rem',
          }}
        >
          <Button
            style={{
              border: '1px solid black',
              width: '47%',
              color: 'black',
              fontSize: 'calc(1rem + 0.2vw)',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
            }}
            onClick={() => {
              props.cancelEvent();
            }}
          >
            취소
          </Button>
          <Button
            style={{
              backgroundColor: codeState == 1 ? Palette.green8 : '#828282',
              width: '47%',
              color: 'white',
              fontSize: 'calc(1rem + 0.2vw)',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
            }}
            onClick={() => {
              showCodeAcceptComplete();
            }}
          >
            이용권 등록하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  cancelEvent: PropTypes.func,
};
export default ItemView;
