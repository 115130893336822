import React from 'react';
import {Box} from '@mui/material';

const ItemView = () => {
  return (
    <Box
      style={{
        borderTop: '2px dotted',
        marginBottom: '3rem',
        marginTop: '2rem',
      }}
    ></Box>
  );
};
export default ItemView;
