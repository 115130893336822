import {Box, Button, tabsClasses} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FragmentClassTab1 from 'view/mypage/FragmentClassTab1';
import FragmentClassTab2 from 'view/mypage/FragmentClassTab2';
import FragmentClassTab3 from 'view/mypage/FragmentClassTab3';
import APIUtils from 'common/utils/APIUtils';

export const FragmentView = () => {
  const authReducer = useSelector(state => state.authReducer);
  const [tabStatus, setTabStatus] = useState(1);

  const [studentCount, setStudentCount] = useState(0);
  const [authCount, setAuthCount] = useState(0);
  function showTab() {
    switch (tabStatus) {
      case 1:
        return <FragmentClassTab1 />;
      case 2:
        return <FragmentClassTab2 />;
      case 3:
        return <FragmentClassTab3 />;
      default:
        return <FragmentClassTab1 />;
    }
  }
  function getStudentCount() {
    const getStudents = async () => {
      try {
        const response = await APIUtils.ClassStudent(authReducer.user_id);
        setStudentCount(response.data.student_list.length);
      } catch (err) {
        console.log(err);
      }
    };
    getStudents();
  }
  function getAuthCount() {
    const authHistory = async () => {
      try {
        const response = await APIUtils.ClassAuth(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    authHistory().then(res => {
      setAuthCount(res.data.auth_student_list.length);
    });
  }
  useEffect(() => {
    getStudentCount();
    getAuthCount();
  }, [tabStatus]);
  return (
    <Box>
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>
        학생/Class 관리
      </Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '1.75rem',
        }}
      />
      <Box style={{color: '#87929D', fontSize: '0.875rem'}}>
        키위런 학생 계정을 만들고, 선생님의 계정(이메일)을 알려주세요. 요청 승인
        후 선생님과 학생을 연동합니다.
      </Box>
      <Box
        style={{color: '#87929D', fontSize: '0.875rem', marginBottom: '2rem'}}
      >
        학생은 최대 3개의 class에 소속될 수 있습니다.
      </Box>
      <Box style={{display: 'flex'}}>
        <Button
          style={{
            color: tabStatus == 1 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 1 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(1);
          }}
        >
          학생 관리
          <span
            style={{
              marginLeft: '0.5rem',
              color: tabStatus == 1 ? '#35D2BB' : '',
            }}
          >
            {studentCount}
          </span>
        </Button>
        <Button
          style={{
            color: tabStatus == 2 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 2 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
            textTransform: 'none',
          }}
          onClick={() => {
            setTabStatus(2);
          }}
        >
          Class 관리
        </Button>
        <Button
          style={{
            color: tabStatus == 3 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 3 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(3);
          }}
        >
          <Box
            style={{
              backgroundColor: '#35D2BB',
              width: '1rem',
              height: '1rem',
              display: authCount == 0 ? 'none' : 'flex',
              // display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              borderRadius: '1.5rem',
              marginRight: '0.2rem',
              fontWeight: 'bold',
              fontSize: '0.75rem',
            }}
          >
            N
          </Box>
          인증 요청
          <span
            style={{
              marginLeft: '0.5rem',
              color: tabStatus == 3 ? '#35D2BB' : '',
            }}
          >
            {authCount}
          </span>
        </Button>
      </Box>
      {showTab()}
    </Box>
  );
};
export default FragmentView;
