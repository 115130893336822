import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import {StringUtils} from 'common/utils/StringUtils';
import React from 'react';

export const TaskView = props => {
  const dateLeft = StringUtils.getDateDiff(props.task.end_time);
  const totalStudents =
    props.task.overall_submission_rate.submit +
    props.task.overall_submission_rate.non_submit;
  const submitRate =
    totalStudents == 0
      ? 0
      : (
          (props.task.overall_submission_rate.submit / totalStudents) *
          100
        ).toFixed(0);
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        paddingBottom: '1.7rem',
        borderBottom: '2px solid #A8B1B7',
      }}
    >
      <Box style={{display: 'flex', flexDirection: 'column', gap: '0.75rem'}}>
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: 'white',
              backgroundColor: '#68737D',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            D-{dateLeft}
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
        </Box>
        <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>
          {props.task.task_name}
        </Box>
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          {props.task.class_name_list
            .sort((a, b) => {
              if (a > b) return 1;
              if (a < b) return -1;
              return 0;
            })
            .map((value, idx) => {
              let classBreak = '';
              if (idx != props.task.class_name_list.length - 1) {
                classBreak = (
                  <Box
                    style={{
                      backgroundColor: '#A8B1B7',
                      width: '1px',
                      height: '0.5rem',
                      marginLeft: '0.5rem',
                    }}
                  />
                );
              }
              return (
                <Box
                  key={'class' + idx}
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  <Box
                    style={{
                      color: '#636C73',
                      fontSize: '0.875rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {value}
                  </Box>
                  {classBreak}
                </Box>
              );
            })}
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            width: '3.5rem',
            height: '3.5rem',
            borderRadius: '6.25rem',
            backgroundColor: '#ff9900',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '1.125rem',
          }}
        >
          {submitRate}%
        </Box>
        <Button
          style={{
            color: '#68737D',
            fontSize: '0.875rem',
            border: '1px solid var(--gray-2, #E6E8EB)',
            borderRadius: '0.625rem',
            marginTop: '0.5rem',
          }}
          onClick={() => {
            props.detailEvent(props.task);
          }}
        >
          자세히 보기
        </Button>
      </Box>
    </Box>
  );
};
TaskView.propTypes = {
  task: PropTypes.object,
  detailEvent: PropTypes.func,
};
export default TaskView;
