import axios from 'axios';
import AESUtils from './AES256Utils';

const API_URI = process.env.REACT_APP_API_URI;

const APIUtils = {
  async VesionCheck() {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const result = await axios.post('/keewi/version/check', {}, config);
    return result;
  },
  async AccountLogin(access_path, access_token) {
    const encrypted = AESUtils.encodeByAES56({
      access_path: access_path,
      access_token: access_token,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/account/login',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async AccountLoginEnterprise(id, pw) {
    const encrypted = AESUtils.encodeByAES56({
      login_id: id,
      login_password: pw,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/account/login/enterprise',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    return response;
  },
  async AccountModify(
    user_id,
    export_name,
    export_email,
    grade_in_charge,
    brand_bar_url,
  ) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      export_name: export_name,
      export_email: export_email,
      grade_in_charge: grade_in_charge,
      brand_bar_url: brand_bar_url,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/account/modify',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async AccountLeave(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/account/leave',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async AccountInfoExport(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/account/info/export',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async AccountInfo(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/account/info',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async EvalAnalyze(user_id, title, writing_janre, text) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      title: title,
      writing_janre: writing_janre,
      text: text,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/eval/analysis',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async EvalSave(user_id, result) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      eval_result: result,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/eval/save',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async EvalLoad(user_id, eval_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      eval_id: eval_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/eval/load',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async EvalModify(user_id, eval_id, eval_result) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      eval_id: eval_id,
      eval_result: eval_result,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/eval/modify',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async EvalHistory(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/eval/history',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async EvalHistoryDelete(eval_id_list) {
    const encrypted = AESUtils.encodeByAES56({
      eval_id_list: eval_id_list,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/eval/history/delete',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ItemValidKeewi(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/item/valid',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ItemListKeewi(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/item/list',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ItemBuyKeewi(
    user_id,
    item_id,
    pg_type,
    pg_transaction_id,
    order_id,
    amount,
    institution_name,
  ) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      item_id: item_id,
      pg_type: pg_type,
      pg_transaction_id: pg_transaction_id,
      order_id: order_id,
      amount: amount,
      institution_name: institution_name,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/item/buy',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async PromotionAccpetKeewi(user_id, promotion_code) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      promotion_code: promotion_code,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/promotion/accept',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async PromotionInfoKeewi(promotion_code) {
    const encrypted = AESUtils.encodeByAES56({
      promotion_code: promotion_code,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/promotion/info',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async NoticeListKeewi() {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post('/keewi/notice/list', {}, config);
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ExtractFileKeewi(formData) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await axios.post('/keewi/file/extract', formData, config);
    this.alertError(response);
    return response;
  },
  async GeneralReviewKeewi(user_text, score_list, writing_janre) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const response = await axios.post(
      '/keewi/general/review',
      {
        user_text: user_text,
        score_list: score_list,
        writing_janre: writing_janre,
      },
      config,
    );
    return response;
  },
  async RefineParagraphKeewi(user_text) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const response = await axios.post(
      '/keewi/refine/paragraph',
      {user_text: user_text},
      config,
    );
    // this.alertError(response);
    return response;
  },

  async ClassStudent(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/class/student',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ClassView(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/class/view',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ClassAuth(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/class/auth',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ClassAuthAccept(user_id, student_id_list) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      student_id_list: student_id_list,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/class/auth/accept',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ClassAuthReject(user_id, student_id_list) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      student_id_list: student_id_list,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/class/auth/reject',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ClassStudentDelete(user_id, student_id_list) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      student_id_list: student_id_list,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/class/student/delete',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ClassCreate(user_id, class_name, class_description) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      class_name: class_name,
      class_description: class_description,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/class/create',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ClassModify(user_id, class_id, class_name, class_description) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      class_id: class_id,
      class_name: class_name,
      class_description: class_description,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/class/modify',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ClassDelete(user_id, class_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      class_id: class_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/class/delete',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ClassAssignStudents(user_id, class_id, student_id_list) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      class_id: class_id,
      student_id_list: student_id_list,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/class/assign/students',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ClassExceptStudents(user_id, class_id, student_id_list) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      class_id: class_id,
      student_id_list: student_id_list,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/class/except/students',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ClassStudentMemo(user_id, student_id, student_memo) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      student_id: student_id,
      student_memo: student_memo,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/class/student/memo',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ClassStudentPasswordReset(user_id, student_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      student_id: student_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/class/student/password/reset',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskView(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/view',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskDetail(user_id, task_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_id: task_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/detail',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskCreate(
    user_id,
    task_name,
    task_message,
    task_janre,
    task_start_time,
    task_end_time,
    task_type,
    class_id_list,
    student_id_list,
    length_check,
    min_length,
    max_length,
    keyword_check,
    keyword,
    pre_keewichat,
  ) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_name: task_name,
      task_message: task_message,
      task_janre: task_janre,
      task_start_time: task_start_time,
      task_end_time: task_end_time,
      task_type: task_type,
      class_id_list: class_id_list,
      student_id_list: student_id_list,
      length_check: length_check,
      min_length: min_length,
      max_length: max_length,
      keyword_check: keyword_check,
      keyword: keyword,
      pre_keewichat: pre_keewichat,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/create',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskModify(
    user_id,
    task_id,
    task_name,
    task_message,
    task_janre,
    task_start_time,
    task_end_time,
    task_type,
    included_class_id_list,
    excluded_class_id_list,
    included_student_id_list,
    excluded_student_id_list,
    length_check,
    min_length,
    max_length,
    keyword_check,
    keyword,
    pre_keewichat,
  ) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_id: task_id,
      task_name: task_name,
      task_message: task_message,
      task_janre: task_janre,
      task_start_time: task_start_time,
      task_end_time: task_end_time,
      task_type: task_type,
      included_class_id_list: included_class_id_list,
      excluded_class_id_list: excluded_class_id_list,
      included_student_id_list: included_student_id_list,
      excluded_student_id_list: excluded_student_id_list,
      length_check: length_check,
      min_length: min_length,
      max_length: max_length,
      keyword_check: keyword_check,
      keyword: keyword,
      pre_keewichat: pre_keewichat,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/modify',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },

  async TaskStudentUnsubmit(user_id, task_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_id: task_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/student/unsubmit',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskEnd(user_id, task_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_id: task_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/end',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskResult(user_id, task_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_id: task_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/result',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskEvalEdit(user_id, task_id, student_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_id: task_id,
      student_id: student_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/eval/edit',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskEvalSave(user_id, task_id, student_id, eval_result) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_id: task_id,
      student_id: student_id,
      eval_result: eval_result,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/eval/save',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskResultComplete(user_id, task_id, open_score) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_id: task_id,
      open_score: open_score,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/result/complete',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async TaskExcel(user_id, task_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_id: task_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/excel',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },

  async TaskDelete(user_id, task_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_id: task_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/task/delete',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async GroupInfo(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/group/info',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async GroupCodeInfo(group_code) {
    const encrypted = AESUtils.encodeByAES56({
      group_code: group_code,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/group/code/info',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async GroupEnter(user_id, group_code) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      group_code: group_code,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/group/enter',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async GroupExit(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/group/exit',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async PassBuy(
    user_id,
    pass_id,
    pg_type,
    pg_transaction_id,
    order_id,
    amount,
    institution_name,
  ) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      pass_id: pass_id,
      pg_type: pg_type,
      pg_transaction_id: pg_transaction_id,
      order_id: order_id,
      amount: amount,
      institution_name: institution_name,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/pass/buy',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async PassList(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/pass/list',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async PassCheck(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/pass/check',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async CreateTaskReport(task_id) {
    const response = await axios.get(
      API_URI + '/keewi/task/' + task_id + '/eval_report',
    );
    return response;
  },
  async CreateEvalReport(eval_id) {
    const config = {
      responseType: 'blob',
    };
    const response = await axios.get(
      API_URI + '/keewi/eval/' + eval_id + '/report',
      config,
    );
    return response;
  },
  async CreateTaskExcel(task_id) {
    const config = {
      responseType: 'blob',
    };
    const response = await axios.get(
      API_URI + '/keewi/task/' + task_id + '/excel',
      config,
    );
    return response;
  },
  async ReportCheck(user_id, task_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_id: task_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/report/check',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    // this.alertError(response);
    return response;
  },
  async CreateEvalReportUrl(eval_id) {
    const response = await axios.get(
      API_URI + '/keewi/eval/' + eval_id + '/report/url',
    );
    return response;
  },
  async ReportEval(user_id, eval_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      eval_id: eval_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/report/eval',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async ReportExcelCheck(user_id, task_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_id: task_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/report/excel/check',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async AdminView(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/admin/view',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async AdminCodeReset(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/admin/code/reset',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },

  async AdminTeacherList(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/admin/teacher/list',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },

  async AdminTeacherDelete(user_id, teacher_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      teacher_id: teacher_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/admin/teacher/delete',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },

  async AdminStudentList(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/admin/student/list',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async LibraryMineList(user_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/library/mine/list',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async LibrarySharedList(target_age, task_janre, tag, sort, page) {
    const encrypted = AESUtils.encodeByAES56({
      target_age: target_age,
      task_janre: task_janre,
      tag: tag,
      sort: sort,
      page: page,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/library/shared/list',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async LibraryCreate(
    user_id,
    task_name,
    task_message,
    task_janre,
    target_age,
  ) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      task_name: task_name,
      task_message: task_message,
      task_janre: task_janre,
      target_age: target_age,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/library/create',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async LibraryDelete(user_id, library_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      library_id: library_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/library/delete',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async LibraryShare(user_id, library_id, tags) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      library_id: library_id,
      tags: tags,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/library/share',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async LibraryShareCancel(user_id, library_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      library_id: library_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/library/share/cancel',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async LibraryUpdate(
    user_id,
    library_id,
    task_name,
    task_message,
    task_janre,
    target_age,
  ) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      library_id: library_id,
      task_name: task_name,
      task_message: task_message,
      task_janre: task_janre,
      target_age: target_age,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/library/update',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async LibraryDetail(library_id) {
    const encrypted = AESUtils.encodeByAES56({
      library_id: library_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/library/detail',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async LibraryDownload(user_id, library_id) {
    const encrypted = AESUtils.encodeByAES56({
      user_id: user_id,
      library_id: library_id,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const encryptedResult = await axios.post(
      '/keewi/library/download',
      {
        data: encrypted,
      },
      config,
    );
    const response = AESUtils.decodeByAES56(encryptedResult.data.data);
    response.status = encryptedResult.status;
    this.alertError(response);
    return response;
  },
  async LibraryContext(genre, target_age, simple_topic) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const response = await axios.post(
      '/keewi/library/context',
      {
        genre: genre,
        target_age: target_age,
        simple_topic: simple_topic,
      },
      config,
    );
    return response;
  },
  async LibraryHashtag(genre, target_age, task_message) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const response = await axios.post(
      '/keewi/library/hashtag',
      {
        genre: genre,
        target_age: target_age,
        task_message: task_message,
      },
      config,
    );
    return response;
  },
  async CheckAnnouncement() {
    const response = await axios.get('/keewi/check/announcement');
    return response;
  },
  async LoggerLog(user_id, tag, level, from, event) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8;',
      },
    };
    const logging = async () => {
      try {
        const response = await axios.post(
          '/keewi/logger/log',
          {user_id: user_id, tag: tag, level: level, from: from, event: event},
          config,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    logging();
  },

  alertError: function (response) {
    if (response.status != 200) {
      alert('인터넷 연결을 확인해 주세요.');
    } else if (response.data.ret_code == 1000) {
      //success do nothing
    } else if (response.data.ret_code == 9999) {
      alert('엔진에러');
    } else if (response.data.ret_code == 9998) {
      alert('입력값 에러');
    } else if (response.data.ret_code == 9997) {
      alert('DB 저장 에러');
    } else if (response.data.ret_code == 9996) {
      alert('api 에러');
    } else if (response.data.ret_code == 9995) {
      alert('1분후에 다시 시도해 주세요.');
    } else if (response.data.ret_code == 2001) {
      alert('존재하지 않는 유저입니다.');
    } else if (response.data.ret_code == 2002) {
      alert('유효하지 않은 유저정보입니다.');
    } else if (response.data.ret_code == 2003) {
      alert('이미 탈퇴한 유저입니다.');
    } else if (response.data.ret_code == 2101) {
      alert('pg사 승인 실패');
    } else if (response.data.ret_code == 2102) {
      alert('이미 결제된 아이템입니다.');
    } else if (response.data.ret_code == 2103) {
      alert('정상적이지 않은 결제 정보입니다.');
    } else if (response.data.ret_code == 2104) {
      // done?
    } else if (response.data.ret_code == 2201) {
      alert('존재하지 않는 아이템');
    } else if (response.data.ret_code == 2202) {
      alert('유효하지 않은 보관함 ID');
    } else if (response.data.ret_code == 2203) {
      alert('이용권 개수 부족');
    } else if (response.data.ret_code == 2301) {
      alert('존재하지 않는 데이터');
    } else if (response.data.ret_code == 2501) {
      alert('존재하지 않는 그룹 정보입니다.');
    } else if (response.data.ret_code == 2502) {
      alert('관리자 계정이 아닙니다.');
    } else if (response.data.ret_code == 2701) {
      alert('최대 인원 수를 초과했습니다.');
    }
    //2401~2405는 code popup에서 처리
  },
};
export default APIUtils;
