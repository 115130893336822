import {Box, Button, Select, MenuItem, TextField} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState, forwardRef, useImperativeHandle} from 'react';
import Pagination from 'view/mypage/Pagination';
import APIUtils from 'common/utils/APIUtils';
import LibraryView1 from './LibraryView1';
import {StringUtils} from 'common/utils/StringUtils';
import PropTypes from 'prop-types';
import {useNavigate, useLocation} from 'react-router-dom';
import ApiLoading from 'view/common/ApiLoading2';
import LibraryModifyPopup from 'view/popup/LibraryModifyPopup';
import LibraryRemovePopup from 'view/popup/LibraryRemovePopup';
import LibrarySharePopup from 'view/popup/LibrarySharePopup';
import TaskCreatePopup from 'view/popup/TaskCreateFromLibraryPopup';
import writingSelectOption from 'asset/json/writing_select_option2.json';

const FragmentView = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  useImperativeHandle(ref, () => ({
    getLibraryList,
  }));
  const [loading, setLoading] = useState(true);
  const [libraryList, setLibraryList] = useState([]);
  const [originalLibrary, setOriginalLibrary] = useState([]);
  const countPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [tabStatus, setTabStatus] = useState(1);
  const [selectedLibrary, setSelectedLibrary] = useState(undefined);

  const [writingSelect, setWritingSelect] = useState(0);
  const handleWritingSelectInput = event => {
    setWritingSelect(event.target.value);
  };
  const [searchText, setSearchText] = useState('');
  const handleSearchText = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setSearchText(inputVal);
  };

  function paginationClickEvent(page) {
    setCurrentPage(page);
  }
  const [libraryModifyVisibility, setLibraryModifyVisibility] = useState(false);

  function modifyLibrary(inputLibrary) {
    setSelectedLibrary(inputLibrary);
    setLibraryModifyVisibility(true);
  }
  function libraryModifyCancel() {
    setSelectedLibrary(undefined);
    setLibraryModifyVisibility(false);
  }
  function libraryModifyConfirm(
    library_id,
    task_name,
    task_message,
    task_janre,
    target_age,
  ) {
    const modifyLibraryApi = async () => {
      try {
        const response = await APIUtils.LibraryUpdate(
          authReducer.user_id,
          library_id,
          task_name,
          task_message,
          task_janre,
          target_age,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    modifyLibraryApi().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        getLibraryList();
      }
      setLibraryModifyVisibility(false);
    });
  }
  const [libraryRemoveVisibility, setLibraryRemoveVisibility] = useState(false);

  function deleteLibrary(inputLibrary) {
    setSelectedLibrary(inputLibrary);
    setLibraryRemoveVisibility(true);
  }
  function libraryRemoveCancel() {
    setSelectedLibrary(undefined);
    setLibraryRemoveVisibility(false);
  }
  function libraryRemoveConfirm() {
    const removeLibraryApi = async input_id => {
      try {
        const response = await APIUtils.LibraryDelete(
          authReducer.user_id,
          input_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    removeLibraryApi(selectedLibrary.library_id).then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        getLibraryList();
      }
      setLibraryRemoveVisibility(false);
    });
  }

  const [libraryShareVisibility, setLibraryShareVisibility] = useState(false);

  function shareLibrary(inputLibrary) {
    if (inputLibrary.is_share == 0) {
      setSelectedLibrary(inputLibrary);
      setLibraryShareVisibility(true);
    } else {
      const shareCancelApi = async () => {
        try {
          const response = await APIUtils.LibraryShareCancel(
            authReducer.user_id,
            inputLibrary.library_id,
          );
          return response;
        } catch (err) {
          console.log(err);
        }
      };
      shareCancelApi().then(res => {
        if (res.status == 200 && res.data.ret_code == 1000) {
          getLibraryList();
        }
      });
    }
  }
  function libraryShareCancel() {
    setSelectedLibrary(undefined);
    setLibraryShareVisibility(false);
  }
  function libraryShareConfirm(inputTags) {
    const shareApi = async (userID, libraryID, tags) => {
      try {
        const response = await APIUtils.LibraryShare(userID, libraryID, tags);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    shareApi(authReducer.user_id, selectedLibrary.library_id, inputTags).then(
      res => {
        if (res.status == 200 && res.data.ret_code == 1000) {
          getLibraryList();
        }
        setSelectedLibrary(undefined);
        setLibraryShareVisibility(false);
      },
    );
  }

  const [taskCreateVisibility, setTaskCreateVisibility] = useState(false);
  function createTask(inputLibrary) {
    setTaskCreateVisibility(true);
    setSelectedLibrary(inputLibrary);
  }
  function taskCreateCancel() {
    setTaskCreateVisibility(false);
    setSelectedLibrary(undefined);
  }
  function taskCreateConfirm(
    task_name,
    task_message,
    task_janre,
    task_start_time,
    task_end_time,
    task_type,
    class_id_list,
    student_id_list,
    length_check,
    min_length,
    max_length,
    keyword_check,
    keyword,
    pre_keewichat,
  ) {
    const createTaskApi = async () => {
      try {
        const response = await APIUtils.TaskCreate(
          authReducer.user_id,
          task_name,
          task_message,
          task_janre,
          StringUtils.getFormattedString(task_start_time),
          StringUtils.getFormattedString(task_end_time),
          task_type,
          class_id_list,
          student_id_list,
          length_check,
          min_length,
          max_length,
          keyword_check,
          keyword,
          pre_keewichat,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    createTaskApi().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        alert('과제를 출제했습니다. 과제 관리에서 확인하세요.');
      }
      setTaskCreateVisibility(false);
      setSelectedLibrary(undefined);
    });
  }

  function getLibraryList() {
    const getLibrary = async () => {
      try {
        const response = await APIUtils.LibraryMineList(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getLibrary().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setOriginalLibrary(res.data.library_list);
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    getLibraryList();
  }, []);
  useEffect(() => {
    let filtered = [...originalLibrary];
    if (searchText.trim() != '') {
      filtered = filtered.filter(
        item =>
          item.task_name != undefined && item.task_name.includes(searchText),
      );
    }
    if (writingSelect != 0) {
      filtered = filtered.filter(
        item => item.task_janre == writingSelectOption[writingSelect],
      );
    }
    if (tabStatus == 1) {
      filtered = filtered.sort(
        (a, b) => new Date(b.update_at) - new Date(a.update_at),
      );
    } else if (tabStatus == 2) {
      filtered = filtered.sort((a, b) => {
        return b.task_name < a.task_name ? 1 : -1;
      });
    }
    setLibraryList(filtered);
    setTotalPage(Math.floor((filtered.length - 1) / countPerPage + 1));
  }, [originalLibrary, tabStatus, searchText, writingSelect]);
  if (loading) {
    return <ApiLoading />;
  }
  return (
    <Box>
      <LibraryModifyPopup
        visibility={libraryModifyVisibility}
        selectedLibrary={selectedLibrary}
        cancelEvent={libraryModifyCancel}
        confirmEvent={libraryModifyConfirm}
      />
      <LibraryRemovePopup
        visibility={libraryRemoveVisibility}
        cancelEvent={libraryRemoveCancel}
        removeEvent={libraryRemoveConfirm}
      />
      <LibrarySharePopup
        visibility={libraryShareVisibility}
        library={selectedLibrary}
        cancelEvent={libraryShareCancel}
        shareEvent={libraryShareConfirm}
      />
      <TaskCreatePopup
        visibility={taskCreateVisibility}
        selectedLibrary={selectedLibrary}
        cancelEvent={taskCreateCancel}
        confirmEvent={taskCreateConfirm}
      />
      <Box
        style={{
          display: 'flex',
          marginTop: '2rem',
          alignItems: 'center',
        }}
      >
        <Button
          style={{
            color: tabStatus == 1 ? '#35D2BB' : '#ADB4BA',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            border: tabStatus == 1 ? '2px solid #35D2BB' : '2px solid #ADB4BA',
            borderRadius: 0,
            padding: '0rem 1rem',
            height: '2.5rem',
            minWidth: 0,
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(1);
          }}
        >
          최신순
        </Button>
        <Button
          style={{
            color: tabStatus == 2 ? '#35D2BB' : '#ADB4BA',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            border: tabStatus == 2 ? '2px solid #35D2BB' : '2px solid #ADB4BA',
            borderRadius: 0,
            padding: '0rem 1rem',
            height: '2.5rem',
            minWidth: 0,
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(2);
          }}
        >
          이름순
        </Button>
        <Box style={{flex: 1}}></Box>{' '}
        <Select
          labelId="select-writing"
          id="wrintingType"
          variant="outlined"
          onChange={handleWritingSelectInput}
          value={writingSelect}
          style={{
            backgroundColor: 'white',
            // border: '0px solid black',
            borderRadius: '0.825rem',
            padding: '0rem 1rem',
            fontSize: '0.9rem',
            height: '3rem',
          }}
        >
          {writingSelectOption.map((value, index) => {
            return (
              <MenuItem key={'key' + index} value={index}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
        <Box>
          <TextField
            autoComplete="off"
            size="small"
            variant="outlined"
            sx={{
              marginLeft: '0.5rem',
              backgroundColor: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid black',
                },
                '&:hover fieldset': {
                  border: '1px solid black',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid black',
                },
              },
            }}
            label={searchText == '' ? '🔍 제목 검색' : ''}
            InputLabelProps={{
              style: {
                color: 'rgba(108, 108, 108, 0.721)',
                fontSize: '1rem',
              },
              shrink: false,
            }}
            InputProps={{style: {fontSize: '1rem'}}}
            value={searchText}
            onChange={handleSearchText}
          ></TextField>
        </Box>
      </Box>
      <Box>
        {libraryList
          .filter((value, index) => {
            if (Math.floor(index / countPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((value, index) => {
            return (
              <LibraryView1
                key={'task' + index}
                modifyEvent={modifyLibrary}
                deleteEvent={deleteLibrary}
                shareEvent={shareLibrary}
                createEvent={createTask}
                library={value}
              />
            );
          })}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
});
FragmentView.displayName = 'LibraryTab1';
export default FragmentView;
