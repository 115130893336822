import React from 'react';
import PropTypes from 'prop-types';
import ABCItem from './ABCItem';
import ACItem from './ACItem';
import CItem from './CItem';
import ModifyItem from './ModifyItem';
import AddItem from './AddItem';
import ParagraphItem from './ParagraphItem';
import DividerItem from './DividerItem';
import LoadingItem from './LoadingItem';
import TitleItem from './TitleItem';

const ItemView = props => {
  if (props.type == 'ABC') {
    return (
      <ABCItem
        paragraphIndex={props.paragraphIndex}
        idx={props.idx}
        removed={props.removed}
        commentID={props.commentID}
        A={props.A}
        B={props.B}
        C={props.C}
        subtype={props.subtype}
        removeEvent={props.removeEvent}
        cancelEvent={props.cancelEvent}
        saveEvent={props.saveEvent}
        modifying={props.modifying}
        startModifyingEvent={props.startModifyingEvent}
        endModifyingEvent={props.endModifyingEvent}
      />
    );
  } else if (props.type == 'AC') {
    return (
      <ACItem
        paragraphIndex={props.paragraphIndex}
        idx={props.idx}
        removed={props.removed}
        commentID={props.commentID}
        A={props.A}
        // B={props.B}
        C={props.C}
        subtype={props.subtype}
        removeEvent={props.removeEvent}
        cancelEvent={props.cancelEvent}
        saveEvent={props.saveEvent}
        modifying={props.modifying}
        startModifyingEvent={props.startModifyingEvent}
        endModifyingEvent={props.endModifyingEvent}
      />
    );
  } else if (props.type == 'C') {
    return (
      <CItem
        paragraphIndex={props.paragraphIndex}
        idx={props.idx}
        removed={props.removed}
        commentID={props.commentID}
        C={props.C}
        subtype={props.subtype}
        removeEvent={props.removeEvent}
        cancelEvent={props.cancelEvent}
        saveEvent={props.saveEvent}
        modifying={props.modifying}
        startModifyingEvent={props.startModifyingEvent}
        endModifyingEvent={props.endModifyingEvent}
      />
    );
  } else if (props.type == 'modify') {
    return (
      <ModifyItem
        paragraphIndex={props.paragraphIndex}
        idx={props.idx}
        saveEvent={props.saveEvent}
      />
    );
  } else if (props.type == 'add') {
    return (
      <AddItem
        paragraphIndex={props.paragraphIndex}
        idx={props.idx}
        addEvent={props.addEvent}
        refineEvent={props.refineEvent}
      />
    );
  } else if (props.type == 'paragraph') {
    return (
      <ParagraphItem paragraphIndex={props.paragraphIndex} text={props.text} />
    );
  } else if (props.type == 'title') {
    return <TitleItem A={props.A} B={props.B} C={props.C} />;
  } else if (props.type == 'divider') {
    return <DividerItem />;
  } else if (props.type == 'loading') {
    return <LoadingItem />;
  }
  return <></>;
};
ItemView.propTypes = {
  paragraphIndex: PropTypes.number,
  idx: PropTypes.number,
  removed: PropTypes.bool,
  type: PropTypes.string,
  commentID: PropTypes.number,
  A: PropTypes.string,
  B: PropTypes.string,
  C: PropTypes.string,
  subtype: PropTypes.string,
  text: PropTypes.array,
  removeEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
  addEvent: PropTypes.func,
  refineEvent: PropTypes.func,
  saveEvent: PropTypes.func,
  modifying: PropTypes.bool,
  startModifyingEvent: PropTypes.func,
  endModifyingEvent: PropTypes.func,
};
export default ItemView;
