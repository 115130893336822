import React, {useEffect, useState} from 'react';
import {Box, Button, TextField} from '@mui/material';
import PropTypes from 'prop-types';

const ItemView = props => {
  const [classExist, setClassExist] = useState(false);
  const [classText, setClassText] = useState('');
  const handleClass = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 15) {
      inputVal = inputVal.substring(0, 15);
    }
    setClassText(inputVal);
  };

  const [memoText, setMemoText] = useState('');
  const handleMemo = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 30) {
      inputVal = inputVal.substring(0, 30);
    }
    setMemoText(inputVal);
  };

  function showClassInfo() {
    if (classExist) {
      return (
        <Box style={{color: 'red', fontSize: '0.875rem'}}>
          중복된 class 이름입니다.
        </Box>
      );
    }
  }
  useEffect(() => {
    if (props.classList == undefined || props.classList.length == 0) {
      return;
    }
    if (props.classList.includes(classText)) {
      setClassExist(true);
    } else if (classExist) {
      setClassExist(false);
    }
  }, [classText]);
  useEffect(() => {
    setClassExist(false);
    if (props.selectedClass != undefined) {
      setClassText(props.selectedClass.class_name);
      setMemoText(props.selectedClass.class_description);
    }
  }, [props.visibility]);
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box
          style={{
            marginBottom: '0.5rem',
          }}
        >
          <TextField
            autoComplete="off"
            variant="filled"
            fullWidth
            label={classText == '' ? 'Class 이름' : ''}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderBottom: '1px solid black',
                },
                '&:hover fieldset': {
                  borderBottom: '1px solid black',
                },
                '&.Mui-focused fieldset': {
                  borderBottom: '1px solid black',
                },
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: '1.5rem',
                color: '#A8B1B7',
                fontWeight: 'bold',
              },
              shrink: false,
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                color: classExist ? 'red' : 'black',
                backgroundColor: 'white',
                fontSize: '1.5rem',
                borderBottom: classExist ? '1px solid red' : '1px solid black',
                fontWeight: 'bold',
              },
            }}
            value={classText}
            onChange={handleClass}
          ></TextField>
        </Box>
        {showClassInfo()}
        <Box style={{position: 'relative', marginTop: '1rem'}}>
          <TextField
            autoComplete="off"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #EBEEF4',
                  borderRadius: '0.625rem',
                },
                '&:hover fieldset': {
                  border: '1px solid #EBEEF4',
                  borderRadius: '0.625rem',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid #EBEEF4',
                  borderRadius: '0.625rem',
                },
              },
            }}
            variant="outlined"
            fullWidth
            label={memoText == '' ? 'class 정보를 간단하게 작성해 주세요.' : ''}
            InputLabelProps={{
              style: {
                color: '#A8B1B7',
              },
              shrink: false,
            }}
            InputProps={{
              style: {
                backgroundColor: '#F7F8FA',
                fontWeight: 500,
              },
            }}
            multiline
            value={memoText}
            onChange={handleMemo}
            onKeyDown={e => {
              if (e.key == 'Enter') {
                e.preventDefault();
              }
            }}
          ></TextField>
          <Box
            style={{
              position: 'absolute',
              bottom: '1rem',
              right: '1rem',
              color: '#A8B1B7',
            }}
          >
            <span style={{marginRight: '0.2rem', color: '#ff9900'}}>
              {memoText.length}
            </span>
            {'/30'}
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '3rem',
          }}
        >
          <Box style={{fontWeight: 'bold', fontSize: '1.2rem'}}>소속 학생</Box>
          <Box style={{fontSize: '1rem'}}>
            총
            <span style={{color: '#ff9900', marginLeft: '0.3rem'}}>
              {props.selectedClass.student_list.length}명
            </span>
          </Box>
        </Box>
        <Box
          style={{
            border: '1px solid #ff9900',
            padding: '1rem',
            marginTop: '1rem',
            borderRadius: '0.625rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            maxHeight: '30rem',
            overflowY: 'auto',
          }}
        >
          {props.selectedClass.student_list
            .sort((a, b) => {
              if (a.student_name > b.student_name) {
                return 1;
              }
              if (a.student_name < b.student_name) {
                return -1;
              }
              return 0;
            })
            .map((val, idx) => {
              return (
                <Box key={'stduent' + idx} style={{fontSize: '0.875rem'}}>
                  {val.student_name}
                </Box>
              );
            })}
        </Box>
        <Box style={{marginTop: '1.5rem', display: 'flex'}}>
          <Button
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: '#ff9900',
              backgroundColor: 'white',
              fontSize: '1rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
              border: '1px solid #ff9900',
            }}
            onClick={() => {
              props.cancelEvent();
            }}
          >
            {'닫기'}
          </Button>
          <Button
            disabled={classExist || classText == ''}
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor:
                classExist || classText == '' ? '#49545C' : '#ff9900',
              fontSize: '1rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => {
              props.modifyEvent(classText, memoText);
            }}
          >
            {'저장하기'}
          </Button>
        </Box>
        <Box
          style={{
            fontSize: '0.75rem',
            color: '#49545C',
            textAlign: 'end',
            marginTop: '0.5rem',
          }}
        >
          Class 배정과 해제는 학생 관리 탭에서 가능합니다.
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  cancelEvent: PropTypes.func,
  classList: PropTypes.array,
  modifyEvent: PropTypes.func,
  selectedClass: PropTypes.object,
};
export default ItemView;
