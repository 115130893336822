import React from 'react';
import {Box} from '@mui/material';
import logo from 'asset/image/logo_keewit.png';

const ErrorPage = () => {
  return (
    <Box
      style={{
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundColor: '#FEF9EC',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box style={{marginTop: '6rem'}}>
        <img src={logo} alt={'logo'} style={{width: '10rem'}} />
      </Box>
      <Box
        style={{
          marginTop: '3rem',
          color: '#696969',
          fontWeight: 'bold',
          fontSize: '1.7rem',
          textAlign: 'center',
        }}
      >
        <Box>글이 성공적으로</Box>
        <Box>제출되었습니다.</Box>
      </Box>
      <Box style={{marginTop: '2rem'}}>
        제출 결과는 담당 선생님께 확인하시기 바랍니다.
      </Box>
      <Box
        style={{
          marginTop: '5rem',
          //border: '2px dotted Black',
          marginLeft: '3rem',
          marginRight: '3rem',
          paddingLeft: '1.2rem',
          paddingRight: '1.2rem',
          paddingTop: '1.5rem',
          paddingBottom: '1.5rem',
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
          backgroundColor: '#EDF4E5',
          fontStyle: 'italic',
        }}
      >
        {
          '독서는 완전한(full) 사람을,\n토론은 준비된(ready) 사람을,\n글쓰기는 정밀한(exact) 사람을 만듭니다.\n\n- 철학자, 프란시스 베이컨 -'
        }
      </Box>
    </Box>
  );
};
export default ErrorPage;
