import React from 'react';
import {Button} from '@mui/material';
import PropTypes from 'prop-types';

const ClipboardCopy = props => {
  const doCopy = text => {
    // 흐음 1.
    if (navigator.clipboard) {
      // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
      navigator.clipboard
        .writeText(text)
        .then(() => {
          props.resultEvent(
            '링크가 복사되었습니다.\n학생들에게 링크를 공유하세요.',
          );
        })
        .catch(() => {
          props.resultEvent('복사를 다시 시도해주세요.');
          //alert('복사를 다시 시도해주세요.');
        });
    } else {
      // 흐름 2.
      if (!document.queryCommandSupported('copy')) {
        props.resultEvent('복사하기가 지원되지 않는 브라우저입니다.');
        return;
        //return alert('복사하기가 지원되지 않는 브라우저입니다.');
      }

      // 흐름 3.
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.top = 0;
      textarea.style.left = 0;
      textarea.style.position = 'fixed';

      // 흐름 4.
      document.body.appendChild(textarea);
      // focus() -> 사파리 브라우저 서포팅
      textarea.focus();
      // select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
      textarea.select();
      // 흐름 5.
      document.execCommand('copy');
      // 흐름 6.
      document.body.removeChild(textarea);
      props.resultEvent(
        '링크가 복사되었습니다.\n학생들에게 링크를 공유하세요.',
      );
    }
  };

  return (
    <Button
      style={{
        fontSize: 'calc(0.5rem + 0.6vw)',
        paddingTop: '3px',
        paddingBottom: '3px',
        paddingRight: '10px',
        paddingLeft: '10px',
        borderRadius: '10px',
        color: '#FF6928',
        border: '1px solid #FF6928',
        backgroundColor: '#FFF5F0',
      }}
      onClick={() => doCopy(props.link)}
    >
      개인 링크 복사
    </Button>
  );
};
ClipboardCopy.propTypes = {
  link: PropTypes.string,
  resultEvent: PropTypes.func,
};
export default ClipboardCopy;
