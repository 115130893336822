import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField, Box, IconButton, Button} from '@mui/material';
import KeewiChip from 'view/result/KeewiChip';
import {CommentBox} from 'view/common/Components';
import emojiRegex from 'emoji-regex';
import Pencil from 'asset/image/icon_pencil.png';
import Dashed from 'asset/image/icon_dashed.png';

const ItemView = props => {
  const [modifyingA, setModifyingA] = useState(props.A);
  const [modifyingC, setModifyingC] = useState(props.C);
  const handleAInput = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      setModifyingA(inputVal);
    }
  };
  const handleCInput = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      setModifyingC(inputVal);
    }
  };
  useEffect(() => {
    setModifyingA(props.A);
    setModifyingC(props.C);
  }, [props.modifying]);
  if (props.modifying) {
    return (
      <CommentBox>
        <>
          <Box style={{display: 'flex', color: '#FF9900'}}>
            {props.commentID == -1 ? '' : '(' + props.commentID + ') '}
            <TextField
              autoComplete="off"
              variant="standard"
              fullWidth
              multiline
              InputProps={{
                disableUnderline: true,
                style: {
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  backgroundColor: '#F7F8FA',
                  borderRadius: '0.625rem',
                  border: '1px solid #EBEEF4',
                },
              }}
              style={{
                flex: 1,
                color: 'black',
                alignItems: 'start',
                flexDirection: 'row',
                wordBreak: 'break-all',
              }}
              value={modifyingA}
              onChange={handleAInput}
            />
          </Box>
          <Box
            style={{
              display: 'flex',
              marginTop: '1rem',
            }}
          >
            <Box>
              <KeewiChip subtype={props.subtype} />
            </Box>
            <TextField
              autoComplete="off"
              variant="standard"
              fullWidth
              multiline
              InputProps={{
                disableUnderline: true,
                style: {
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                },
              }}
              style={{
                flex: 1,
                color: 'black',
                border: '1px solid var(--01-gray-06, #D8DCDE)',
                borderRadius: '0.625rem',
                marginLeft: '1rem',
              }}
              value={modifyingC}
              onChange={handleCInput}
            ></TextField>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: '1rem',
              gap: '0.6rem',
            }}
          >
            <Button
              size="small"
              style={{
                border: '1px solid var(--01-gray-06, #D8DCDE)',
                backgroundColor: 'white',
                color: '#49545C',
                borderRadius: '0.625rem',
                fontSize: '0.875rem',
                padding: '0.5rem 1.5rem',
              }}
              onClick={() => {
                props.endModifyingEvent(
                  props.paragraphIndex,
                  props.idx,
                  props.A,
                  '',
                  props.C,
                );
              }}
            >
              취소하기
            </Button>
            <Button
              size="small"
              style={{
                color: 'white',
                backgroundColor: '#49545C',
                borderRadius: '0.625rem',
                fontSize: '0.875rem',
                padding: '0.5rem 1.5rem',
              }}
              onClick={() => {
                props.endModifyingEvent(
                  props.paragraphIndex,
                  props.idx,
                  modifyingA,
                  '',
                  modifyingC,
                );
              }}
            >
              저장하기
            </Button>
          </Box>
        </>
      </CommentBox>
    );
  }
  return (
    <Box
      style={{
        textDecorationLine: props.removed ? 'line-through' : 'none',
        textDecorationColor: 'black',
        textDecorationThickness: '1px',
      }}
    >
      <CommentBox>
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'start',
            }}
          >
            <Box>
              <span style={{color: '#FF9900', fontSize: 'bold'}}>
                {props.commentID == -1 ? '' : '(' + props.commentID + ') '}
              </span>
              {props.A}
            </Box>
            <Box
              style={{
                display: props.removed ? 'none' : 'flex',
                whiteSpace: 'nowrap',
              }}
            >
              <IconButton
                size="small"
                onClick={() => {
                  props.startModifyingEvent(props.paragraphIndex, props.idx);
                }}
              >
                <img
                  src={Pencil}
                  alt={'Pencil'}
                  style={{
                    width: '1.5rem',
                    height: '1.5rem',
                  }}
                />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  props.removeEvent(props.paragraphIndex, props.idx);
                }}
              >
                <img
                  src={Dashed}
                  alt={'Dashed'}
                  style={{
                    width: '1.5rem',
                    height: '1.5rem',
                  }}
                />
              </IconButton>
            </Box>
            <Box
              style={{
                display: props.removed ? 'flex' : 'none',
                whiteSpace: 'nowrap',
              }}
            >
              <Button
                size="small"
                style={{
                  border: '1px solid var(--01-gray-06, #D8DCDE)',
                  backgroundColor: 'white',
                  color: '#49545C',
                  borderRadius: '0.625rem',
                  fontSize: '0.875rem',
                  padding: '0.5rem 1.5rem',
                }}
                onClick={() => {
                  props.cancelEvent(props.paragraphIndex, props.idx);
                }}
              >
                취소하기
              </Button>
            </Box>
          </Box>
          <Box style={{backgroundColor: 'black', height: '1px'}}></Box>
          <Box
            style={{
              display: 'flex',
              marginTop: '0.5rem',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
              color: '#68737D',
            }}
          >
            <Box>
              <KeewiChip subtype={props.subtype} />
            </Box>
            <span style={{marginLeft: '0.5rem'}}>{props.C}</span>
          </Box>
        </>
      </CommentBox>
    </Box>
  );
};
ItemView.propTypes = {
  paragraphIndex: PropTypes.number,
  idx: PropTypes.number,
  removed: PropTypes.bool,
  commentID: PropTypes.number,
  A: PropTypes.string,
  C: PropTypes.string,
  subtype: PropTypes.string,
  removeEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
  saveEvent: PropTypes.func,
  modifying: PropTypes.bool,
  startModifyingEvent: PropTypes.func,
  endModifyingEvent: PropTypes.func,
};
export default ItemView;
