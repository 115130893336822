import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import EyeOn from 'asset/image/icon_eye_on.png';
import EyeOff from 'asset/image/icon_eye_off.png';
import {Palette} from 'common/utils/ColorUtils';
const ItemView = props => {
  return (
    <Box
      onClick={() => {
        props.toggleEvent(props.index);
      }}
      style={{
        display: 'flex',
        fontSize: '0.875rem',
        fontWeight: '500',
        //justifyContent: 'center',
        lineHeight: '2vh',
        paddingRight: '1rem',
        marginTop: '0.5rem',
        color: props.visible ? Palette.orange1 : '#717171',
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '1rem',
          marginRight: '0.5rem',
        }}
      >
        <img
          style={{height: '1.3rem'}}
          src={props.visible ? EyeOn : EyeOff}
          alt={'eyeImage'}
        />
      </Box>
      {props.text}
    </Box>
  );
};
ItemView.propTypes = {
  visible: PropTypes.bool,
  text: PropTypes.string,
  toggleEvent: PropTypes.func,
  index: PropTypes.number,
};
export default ItemView;
