import ReactGA4 from 'react-ga4';
const InitializeGoogleAnalytics = () => {
  // Initialize GA4 - Add your measurement ID
  const TRACKING_ID = process.env.REACT_APP_ANALYTICS_TRACKING_ID;
  ReactGA4.initialize(TRACKING_ID);
};

const TrackGoogleAnalyticsEvent = (category, action, label) => {
  if (process.env.REACT_APP_ENV == 'live') {
    // Send GA4 Event
    ReactGA4.event({
      category: category,
      action: action,
      label: label,
    });
  }
};

export default InitializeGoogleAnalytics;
export {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent};
