import React from 'react';
import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';
const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '80%',
          textAlign: 'left',
          borderRadius: '20px',
          background: 'white',
          paddingLeft: '5%',
          paddingRight: '5%',
          paddingBottom: '2rem',
          paddingTop: '0.2rem',
          color: 'black',
        }}
      >
        <Box style={{textAlign: 'right'}}>
          <Button
            onClick={() => {
              props.cancelEvent();
            }}
            style={{color: '#717171', fontSize: '1rem'}}
          >
            ✖
          </Button>
        </Box>
        <Box
          style={{
            fontSize: '1.2rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            marginTop: '0.5rem',
          }}
        >
          진행중인 과제를 종료 하시겠습니까?
        </Box>
        {/* <Box style={{fontSize: '0.9rem'}}>삭제 후 복구할 수 없습니다.</Box> */}
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '2rem',
          }}
        >
          <Button
            style={{
              border: '1px solid black',
              width: '47%',
              color: 'black',
              fontSize: '0.9rem',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
            }}
            onClick={() => {
              props.cancelEvent();
            }}
          >
            취소
          </Button>
          <Button
            style={{
              backgroundColor: '#828282',
              width: '47%',
              color: 'white',
              fontSize: '0.9rem',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
            }}
            onClick={() => {
              props.completeEvent();
            }}
          >
            종료하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  completeEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
};
export default ItemView;
