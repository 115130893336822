import PropTypes from 'prop-types';
import {Box, Checkbox, IconButton, Button} from '@mui/material';
import PencilIcon3 from 'asset/imageV2/icon_pencil3.svg';

const StudentTableItem = props => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #68737D',
        paddingBottom: '1.5rem',
        paddingTop: '1.5rem',
      }}
    >
      <Box style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
        <Box>
          <Checkbox
            checked={props.checked}
            onChange={() => {
              props.handleCheck(props.index);
            }}
            inputProps={{'aria-label': 'controlled'}}
            style={{color: '#35D2BB'}}
          />
        </Box>
        <Box style={{display: 'flex', gap: '0.5rem', flexDirection: 'column'}}>
          <Box
            style={{
              color: props.student_status == 1 ? '#cdcdcd' : 'black',
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
          >
            {props.student_name}
            <span
              style={{
                display: props.student_status == 1 ? '' : 'none',
                fontSize: '0.7rem',
                marginLeft: '0.5rem',
              }}
            >
              (탈퇴한 유저)
            </span>
            <span
              style={{
                color: '#636C73',
                fontSize: '0.875rem',
                marginLeft: '0.5rem',
              }}
            >
              {props.student_email}
            </span>
          </Box>
          <Box
            style={{
              color: '#636C73',
              fontSize: '0.875rem',
              display: 'flex',
              gap: '0.3rem',
            }}
          >
            {props.student_class_list
              .sort((a, b) => {
                if (a > b) return 1;
                if (a < b) return -1;
                return 0;
              })
              .map((value, index) => {
                let comma =
                  index + 1 == props.student_class_list.length ? '' : ', ';
                return <Box key={'className' + index}>{value + comma}</Box>;
              })}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          marginLeft: '3rem',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            color: '#636C73',
            fontSize: '0.875rem',
          }}
        >
          {props.student_memo}
          <IconButton
            style={{
              width: '1rem',
              height: '1rem',
            }}
            onClick={() => {
              props.startModifyEvent();
            }}
          >
            <img
              src={PencilIcon3}
              style={{
                width: '1rem',
                marginLeft: '3rem',
                height: '1rem',
              }}
              alt="PencilIcon3"
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        style={{
          display: props.student_access_path == 10 ? 'flex' : 'none',
          // display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          style={{
            backgroundColor: '#D9D9D9',
            borderRadius: '2.6rem',
            padding: '0.4rem 1rem',
            color: 'black',
          }}
          onClick={() => {
            props.resetPWEvent(props.student_id, props.student_email);
          }}
        >
          비밀번호 재설정
        </Button>
      </Box>
    </Box>
  );
};
export default StudentTableItem;
StudentTableItem.propTypes = {
  student_id: PropTypes.number,
  student_class_list: PropTypes.array,
  student_email: PropTypes.string,
  student_name: PropTypes.string,
  student_memo: PropTypes.string,
  student_access_path: PropTypes.number,
  student_status: PropTypes.number,
  handleCheck: PropTypes.func,
  score: PropTypes.number,
  index: PropTypes.number,
  checked: PropTypes.bool,
  startModifyEvent: PropTypes.func,
  resetPWEvent: PropTypes.func,
};
