import PropTypes from 'prop-types';
import {Grid, Box, IconButton} from '@mui/material';
import {StringUtils} from 'common/utils/StringUtils';
import React, {useState} from 'react';
import Pagination from 'view/mypage/Pagination';
import remove from 'asset/imageV2/icon_mobile_delete.png';
import finish from 'asset/imageV2/icon_mobile_finish.png';

export const TaskTable = props => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPage = Math.floor((props.taskList.length - 1) / 15 + 1);
  function clickEvent(page) {
    setCurrentPage(page);
  }
  function getButton(currItem) {
    if (currItem.status == '진행중') {
      return (
        <IconButton onClick={() => props.finishEvent(currItem)}>
          <img src={finish} alt={'finish'} style={{width: '2rem'}} />
        </IconButton>
      );
    } else if (currItem.status == '대기' || currItem.status == '종료') {
      return (
        <IconButton onClick={() => props.deleteEvent(currItem)}>
          <img src={remove} alt={'remove'} style={{width: '2rem'}} />
        </IconButton>
      );
    }
    return;
  }
  function showLabel(currItem) {
    const dateLeft = StringUtils.getDateDiff(currItem.end_time);

    if (currItem.status == '진행중') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: 'white',
              backgroundColor: '#FF9900',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            D-{dateLeft}
          </Box>
          <Box
            style={{
              display: currItem.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
        </Box>
      );
    } else if (currItem.status == '대기') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#68737D',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #68737D',
            }}
          >
            대기
          </Box>
          <Box
            style={{
              display: currItem.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
        </Box>
      );
    } else if (currItem.status == '종료') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#87929D',
              backgroundColor: '#E9EBED',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            종료
          </Box>
          <Box
            style={{
              display: currItem.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
          <Box
            style={{
              display: currItem.task_share == 1 ? '' : 'none',
              color: 'white',
              backgroundColor: '#ff9900',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            {'공유 완료'}
          </Box>
        </Box>
      );
    }
  }
  return (
    <Box style={{width: '100%'}}>
      {props.taskList
        .filter((value, index) => {
          if (Math.floor(index / 15) + 1 == currentPage) {
            return true;
          }
          return false;
        })
        .map((value, index) => {
          return (
            <Box
              key={'historyList' + index}
              style={{
                width: '100%',
                marginTop: '0.5rem',
                boxShadow: '0px 3px 2px 0px rgba(0, 0, 0, 0.15)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '1rem',
                }}
              >
                <Box>
                  <Box style={{}}>{showLabel(value)}</Box>
                  <Box
                    style={{
                      marginTop: '0.3rem',
                      fontSize: '0.95rem',
                      display: 'flex',
                    }}
                  >
                    {value.task_name}
                  </Box>
                </Box>
              </Box>
              <Box>{getButton(value)}</Box>
            </Box>
          );
        })}
      <Box
        style={{
          marginTop: '3rem',
          fontSize: 'calc(0.8rem + 0.5vw)',
        }}
      >
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          clickEvent={clickEvent}
        />
      </Box>
    </Box>
  );
};
TaskTable.propTypes = {
  taskList: PropTypes.array,
  finishEvent: PropTypes.func,
  deleteEvent: PropTypes.func,
};
