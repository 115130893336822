export const PurchaseConstants = [
  {
    evalCount: 1,
    evalCost: 9900,
    evalDuration: 1,
  },
  {
    evalCount: 10,
    evalCost: 55000,
    evalDuration: 1,
  },
  {
    evalCount: 50,
    evalCost: 110000,
    evalDuration: 1,
  },
  {
    evalCount: 150,
    evalCost: 220000,
    evalDuration: 1,
  },
];
export const PurchaseConstant1 = [
  {
    studentCount: 3,
    classCount: 1,
    evalDuration: 12,
    storageLimit: 10,
    evalCost: 550000,
  },
  {
    studentCount: 10,
    classCount: 3,
    evalDuration: 12,
    storageLimit: 10,
    evalCost: 1100000,
  },
  {
    studentCount: 25,
    classCount: 5,
    evalDuration: 12,
    storageLimit: 30,
    evalCost: 2200000,
  },
  {
    studentCount: 50,
    classCount: 10,
    evalDuration: 12,
    storageLimit: 30,
    evalCost: 3300000,
  },
];
export const PurchaseConstant2 = [
  {
    studentCount: 3,
    classCount: 1,
    evalDuration: 6,
    storageLimit: 10,
    evalCost: 330000,
  },
  {
    studentCount: 10,
    classCount: 3,
    evalDuration: 6,
    storageLimit: 10,
    evalCost: 660000,
  },
  {
    studentCount: 25,
    classCount: 5,
    evalDuration: 6,
    storageLimit: 30,
    evalCost: 1320000,
  },
  {
    studentCount: 50,
    classCount: 10,
    evalDuration: 6,
    storageLimit: 30,
    evalCost: 1980000,
  },
];
