import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React from 'react';

const ClassViewItem = props => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #68737D',
        paddingBottom: '1.5rem',
        paddingTop: '1.5rem',
      }}
    >
      <Box style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
        <Box style={{display: 'flex', gap: '0.5rem', flexDirection: 'column'}}>
          <Box style={{color: 'black', fontSize: '1rem', fontWeight: 'bold'}}>
            {props.class_name}
            <span
              style={{
                color: '#636C73',
                fontSize: '0.875rem',
                marginLeft: '0.3rem',
              }}
            >
              {'(' + props.total_student + ')'}
            </span>
          </Box>
          <Box
            style={{
              color: '#636C73',
              fontSize: '0.875rem',
              display: 'flex',
              gap: '0.3rem',
            }}
          >
            {props.class_description}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '1rem',
          gap: '0.5rem',
        }}
      >
        <Button
          style={{
            border: '2px solid #E9EBED',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#262c31',
            borderRadius: '1.9rem',
            padding: '0rem 2.5rem',
            height: '3rem',
          }}
          onClick={() => {
            props.remove_event(props.idx);
          }}
        >
          삭제
        </Button>
        <Button
          style={{
            border: '2px solid #FF9900',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#FF9900',
            borderRadius: '1.9rem',
            padding: '0rem 2.5rem',
            height: '3rem',
          }}
          onClick={() => {
            props.show_detail_event(props.idx);
          }}
        >
          자세히 보기
        </Button>
      </Box>
    </Box>
  );
};
export default ClassViewItem;
ClassViewItem.propTypes = {
  idx: PropTypes.number,
  class_name: PropTypes.string,
  class_description: PropTypes.string,
  total_student: PropTypes.number,
  show_detail_event: PropTypes.func,
  remove_event: PropTypes.func,
};
