import {styled} from '@mui/material/styles';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';
export const PurchaseSelectButton = styled(ToggleButton)({
  // marginLeft: '2.5%',
  // marginRight: '2.5%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  width: '50%',
  outlineStyle: 'none',
  borderRadius: '0.625rem',
  color: 'black',
  marginTop: '1rem',
  marginBottom: '1rem',
  fontSize: 'calc(0.7rem + 0.5vw)',
  fontWeight: 'bold',
  boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.15)',
  backgroundColor: 'white',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'rgba(53, 210, 187, 1)',
    backgroundColor: 'rgba(53, 210, 187, 0.1)',
  },
});
