import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Select, MenuItem, Box, TextField, Chip, Button} from '@mui/material';
import emojiRegex from 'emoji-regex';
import {CommentBox} from 'view/common/Components';

const ItemView = props => {
  const [BText, setBText] = useState(0);
  const [CText, setCText] = useState('');
  const selectOption = [
    <Chip
      label="좋아요"
      variant="filled"
      size="small"
      key={'good'}
      style={{
        fontSize: '0.875rem',
        height: '1.5rem',
        color: 'white',
        backgroundColor: '#ed9e00',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
      }}
    />,
    <Chip
      label="코멘트"
      variant="filled"
      size="small"
      key={'comment'}
      style={{
        fontSize: '0.875rem',
        height: '1.5rem',
        color: 'white',
        backgroundColor: 'gray',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
      }}
    />,
    <Chip
      label="고쳐요"
      variant="filled"
      size="small"
      key={'fix'}
      style={{
        fontSize: '0.875rem',
        height: '1.5rem',
        color: 'white',
        backgroundColor: '#2a56c6',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
      }}
    />,
  ];
  const handleCInput = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      // if (inputVal.length > 300) {
      //   inputVal = inputVal.substring(0, 300);
      // }
      setCText(inputVal);
    }
  };
  const handleBInput = event => {
    setBText(event.target.value);
  };

  function onKeyPressed(ev) {
    let emoji = '';
    if (BText == 0) {
      emoji = 'good';
    } else if (BText == 1) {
      emoji = 'comment';
    } else if (BText == 2) {
      emoji = 'fix';
    }
    if (ev.keyCode == 13) {
      props.saveEvent(props.paragraphIndex, props.idx, emoji, CText);
    }
  }
  return (
    <CommentBox>
      <>
        <Box
          style={{
            display: 'flex',
            marginTop: '1rem',
          }}
        >
          <Select
            disableUnderline
            labelId="select-emoji"
            id="emoji"
            variant="filled"
            hiddenLabel
            onChange={handleBInput}
            value={BText}
            style={{
              color: '#FF8414',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              // backgroundColor: 'white',
              backgroundColor: '#F7F8FA',
              borderRadius: '0.625rem',
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            {selectOption.map((value, index) => {
              return (
                <MenuItem key={'key' + index} value={index}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
          <TextField
            autoComplete="off"
            variant="standard"
            fullWidth
            multiline
            InputProps={{
              disableUnderline: true,
              style: {
                paddingLeft: '1rem',
                paddingRight: '1rem',
                backgroundColor: '#F7F8FA',
                borderRadius: '0.625rem',
                border: '1px solid var(--01-gray-06, #D8DCDE)',
              },
            }}
            style={{
              flex: 1,
              color: 'black',
              borderRadius: '0.625rem',
              marginLeft: '1rem',
            }}
            value={CText}
            onChange={handleCInput}
          ></TextField>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: '1rem',
            gap: '0.6rem',
          }}
        >
          <Button
            size="small"
            style={{
              color: 'white',
              backgroundColor: '#49545C',
              borderRadius: '0.625rem',
              fontSize: '0.875rem',
              padding: '0.5rem 1.5rem',
            }}
            onClick={() => {
              let emoji = '';
              if (BText == 0) {
                emoji = 'good';
              } else if (BText == 1) {
                emoji = 'comment';
              } else if (BText == 2) {
                emoji = 'fix';
              }
              props.saveEvent(props.paragraphIndex, props.idx, emoji, CText);
            }}
          >
            저장하기
          </Button>
        </Box>
      </>
    </CommentBox>
  );
};
ItemView.propTypes = {
  paragraphIndex: PropTypes.number,
  idx: PropTypes.number,
  saveEvent: PropTypes.func,
};
export default ItemView;
