import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {ThemeProvider} from '@mui/material/styles';
import {PersistGate} from 'redux-persist/integration/react';
import {localStore, localPersistor} from 'common/store';
import 'asset/css/index.css';
import ScrollToTop from 'common/utils/ScrollToTop';
import theme from 'common/utils/Theme';
import {CookiesProvider} from 'react-cookie';
import IPInfo from 'ip-info-react';
import {ApolloProvider} from '@apollo/client';
import {ApolloClient, InMemoryCache, HttpLink} from '@apollo/client';

const GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_URI;

const client = new ApolloClient({
  link: new HttpLink({
    uri: GRAPHQL_URI,
    // useGETForQueries: true,
  }),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <CookiesProvider>
    <Provider store={localStore}>
      <PersistGate loading={null} persistor={localPersistor}>
        <BrowserRouter basename={'/'}>
          <ThemeProvider theme={theme}>
            <ScrollToTop />
            <IPInfo>
              <ApolloProvider client={client}>
                <App />
              </ApolloProvider>
            </IPInfo>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </CookiesProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
