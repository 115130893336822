import {Backdrop, CircularProgress, Box} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const ApiLoading = props => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#35D2BB',
        width: '100%',
        height: '100%',
        paddingTop: '5rem',
      }}
    >
      <CircularProgress size="10rem" color="inherit" thickness={7} />
      <Box
        style={{
          marginTop: '5rem',
          fontSize: 'calc(0.8rem + 1vw)',
          fontWeight: 'bold',
        }}
      >
        {'ㆍㆍLoadingㆍㆍ'}
      </Box>
    </Box>
  );
};

export default ApiLoading;
