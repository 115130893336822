import React from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import CustomBar from 'view/common/CustomBar';

const CustomGraph = props => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.7rem',
      }}
    >
      {props.taskClass.map((item, idx) => {
        let barColor = 'black';
        switch (idx) {
          case 0:
            barColor = '#FF9900';
            break;
          case 1:
            barColor = '#279EFF';
            break;
          case 2:
            barColor = '#F9E000';
            break;
          case 3:
            barColor = '#35D2BB';
            break;
          case 4:
            barColor = '#F24147';
            break;
        }
        if (idx > props.maxCount) {
          return;
        }
        return (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '1.25rem',
            }}
            key={'classSubmit' + idx}
          >
            <Box
              style={{
                width: '6rem',
                marginRight: '1rem',
                color: 'black',
                textAlign: 'end',
                fontSize: '0.875rem',
              }}
            >
              {item.class_name}
            </Box>
            <CustomBar
              value={item.submit_num}
              total={item.student_count}
              color={barColor}
            />
            <Box style={{color: '#ADB4BA', fontSize: '0.8rem'}}>
              <span style={{color: '#5ECECE'}}>{item.submit_num}</span>/
              {item.student_count} 명 제출
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

CustomGraph.propTypes = {
  taskClass: PropTypes.array,
  maxCount: PropTypes.number,
};

export default CustomGraph;
