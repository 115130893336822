import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React from 'react';
import {StringUtils} from 'common/utils/StringUtils';

export const TagView = props => {
  return (
    <Box
      style={{
        display: 'flex',
        backgroundColor: '#f4f4f4',
        borderRadius: '1.5rem',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1rem',
        textTransform: 'none',
        padding: '0.1rem 1rem',
      }}
    >
      {props.tag}
    </Box>
  );
};
TagView.propTypes = {
  tag: PropTypes.string,
};
export default TagView;
