import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import HeaderView from 'view/common/MobileHeader';
import PurchaseCard from 'view/purchase/MobilePurchaseCard';
import {useSelector} from 'react-redux';
import {
  PurchaseConstant1,
  PurchaseConstant2,
} from 'common/utils/PurchaseConstants';
import APIUtils from 'common/utils/APIUtils';

import {loadTossPayments} from '@tosspayments/payment-sdk';
import FooterView from 'view/common/MobileFooter';
import PurchaseSelectGroup from 'view/purchase/MobilePurchaseSelectGroup';
const clientKey = process.env.REACT_APP_TOSS_CLIENT_ID;
const tossSuccessURL = process.env.REACT_APP_TOSS_SUCCESS_URI;
const tossFailURL = process.env.REACT_APP_TOSS_FAIL_URI;

const PurchasePage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const [clickedCard, setclickedCard] = useState(1);
  const [purchaseDisabled, setPurchaseDisabled] = useState(true);

  function tossPurchase(
    item_id,
    amount,
    radioValue,
    purchaseName,
    institutionName,
  ) {
    const timestamp = new Date().getTime();
    const orderID = authReducer.user_id + '_' + item_id + '_' + timestamp;
    if (radioValue == '카드') {
      loadTossPayments(clientKey).then(tossPayments => {
        tossPayments.requestPayment('카드', {
          amount: amount, // 가격
          orderId: orderID, // 주문 id
          orderName: purchaseName, // 결제 이름
          customerName: authReducer.user_name, // 판매자, 판매처 이름
          successUrl:
            tossSuccessURL +
            '?itemId=' +
            item_id +
            '&institutionName=' +
            institutionName, // 성공시 리다이렉트 주소
          failUrl: tossFailURL, // 실패시 리다이렉트 주소
        });
      });
    } else {
      loadTossPayments(clientKey).then(tossPayments => {
        tossPayments.requestPayment('카드', {
          amount: amount, // 가격
          orderId: orderID, // 주문 id
          orderName: purchaseName, // 결제 이름
          customerName: authReducer.user_name, // 판매자, 판매처 이름
          successUrl:
            tossSuccessURL +
            '?itemId=' +
            item_id +
            '&institutionName=' +
            institutionName, // 성공시 리다이렉트 주소
          failUrl: tossFailURL, // 실패시 리다이렉트 주소
          flowMode: 'DIRECT',
          easyPay: radioValue,
        });
      });
    }
  }
  function purchaseItem(radioValue) {
    let item_id = 0;
    let amount = 0;
    let purchaseName = '';
    switch (clickedCard) {
      case 0:
        item_id = 11201;
        amount = 550000;
        purchaseName = '[개인] 3명 12개월 권';
        break;
      case 1:
        item_id = 11202;
        amount = 1100000;
        purchaseName = '[개인] 10명 12개월 권';
        break;
      case 2:
        item_id = 11203;
        amount = 2200000;
        purchaseName = '[개인] 25명 12개월 권';
        break;
      case 3:
        item_id = 11204;
        amount = 3300000;
        purchaseName = '[개인] 50명 12개월 권';
        break;
      case 4:
        item_id = 10601;
        amount = 330000;
        purchaseName = '[개인] 3명 6개월 권';
        break;
      case 5:
        item_id = 10602;
        amount = 660000;
        purchaseName = '[개인] 10명 6개월 권';
        break;
      case 6:
        item_id = 10603;
        amount = 1320000;
        purchaseName = '[개인] 25명 6개월 권';
        break;
      case 7:
        item_id = 10604;
        amount = 1980000;
        purchaseName = '[개인] 50명 6개월 권';
        break;

      default:
        break;
    }
    tossPurchase(item_id, amount, radioValue, purchaseName, '');
  }
  function onCardClick(num) {
    setclickedCard(num);
  }
  function checkPurchasable() {
    const passCheck = async () => {
      try {
        const response = await APIUtils.PassCheck(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    passCheck().then(res => {
      if (res.data.status == 'none') {
        setPurchaseDisabled(false);
      } else {
        setPurchaseDisabled(true);
      }
    });
  }
  useEffect(() => {
    checkPurchasable();
  }, []);
  return (
    <>
      <Box
        style={{
          width: '100%',
          minHeight: '100vh',
          paddingBottom: '5rem',
        }}
      >
        <HeaderView />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            style={{
              // marginTop: '3rem',
              width: '90%',
              color: '#717171',
              fontSize: '1.8rem',
              fontWeight: 'bold',
            }}
          >
            이용권 구매
          </Box>
          <Box
            style={{
              marginTop: '0.8rem',
              width: '90%',
              color: '#696969',
              fontSize: '0.7rem',
              fontWeight: '600',
            }}
          >
            ※ 여러 선생님이 함께 사용하는 학교/기관/단체 구매자는 상담을 통해
            그룹 이용권으로 구매하세요.(문의 : support@twoblockai.com)
          </Box>
          <Box
            style={{
              width: '90%',
              display: 'flex',
              marginTop: '1.5rem',
              gap: '0.5rem',
            }}
          >
            <Box
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {PurchaseConstant1.map((value, index) => {
                return (
                  <PurchaseCard
                    key={'purchaseCard' + index}
                    index={index}
                    clicked={clickedCard == index}
                    studentCount={value.studentCount}
                    classCount={value.classCount}
                    evalCost={value.evalCost}
                    evalDuration={value.evalDuration}
                    clickEvent={onCardClick}
                  />
                );
              })}
            </Box>
            <Box
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {PurchaseConstant2.map((value, index) => {
                const idx = index + 4;
                return (
                  <PurchaseCard
                    key={'purchaseCard' + idx}
                    index={idx}
                    clicked={clickedCard == idx}
                    studentCount={value.studentCount}
                    classCount={value.classCount}
                    evalCost={value.evalCost}
                    evalDuration={value.evalDuration}
                    clickEvent={onCardClick}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
        <PurchaseSelectGroup
          clickedCard={clickedCard}
          clickEvent={purchaseItem}
          purchaseDisabled={purchaseDisabled}
        />
      </Box>
      <FooterView />
    </>
  );
};
export default PurchasePage;
