import React from 'react';
import {Box, CircularProgress} from '@mui/material';

const ItemView = () => {
  return (
    <Box
      style={{
        marginBottom: '1rem',
        marginTop: '1rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size="2rem" color="inherit" thickness={5} />
    </Box>
  );
};
export default ItemView;
