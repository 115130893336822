import PropTypes from 'prop-types';
import {Box, Button, IconButton} from '@mui/material';
import React, {useState} from 'react';
import {StringUtils} from 'common/utils/StringUtils';
import iconArrowDown from 'asset/image/icon_arrow_down.png';

export const TicketView = props => {
  const receiptExist = props.receipt != '';
  const [expanded, setExpanded] = useState(false);
  const usable = StringUtils.activeToday(props.start_time, props.end_time);

  function showLabel() {
    if (usable) {
      return (
        <Box
          style={{
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: '#ff9900',
            borderRadius: '0.3rem',
            padding: '0.125rem 0.375rem',
            marginRight: '1rem',
            whiteSpace: 'nowrap',
          }}
        >
          이용
        </Box>
      );
    }
    return (
      <Box
        style={{
          fontSize: '0.875rem',
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: '#68737D',
          borderRadius: '0.3rem',
          padding: '0.125rem 0.375rem',
          marginRight: '1rem',
          whiteSpace: 'nowrap',
        }}
      >
        만료
      </Box>
    );
  }
  function showDetail() {
    if (expanded) {
      return (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '0.7rem',
          }}
        >
          <Box
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            <Box>결제일</Box> <Box>결제 영수증</Box>
          </Box>
          <Box>
            <Box style={{color: '#49545C'}}>
              {StringUtils.getDateString(props.start_time)}
            </Box>
            <Box
              style={{
                color: '#49545C',
                cursor: receiptExist ? 'pointer' : 'default',
                textDecoration: receiptExist ? 'underline' : '',
              }}
              onClick={() => {
                if (receiptExist) {
                  window.open(props.receipt, '_blank');
                }
              }}
            >
              {receiptExist ? '다운로드' : '-'}
            </Box>
          </Box>
        </Box>
      );
    }
  }
  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1.5rem',
          paddingBottom: '0.7rem',
          borderBottom: '2px solid #A8B1B7',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          {showLabel()}
          {props.ticket_name}
        </Box>
        <Box style={{display: 'flex'}}>
          {StringUtils.getDateString(props.start_time) +
            '~' +
            StringUtils.getDateString(props.end_time)}
          <IconButton
            style={{
              height: '1.5rem',
              width: '1.5rem',
              padding: 0,
              marginLeft: '0.5rem',
            }}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <img
              src={iconArrowDown}
              alt="iconArrowDown"
              style={{
                width: '1.5rem',
                height: '1.5rem',
                transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box>{showDetail()}</Box>
    </Box>
  );
};
TicketView.propTypes = {
  ticket_name: PropTypes.string,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  available: PropTypes.number,
  receipt: PropTypes.string,
};
export default TicketView;
