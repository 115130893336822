import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import HeaderView from 'view/common/Header';
import PurchaseCard from 'view/purchase/PurchaseCard';
import {useSelector} from 'react-redux';
import {
  PurchaseConstant1,
  PurchaseConstant2,
} from 'common/utils/PurchaseConstants';
import APIUtils from 'common/utils/APIUtils';

import {loadTossPayments} from '@tosspayments/payment-sdk';
import FooterView from 'view/common/Footer';
import PurchaseSelectGroup from 'view/purchase/PurchaseSelectGroup';
import iconCert1 from 'asset/imageV2/icon_cert1.svg';
import iconCert2 from 'asset/imageV2/icon_cert2.png';
import iconCert3 from 'asset/imageV2/icon_cert3.png';
import iconCert4 from 'asset/imageV2/icon_cert4.png';
import iconCert5 from 'asset/imageV2/icon_cert5.png';
import iconCert6 from 'asset/imageV2/icon_cert6.png';
import {iconBox} from 'view/main/Styles';
import SideOverlay1 from 'view/common/SideOverlay1';

const clientKey = process.env.REACT_APP_TOSS_CLIENT_ID;
const tossSuccessURL = process.env.REACT_APP_TOSS_SUCCESS_URI;
const tossFailURL = process.env.REACT_APP_TOSS_FAIL_URI;

const PurchasePage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const [clickedCard, setclickedCard] = useState(1);
  const [purchaseDisabled, setPurchaseDisabled] = useState(true);

  function tossPurchase(
    item_id,
    amount,
    radioValue,
    purchaseName,
    institutionName,
  ) {
    const timestamp = new Date().getTime();
    const orderID = authReducer.user_id + '_' + item_id + '_' + timestamp;
    if (radioValue == '카드') {
      loadTossPayments(clientKey).then(tossPayments => {
        tossPayments.requestPayment('카드', {
          amount: amount, // 가격
          orderId: orderID, // 주문 id
          orderName: purchaseName, // 결제 이름
          customerName: authReducer.user_name, // 판매자, 판매처 이름
          successUrl:
            tossSuccessURL +
            '?itemId=' +
            item_id +
            '&institutionName=' +
            institutionName, // 성공시 리다이렉트 주소
          failUrl: tossFailURL, // 실패시 리다이렉트 주소
        });
      });
    } else {
      loadTossPayments(clientKey).then(tossPayments => {
        tossPayments.requestPayment('카드', {
          amount: amount, // 가격
          orderId: orderID, // 주문 id
          orderName: purchaseName, // 결제 이름
          customerName: authReducer.user_name, // 판매자, 판매처 이름
          successUrl:
            tossSuccessURL +
            '?itemId=' +
            item_id +
            '&institutionName=' +
            institutionName, // 성공시 리다이렉트 주소
          failUrl: tossFailURL, // 실패시 리다이렉트 주소
          flowMode: 'DIRECT',
          easyPay: radioValue,
        });
      });
    }
  }
  function purchaseItem(radioValue) {
    let item_id = 0;
    let amount = 0;
    let purchaseName = '';
    switch (clickedCard) {
      case 0:
        item_id = 11201;
        amount = 550000;
        purchaseName = '[개인] 3명 12개월 권';
        break;
      case 1:
        item_id = 11202;
        amount = 1100000;
        purchaseName = '[개인] 10명 12개월 권';
        break;
      case 2:
        item_id = 11203;
        amount = 2200000;
        purchaseName = '[개인] 25명 12개월 권';
        break;
      case 3:
        item_id = 11204;
        amount = 3300000;
        purchaseName = '[개인] 50명 12개월 권';
        break;
      case 4:
        item_id = 10601;
        amount = 330000;
        purchaseName = '[개인] 3명 6개월 권';
        break;
      case 5:
        item_id = 10602;
        amount = 660000;
        purchaseName = '[개인] 10명 6개월 권';
        break;
      case 6:
        item_id = 10603;
        amount = 1320000;
        purchaseName = '[개인] 25명 6개월 권';
        break;
      case 7:
        item_id = 10604;
        amount = 1980000;
        purchaseName = '[개인] 50명 6개월 권';
        break;

      default:
        break;
    }
    tossPurchase(item_id, amount, radioValue, purchaseName, '');
  }
  function onCardClick(num) {
    setclickedCard(num);
  }
  function checkPurchasable() {
    const passCheck = async () => {
      try {
        const response = await APIUtils.PassCheck(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    passCheck().then(res => {
      if (res.data.status == 'none') {
        setPurchaseDisabled(false);
      } else {
        setPurchaseDisabled(true);
      }
    });
  }
  useEffect(() => {
    checkPurchasable();
  }, []);

  return (
    <>
      <Box
        style={{
          width: '100%',
          minWidth: '100vw',
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '5rem',
        }}
      >
        <SideOverlay1 />
        <HeaderView />
        <Box
          style={{
            marginLeft: '10%',
            marginRight: '10%',
            marginBottom: '5%',
            marginTop: '5%',
            width: '80%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            style={{
              fontSize: '2.5rem',
              fontWeight: 'bold',
              color: '#717171',
            }}
          >
            이용권 구매
          </Box>
          <Box
            style={{
              backgroundColor: '#E6E8EB',
              height: '1px',
              width: '100%',
              marginTop: '1.5rem',
            }}
          ></Box>
          <Box style={{marginTop: '1rem', color: '#68737D'}}>
            그룹 이용권 : 다수의 선생님이 다수의 학생과 교차연결되어 다양한
            조합이 가능합니다. 고객센터와 연락하여 이용권이 부여됩니다.
          </Box>
          <Box style={{color: '#68737D'}}>
            개인 이용권 : 한 분의 선생님이 다수의 학생과 연결되어 결제 즉시
            사용할 수 있습니다.
          </Box>
          <Box style={{color: '#68737D'}}>
            ※ 키위티 계정은 동시에 한가지 이용권만 사용할 수 있습니다. 이용권은
            최대 연결 가능 학생 수와 기간에 따라 분류됩니다.
          </Box>

          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginTop: '4rem',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert6}
                  alt="iconCert1"
                  style={{
                    height: '50%',
                  }}
                />
              </Box>
              <Box>국가공헌대상</Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                교육부 장관상
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert5}
                  alt="iconCert2"
                  style={{
                    height: '50%',
                  }}
                />
              </Box>
              <Box> 이러닝-에듀테크</Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                비즈모델 최우수상
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert4}
                  alt="iconCert3"
                  style={{
                    height: '50%',
                  }}
                />
              </Box>
              <Box> 에듀테크 우수기업 </Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                콘텐츠·서비스 분야
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert3}
                  alt="iconCert4"
                  style={{
                    height: '50%',
                  }}
                />
              </Box>
              <Box>TTA</Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                GS 1등급 인증
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert1}
                  alt="iconCert5"
                  style={{
                    height: '50%',
                  }}
                />
              </Box>
              <Box>조달청 인증</Box>
              <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
                벤처혁신상품
              </Box>
            </Box>
          </Box>
          <Box style={{display: 'flex', alignItems: 'end', marginTop: '7rem'}}>
            <Box
              style={{
                color: '#68737D',
                fontSize: '1.25rem',
                fontWeight: 'bold',
                borderRadius: '0.25rem',
                backgroundColor: '#E9EBED',
                padding: '0.1rem 0.7rem',
              }}
            >
              ※ 기관 구매
            </Box>
            <Box
              style={{
                fontSize: '1.25rem',
                fontWeight: 'bold',
                marginLeft: '1rem',
              }}
            ></Box>
            <Box
              style={{
                marginLeft: '2rem',
                color: '#68737D',
                fontSize: '0.875rem',
              }}
            >
              그룹 이용권을 구입하면 여러 선생님이 함께 사용할 수 있습니다.
              학교장터와 고객센터를 통해서 구매할 수 있습니다.
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{
                borderRadius: '10px',
                width: '30%',
                backgroundColor: 'white',
                marginTop: '2rem',
                boxShadow: '0px 0px 10px 3px rgba(0, 0, 0, 0.25)',
                border: '2px solid #D8DCDE',
              }}
              onClick={() => {
                window.open(
                  'https://s2b.kr/Edutech/product/productDetail.do?estimatecode=202408209320791',
                );
              }}
            >
              <Box
                style={{
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  textAlign: 'center',
                }}
              >
                <Box style={{color: '#87929D', fontSize: '0.875rem'}}>
                  공교육 기관 전용
                </Box>
                <Box
                  style={{
                    color: '#2F3941',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                  }}
                >
                  1개월권
                </Box>
                <Box
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                    color: '#4DC8B6',
                  }}
                >
                  S2B 학교장터
                  <span style={{color: '#49545C', fontSize: '1rem'}}>
                    {' 로 이동'}
                  </span>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                borderRadius: '10px',
                width: '30%',
                backgroundColor: 'white',
                marginTop: '2rem',
                boxShadow: '0px 0px 10px 3px rgba(0, 0, 0, 0.25)',
                border: '2px solid #D8DCDE',
              }}
              onClick={() => {
                window.open(
                  'https://s2b.kr/Edutech/product/productDetail.do?estimatecode=202408219323093',
                );
              }}
            >
              <Box
                style={{
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  textAlign: 'center',
                }}
              >
                <Box style={{color: '#87929D', fontSize: '0.875rem'}}>
                  공교육 기관 전용
                </Box>
                <Box
                  style={{
                    color: '#2F3941',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                  }}
                >
                  6개월권
                </Box>
                <Box
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                    color: '#4DC8B6',
                  }}
                >
                  S2B 학교장터
                  <span style={{color: '#49545C', fontSize: '1rem'}}>
                    {' 로 이동'}
                  </span>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                borderRadius: '10px',
                width: '30%',
                backgroundColor: 'white',
                marginTop: '2rem',
                boxShadow: '0px 0px 10px 3px rgba(0, 0, 0, 0.25)',
                border: '2px solid #D8DCDE',
              }}
              onClick={() => {
                window.open(
                  'https://s2b.kr/Edutech/product/productDetail.do?estimatecode=202408219323411',
                );
              }}
            >
              <Box
                style={{
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  textAlign: 'center',
                }}
              >
                <Box style={{color: '#87929D', fontSize: '0.875rem'}}>
                  공교육 기관 전용
                </Box>
                <Box
                  style={{
                    color: '#2F3941',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                  }}
                >
                  12개월권
                </Box>
                <Box
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                    color: '#4DC8B6',
                  }}
                >
                  S2B 학교장터
                  <span style={{color: '#49545C', fontSize: '1rem'}}>
                    {' 로 이동'}
                  </span>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                borderRadius: '10px',
                width: '30%',
                backgroundColor: 'white',
                marginTop: '2rem',
                boxShadow: '0px 0px 10px 3px rgba(0, 0, 0, 0.25)',
                border: '2px solid #D8DCDE',
              }}
              onClick={() => {
                window.open('http://pf.kakao.com/_XxbmzG', '_blank');
              }}
            >
              <Box
                style={{
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  textAlign: 'center',
                }}
              >
                <Box style={{color: '#87929D', fontSize: '0.875rem'}}>
                  사교육 기관 등
                </Box>
                <Box
                  style={{
                    color: '#2F3941',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                  }}
                >
                  고객 센터
                </Box>
                <Box
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginTop: '0.5rem',
                    color: '#4DC8B6',
                  }}
                >
                  카톡상담
                  <span style={{color: '#49545C', fontSize: '1rem'}}>
                    {' 으로 이동'}
                  </span>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{display: 'flex', alignItems: 'end', marginTop: '7rem'}}>
            <Box
              style={{
                color: '#68737D',
                fontSize: '1.25rem',
                fontWeight: 'bold',
                borderRadius: '0.25rem',
                backgroundColor: '#E9EBED',
                padding: '0.1rem 0.7rem',
              }}
            >
              ※ 개인 구매
            </Box>
            <Box
              style={{
                fontSize: '1.25rem',
                fontWeight: 'bold',
                marginLeft: '1rem',
              }}
            ></Box>
            <Box
              style={{
                marginLeft: '2rem',
                color: '#68737D',
                fontSize: '0.875rem',
              }}
            >
              개인 이용권을 구입하면 선생님 한명이 다수의 학생과 즉시 사용할 수
              있습니다.
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                justifyContent: 'space-between',
              }}
            >
              {PurchaseConstant1.map((value, index) => {
                return (
                  <PurchaseCard
                    key={'purchaseCard' + index}
                    index={index}
                    clicked={clickedCard == index}
                    studentCount={value.studentCount}
                    classCount={value.classCount}
                    evalCost={value.evalCost}
                    evalDuration={value.evalDuration}
                    clickEvent={onCardClick}
                  />
                );
              })}
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                justifyContent: 'space-between',
              }}
            >
              {PurchaseConstant2.map((value, index) => {
                const idx = index + 4;
                return (
                  <PurchaseCard
                    key={'purchaseCard' + idx}
                    index={idx}
                    clicked={clickedCard == idx}
                    studentCount={value.studentCount}
                    classCount={value.classCount}
                    evalCost={value.evalCost}
                    evalDuration={value.evalDuration}
                    clickEvent={onCardClick}
                  />
                );
              })}
            </Box>
          </Box>
          <Box style={{display: 'flex', alignItems: 'end', marginTop: '3rem'}}>
            <Box
              style={{
                color: '#68737D',
                fontSize: '1.25rem',
                fontWeight: 'bold',
                borderRadius: '0.25rem',
                backgroundColor: '#E9EBED',
                padding: '0.1rem 0.7rem',
              }}
            ></Box>
            <Box
              style={{
                fontSize: '1.25rem',
                fontWeight: 'bold',
                marginLeft: '1rem',
              }}
            >
              개인 구매 결제
            </Box>
            <Box
              style={{
                marginLeft: '2rem',
                color: '#68737D',
                fontSize: '0.875rem',
              }}
            >
              안전한 토스페이먼트로 결제가 진행됩니다.
            </Box>
          </Box>
          <PurchaseSelectGroup
            clickedCard={clickedCard}
            clickEvent={purchaseItem}
            purchaseDisabled={purchaseDisabled}
          />
        </Box>
      </Box>
      <FooterView />
    </>
  );
};
export default PurchasePage;
