import React, {useEffect, useState} from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {
  categoryTextBox,
  overallBox,
  overallLeftBox,
  overallTextBox,
} from 'view/result/Styles';
import {useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {AnalysisText} from 'view/result/AnalysisText';
import {NumberUtils} from 'common/utils/NumberUtils';
import {StringUtils} from 'common/utils/StringUtils';
import {
  MarginBoxComponent,
  TitleComponent2,
  WhiteBox,
} from 'view/common/Components';
import ApiLoading from 'view/common/ApiLoading';
import CommentItem from 'view/comment/CommentItem';
import CustomToggleGrade from 'view/grade/CustomToggleGrade';
import ToggleComment from 'view/grade/ToggleComment';
import DetailComment from 'asset/json/trait_comment';
import HeaderView from 'view/common/Header';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import FooterView from 'view/common/Footer';
import SavePopup from 'view/popup/Save';
import LoginPopup from 'view/popup/Login';
import WarningPopup from 'view/popup/Warning';
import EssayEditor from 'view/result/EssayEditor';
import WandImg1 from 'asset/imageV2/icon_wand1.svg';
import WandImg2 from 'asset/imageV2/icon_wand2.png';
import SaveIcon from 'asset/imageV2/icon_save.svg';
import PencilIcon2 from 'asset/imageV2/icon_pencil2.svg';
import {TrackGoogleAnalyticsEvent} from 'common/utils/GoogleAnlytics';
import RadarChart from 'view/common/RadarChart';
import OverallScore from 'view/grade/OverallScore';

const MainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const [commentParagraph, setCommentParagraph] = useState([]);
  const [modifyingParagraph, setModifyingParagraph] = useState(-1);
  const [modifyingIndex, setModifyingIndex] = useState(-1);

  const [loading, setLoading] = useState(true);
  const [onError, setOnError] = useState(false);
  const [keewiWc, setKeewiWc] = useState([]);
  const [currentText, setCurrentText] = useState('');
  const [bottomOverlay, setBottomOverlay] = useState(true);

  const [evalID, setEvalID] = useState(0);
  const [dateText, setDateText] = useState('');
  const [titleText, setTitleText] = useState('');
  const [ageText, setAgeText] = useState('');
  const [nameText, setNameText] = useState('');
  const [writingJanre, setWritingJanre] = useState('');
  const [labelText, setLabelText] = useState('');
  const [analyText, setAnalyText] = useState('');

  const [tScore, setTScore] = useState(0);
  const [tScoreComment, setTScoreComment] = useState('');
  const [savedScore, setSavedScore] = useState(-1);
  const [toggle1, setToggle1] = useState('B');
  const [toggle2, setToggle2] = useState('B');
  const [toggle3, setToggle3] = useState('B');
  const [toggle4, setToggle4] = useState('B');
  const [toggle5, setToggle5] = useState('B');
  const [toggle6, setToggle6] = useState('B');
  const [toggleComment, setToggleComment] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const [overallComment, setOverallComment] = useState('');
  const [savedComment, setSavedComment] = useState('');
  const [loadingComment, setLoadingComment] = useState(false);
  const [highlightWords, setHighlightWords] = useState([]);

  const [keewiScore, setKeewiScore] = useState({
    겹치는단어비율: 0,
    단어의평균길이: 0,
    문단별문장수: 0,
    문장별단어수: 0,
    분석오류문장수: 0,
    전체글자수: 0,
    전체문장수: 0,
    전체문단수: 0,
    흐름이자연스러운정도: 0,
    긴문장의수: 0,
    독자나이: 0,
  });
  const [radarData, setRadarData] = useState({
    labels: [],
    datasets: [],
  });
  const [originalData, setOriginalData] = useState({
    originalText: '',
    type: '',
    is_gpt_text: false,
    teacher_name: '',
    teacher_email: '',
    aes_score: 0,
    paragraph_texts: [],
  });
  //save popup values
  const [saveVisibility, setSaveVisibility] = useState(false);
  function saveCancel() {
    setSaveVisibility(false);
  }
  function saveComplete() {
    APIUtils.LoggerLog(
      authReducer.user_id,
      'keewit',
      'info',
      window.location.pathname,
      'navigate_mypage',
    );
    setSaveVisibility(false);
    navigate('/mypage', {
      replace: true,
      state: {
        fragmentNum: 3,
      },
    });
  }
  //login popup values
  const [loginVisibility, setLoginVisibility] = useState(false);
  function loginCancel() {
    setLoginVisibility(false);
  }
  function loginComplete() {
    APIUtils.LoggerLog(
      authReducer.user_id,
      'keewit',
      'info',
      window.location.pathname,
      'navigate_login',
    );
    setLoginVisibility(false);
    navigate('/login');
  }

  function setAnalysisInfoFromKeewiApiResult(res, anlayzedText) {
    setSavedScore(NumberUtils.getAnalysisNumber(res.data.result.keewi_score));
    const reactwc = res.data.result.word_cloud.map((item, index) => {
      return {text: item.name, value: item.weight};
    });
    setKeewiWc(reactwc);

    const keewiScore = {
      겹치는단어비율: NumberUtils.getAnalysisNumber(
        res.data.result.겹치는단어비율 * 100,
      ),
      단어의평균길이: NumberUtils.getAnalysisNumber(
        res.data.result.단어의평균길이,
      ),
      문단별문장수: NumberUtils.getAnalysisNumber(res.data.result.문단별문장수),
      문장별단어수: NumberUtils.getAnalysisNumber(res.data.result.문장별단어수),
      분석오류문장수: res.data.result.분석오류문장수,
      // 전체글자수: textLength,
      전체글자수: res.data.result.전체글자수,
      전체문장수: res.data.result.전체문장수,
      전체문단수: res.data.result.전체문단수,
      흐름이자연스러운정도: NumberUtils.getAnalysisNumber(
        res.data.result.흐름이자연스러운정도 * 100,
      ),
      긴문장의수: res.data.result.긴문장의수 ?? 0,
      독자나이: res.data.result.독자나이 ?? 0,
    };
    setKeewiScore(keewiScore);
    const wholeComment = [
      ...res.data.result.trait_6_comment,
      ...res.data.result.trait_5_comment,
      ...res.data.result.trait_4_comment,
      ...res.data.result.trait_3_comment,
      ...res.data.result.trait_2_comment,
      ...res.data.result.trait_1_comment,
    ];
    setHighlightWords(AnalysisText.createHighlightWordList(wholeComment));
    setCommentParagraph(
      AnalysisText.createComment(
        // location.state.text,
        anlayzedText,
        res.data.result.paragraph_texts,
        wholeComment,
      ),
    );
    setLabelText(AnalysisText.createHtmlTextLabel(res.data.result.type));
    setAnalyText(
      AnalysisText.createHtmlTextAnaly(
        res.data.result.paragraph_texts,
        res.data.result.type,
      ),
    );
    setToggle1(AnalysisText.getAlphbeticScore(res.data.result.trait_1_score));
    setToggle2(AnalysisText.getAlphbeticScore(res.data.result.trait_2_score));
    setToggle3(AnalysisText.getAlphbeticScore(res.data.result.trait_3_score));
    setToggle4(AnalysisText.getAlphbeticScore(res.data.result.trait_4_score));
    setToggle5(AnalysisText.getAlphbeticScore(res.data.result.trait_5_score));
    setToggle6(AnalysisText.getAlphbeticScore(res.data.result.trait_6_score));
    setOriginalData({
      originalText: anlayzedText,
      type: res.data.result.type,
      aes_score: res.data.result.aes_score,
      is_gpt_text: res.data.result.is_gpt_text,
      teacher_name: originalData.teacher_name,
      teacher_email: originalData.teacher_email,
      paragraph_texts: res.data.result.paragraph_texts,
    });
    let datasets = [];
    datasets = [
      {
        data: [
          res.data.result.trait_1_score,
          res.data.result.trait_2_score,
          res.data.result.trait_3_score,
          res.data.result.trait_4_score,
          res.data.result.trait_5_score,
          res.data.result.trait_6_score,
        ],
        fill: true,
        backgroundColor: ({chart: {ctx}}) => {
          const bg = ctx.createLinearGradient(90, 90, 200, 200);
          bg.addColorStop(0, 'rgba(178, 223, 51, 0.5)');
          bg.addColorStop(1, 'rgba(255, 153, 0, 0.5)');
          return bg;
        },
        borderColor: '#FF9900',
        borderWidth: 1,
        pointStyle: 'circle',
        pointBackgroundColor: 'rgba(255, 138, 0, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 0)',
      },
    ];
    const newRadarData = {
      labels: ['문법', '어휘', '표현', '문장', '조직', '주제'],
      datasets: datasets,
    };
    setRadarData(newRadarData);
  }
  function showGPTValidation() {
    if (originalData.is_gpt_text) {
      return (
        <Box
          style={{
            backgroundColor: '#f5d7da',
            color: '#bb0000',
            fontSize: '1rem',
            padding: '0.5rem',
          }}
        >
          ⚠ GPT로 생성한 글일 가능성이 높습니다.
        </Box>
      );
    }
  }
  function showTScoreComment() {
    if (tScoreComment != '') {
      return (
        <Box
          style={{
            backgroundColor: '#b9b9b9',
            color: 'white',
            textAlign: 'center',
            fontSize: '0.6rem',
            height: '1rem',
            marginTop: '0.5rem',
            borderRadius: '20px',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          {tScoreComment}
        </Box>
      );
    }
    return (
      <Box
        style={{
          height: '1rem',
          marginTop: '0.5rem',
        }}
      ></Box>
    );
  }
  function initialLoad(inputEvalID) {
    const setAnalasisResult = async eval_id => {
      try {
        const response = await APIUtils.EvalLoad(authReducer.user_id, eval_id);
        return response;
      } catch (err) {
        console.log(err);
        setOnError(true);
      }
    };
    setAnalasisResult(inputEvalID).then(res => {
      if (res.data.ret_code == 1000) {
        setTitleText(res.data.eval_result.title);
        setAgeText(res.data.eval_result.student_age);
        setNameText(res.data.eval_result.student_name);
        setWritingJanre(res.data.eval_result.writing_janre ?? '주장하는 글');
        setKeewiWc(res.data.eval_result.word_cloud);
        if (window.localStorage.getItem('text') == null) {
          setCurrentText(res.data.eval_result.originalText);
          setAnalysisInfoFromKeewiApiLoad(res);
        } else {
          setCurrentText(window.localStorage.getItem('text'));
          analyzeEvent(window.localStorage.getItem('text'));
        }
      }
    });
  }
  function setAnalysisInfoFromKeewiApiLoad(res) {
    const keewiScore = {
      겹치는단어비율: res.data.eval_result.겹치는단어비율,
      단어의평균길이: res.data.eval_result.단어의평균길이,
      문단별문장수: res.data.eval_result.문단별문장수,
      문장별단어수: res.data.eval_result.문장별단어수,
      분석오류문장수: res.data.eval_result.분석오류문장수,
      전체글자수: res.data.eval_result.전체글자수,
      전체문장수: res.data.eval_result.전체문장수,
      전체문단수: res.data.eval_result.전체문단수,
      흐름이자연스러운정도: res.data.eval_result.흐름이자연스러운정도,
      긴문장의수: res.data.eval_result.긴문장의수 ?? 0,
      독자나이: res.data.eval_result.독자나이 ?? 10,
    };
    setKeewiScore(keewiScore);

    setHighlightWords(
      AnalysisText.createHighlightWordListFromSaved(
        res.data.eval_result.commentList,
      ),
    );
    setCommentParagraph(
      AnalysisText.createCommentFromSaved(
        res.data.eval_result.originalText,
        res.data.eval_result.commentList,
      ),
    );
    setLabelText(AnalysisText.createHtmlTextLabel(res.data.eval_result.type));

    setAnalyText(
      AnalysisText.createHtmlTextAnaly(
        res.data.eval_result.paragraph_texts,
        res.data.eval_result.type,
      ),
    );
    setToggle1(res.data.eval_result.trait_1_score);
    setToggle2(res.data.eval_result.trait_2_score);
    setToggle3(res.data.eval_result.trait_3_score);
    setToggle4(res.data.eval_result.trait_4_score);
    setToggle5(res.data.eval_result.trait_5_score);
    setToggle6(res.data.eval_result.trait_6_score);
    setSavedScore(res.data.eval_result.score);
    const commentArray = [
      res.data.eval_result.trait_1_text,
      res.data.eval_result.trait_2_text,
      res.data.eval_result.trait_3_text,
      res.data.eval_result.trait_4_text,
      res.data.eval_result.trait_5_text,
      res.data.eval_result.trait_6_text,
    ];
    setToggleComment(commentArray);
    setSavedComment(res.data.eval_result.comment);
    setOriginalData({
      originalText: res.data.eval_result.originalText,
      type: res.data.eval_result.type,
      aes_score: res.data.eval_result.aes_score,
      is_gpt_text: res.data.eval_result.is_gpt_text,
      teacher_name: res.data.eval_result.teacher_name,
      teacher_email: res.data.eval_result.teacher_email,
      paragraph_texts: res.data.eval_result.paragraph_texts,
    });
    let datasets = [];
    datasets = [
      {
        data: [
          AnalysisText.getNumericScore(res.data.eval_result.trait_1_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_2_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_3_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_4_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_5_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_6_score),
        ],
        fill: true,
        backgroundColor: ({chart: {ctx}}) => {
          const bg = ctx.createLinearGradient(90, 90, 200, 200);
          bg.addColorStop(0, 'rgba(178, 223, 51, 0.5)');
          bg.addColorStop(1, 'rgba(255, 153, 0, 0.5)');
          return bg;
        },
        borderColor: '#FF9900',
        borderWidth: 1,
        pointStyle: 'circle',
        pointBackgroundColor: 'rgba(255, 138, 0, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 0)',
      },
    ];
    const newRadarData = {
      labels: ['문법', '어휘', '표현', '문장', '조직', '주제'],
      datasets: datasets,
    };
    setRadarData(newRadarData);
    setLoading(false);
  }
  function createWholeStatisticsText() {
    let flowLevel = '어려움';
    if (keewiScore.흐름이자연스러운정도 >= 71) flowLevel = '쉬움';
    else if (keewiScore.흐름이자연스러운정도 >= 68) flowLevel = '보통';
    return (
      <Box style={{width: '90%', marginBottom: '3rem'}}>
        <Box>
          {createStatisticsText('전체 글자 수', keewiScore.전체글자수, '자')}
        </Box>
        <Box>
          {createStatisticsText('전체 문단 수', keewiScore.전체문단수, '개')}
        </Box>
        <Box>
          {createStatisticsText('전체 문장 수', keewiScore.전체문장수, '개')}
        </Box>
        <Box>
          {createStatisticsText(
            '문단별 문장 수',
            keewiScore.문단별문장수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText(
            '긴 문장(50자)의 수',
            keewiScore.긴문장의수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText('예상 독자 나이', keewiScore.독자나이, '세')}
        </Box>
        <Box>{createStatisticsText('읽기 쉬운 정도', flowLevel, '')}</Box>
      </Box>
    );
  }
  function createStatisticsText(title, score, rest) {
    return (
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '0.4rem',
          fontSize: '1rem',
          fontWeight: '500',
        }}
      >
        <Box>{title}</Box>
        <Box>
          <span style={{color: '#f67f10'}}>{score}</span> {rest}
        </Box>
      </Box>
    );
  }
  function createOverallComment() {
    if (loadingComment) {
      return (
        <Box style={overallBox}>
          <Box style={overallLeftBox}>총평</Box>
          <Box
            style={{
              backgroundColor: '#D8DCDE',
              height: '2px',
              width: '100%',
              margin: '1rem 0rem',
            }}
          ></Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress size="4rem" color="inherit" thickness={5} />
          </Box>
        </Box>
      );
    }
    return (
      <Box style={overallBox}>
        <Box style={overallLeftBox}>총평</Box>
        <Box
          style={{
            backgroundColor: '#D8DCDE',
            height: '2px',
            width: '100%',
            margin: '1rem 0rem',
          }}
        ></Box>
        <TextField
          autoComplete="off"
          variant="standard"
          fullWidth
          multiline
          InputProps={{
            disableUnderline: true,
          }}
          style={overallTextBox}
          value={overallComment}
          onChange={handleComment}
        ></TextField>
        <Box style={{display: 'flex', justifyContent: 'end'}}>
          <IconButton
            onClick={() => {
              APIUtils.LoggerLog(
                authReducer.user_id,
                'keewit',
                'info',
                window.location.pathname,
                'click_event_general_review',
              );
              genralReview();
            }}
            // size="small"
            style={{height: '2.5rem', width: '2.5rem', padding: 0}}
          >
            <img
              src={WandImg1}
              alt={'wandImg'}
              style={{
                width: '2.5rem',
                height: '2.5rem',
              }}
            />
          </IconButton>
        </Box>
      </Box>
    );
  }
  function removeComment(paragraphIndex, idx) {
    // if (checkModifiable()) {
    //   return;
    // }
    const temp = [...commentParagraph];
    const tempItem = temp[paragraphIndex][idx];

    tempItem.removed = true;
    temp[paragraphIndex].splice(idx, 1, tempItem);
    setCommentParagraph(temp);
  }
  function cancelRemoveComment(paragraphIndex, idx) {
    // if (checkModifiable()) {
    //   return;
    // }
    const temp = [...commentParagraph];
    const tempItem = temp[paragraphIndex][idx];

    tempItem.removed = false;
    temp[paragraphIndex].splice(idx, 1, tempItem);
    setCommentParagraph(temp);
  }
  function addComment(paragraphIndex, idx) {
    // if (checkModifiable()) {
    //   return;
    // }
    setModifyingParagraph(paragraphIndex);
    setModifyingIndex(idx);
    const temp = [...commentParagraph];
    for (let i = 0; i < temp.length; i++) {
      const notMod = temp[i].filter(item => item.type != 'modify');
      temp[i] = notMod;
    }
    const tempItem = {type: 'modify'};
    temp[paragraphIndex].splice(temp[paragraphIndex].length - 1, 0, tempItem);

    setCommentParagraph(temp);
  }
  function refineParagraph(paragraphIndex, idx) {
    // if (checkModifiable()) {
    //   return;
    // }
    const temp = [...commentParagraph];
    const tempItem = {
      type: 'loading',
    };
    temp[paragraphIndex].splice(idx, 1, tempItem);
    setCommentParagraph(temp);
    const refineParagraphResult = async inputText => {
      try {
        const response = await APIUtils.RefineParagraphKeewi(inputText);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const splited = originalData.originalText.split('\n');
    const paragraph_text = splited[paragraphIndex - 1];

    refineParagraphResult(paragraph_text).then(r => {
      setCommentParagraph(item => {
        const temp = [...item];
        const notLoading = temp[paragraphIndex].filter(
          item => item.type != 'loading',
        );
        temp[paragraphIndex] = notLoading;
        const tempItem1 = {
          type: 'add',
        };
        if (r.data != undefined && r.data.ret_code == 1000) {
          const tempItem2 = {
            index: [],
            commentID: -1,
            removed: false,
            type: 'C',
            C: '[문단 수정 제안] ' + r.data.text,
            subtype: '',
          };
          temp[paragraphIndex].splice(
            temp[paragraphIndex].length,
            0,
            tempItem2,
            tempItem1,
          );
        } else {
          temp[paragraphIndex].splice(
            temp[paragraphIndex].length,
            0,
            tempItem1,
          );
        }
        return temp;
      });
    });
  }
  function saveComment(paragraphIndex, idx, emoji, text) {
    if (text.trim() == '') {
      return;
    }
    // if (checkModifiable()) {
    //   return;
    // }
    setModifyingParagraph(-1);
    setModifyingIndex(-1);
    const temp = [...commentParagraph];
    const tempItem = {
      index: [],
      commentID: -1,
      removed: false,
      type: 'C',
      C: text,
      subtype: emoji,
    };
    temp[paragraphIndex].splice(idx, 1, tempItem);
    setCommentParagraph(temp);
  }
  function startModifying(paragraphIndex, idx) {
    // if (checkModifiable()) {
    //   return;
    // }
    setModifyingParagraph(paragraphIndex);
    setModifyingIndex(idx);
    const temp = [...commentParagraph];
    for (let i = 0; i < temp.length; i++) {
      const notMod = temp[i].filter(item => item.type != 'modify');
      temp[i] = notMod;
    }
    setCommentParagraph(temp);
  }
  function endModifying(
    paragraphIndex,
    idx,
    modifyingA,
    modifyingB,
    modifyingC,
  ) {
    // if (checkModifiable()) {
    //   return;
    // }
    setModifyingParagraph(-1);
    setModifyingIndex(-1);
    const temp = [...commentParagraph];
    const tempItem = commentParagraph[paragraphIndex][idx];
    tempItem.A = modifyingA;
    tempItem.a = modifyingA;
    tempItem.B = modifyingB;
    tempItem.b = modifyingB;
    tempItem.C = modifyingC;
    tempItem.c = modifyingC;
    temp[paragraphIndex].splice(idx, 1, tempItem);
    setCommentParagraph(temp);
  }

  function handleToggle1(newItem) {
    // if (checkModifiable()) {
    //   return;
    // }
    setToggle1(newItem);
    turnonDetailComment(0);
    setSavedComment('');
    setSavedScore(-1);
  }
  function handleToggle2(newItem) {
    // if (checkModifiable()) {
    //   return;
    // }
    setToggle2(newItem);
    turnonDetailComment(1);
    setSavedComment('');
    setSavedScore(-1);
  }
  function handleToggle3(newItem) {
    // if (checkModifiable()) {
    //   return;
    // }
    setToggle3(newItem);
    turnonDetailComment(2);
    setSavedComment('');
    setSavedScore(-1);
  }
  function handleToggle4(newItem) {
    // if (checkModifiable()) {
    //   return;
    // }
    setToggle4(newItem);
    turnonDetailComment(3);
    setSavedComment('');
    setSavedScore(-1);
  }
  function handleToggle5(newItem) {
    // if (checkModifiable()) {
    //   return;
    // }
    setToggle5(newItem);
    turnonDetailComment(4);
    setSavedComment('');
    setSavedScore(-1);
  }
  function handleToggle6(newItem) {
    // if (checkModifiable()) {
    //   return;
    // }
    setToggle6(newItem);
    turnonDetailComment(5);
    setSavedComment('');
    setSavedScore(-1);
  }
  function toggleDetailComment(num) {
    // if (checkModifiable()) {
    //   return;
    // }
    const temp = [...toggleComment];
    temp[num] = !temp[num];
    setToggleComment(temp);
  }
  function turnonDetailComment(num) {
    const temp = [...toggleComment];
    temp[num] = true;
    setToggleComment(temp);
  }
  function setOverallCommentFromJson() {
    const currentScore = AnalysisText.calculateTscore(
      toggle1,
      toggle2,
      toggle3,
      toggle4,
      toggle5,
      toggle6,
    );
    const comment = AnalysisText.getOverallCommentFromJson(currentScore);
    setTScoreComment('');
    if (savedScore == -1) {
      setTScore(currentScore);
    } else {
      setTScore(savedScore);
    }
    if (savedComment != '') {
      setOverallComment(savedComment);
    } else {
      setOverallComment(comment);
    }
  }
  function saveResult() {
    if (checkSaveable()) {
      TrackGoogleAnalyticsEvent('User_Action', 'Click_Save_Logout', 'Modify');
      return;
    }
    TrackGoogleAnalyticsEvent('User_Action', 'Click_Save_Login', 'Modify');
    const jsonResult = AnalysisText.createSavingJson(
      commentParagraph,
      tScore,
      titleText,
      ageText,
      nameText,
      writingJanre,
      keewiWc,
      overallComment,
      toggleComment,
      toggle1,
      toggle2,
      toggle3,
      toggle4,
      toggle5,
      toggle6,
      keewiScore,
      originalData,
    );
    const modifyAnalasisResult = async result => {
      try {
        const response = await APIUtils.EvalModify(
          authReducer.user_id,
          evalID,
          result,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    modifyAnalasisResult(jsonResult).then(r => {
      setSaveVisibility(true);
    });
  }
  function handleTScore(inputVal) {
    setTScore(inputVal);
    // if (checkModifiable()) {
    //   return;
    // }
    // let inputVal = event.target.value;
    // const reg = new RegExp('^$|^[0-9.]+$');
    // if (reg.test(inputVal)) {
    //   if (inputVal >= 100) {
    //     setTScoreComment('최대 점수는 100점입니다.');
    //     setTScore('100');
    //   } else if (inputVal.length > 4) {
    //     setTScoreComment('점수는 소수점 한자리까지 표시할 수 있습니다.');
    //     setTScore(inputVal.toString().slice(0, 4));
    //   } else {
    //     setTScoreComment('');
    //     setTScore(inputVal.toString());
    //   }
    // }
  }

  const handleComment = event => {
    // if (checkModifiable()) {
    //   return;
    // }
    let inputVal = event.target.value;
    if (inputVal.length > 500) {
      inputVal = inputVal.substring(0, 500);
    }
    setOverallComment(inputVal);
  };

  function checkModifiable() {
    if (!authReducer.isLogin) {
      setLoginVisibility(true);
      return true;
    }
    return false;
  }

  function checkSaveable() {
    if (!authReducer.isLogin) {
      setLoginVisibility(true);
      return true;
    }
    return false;
  }
  function showSaveOverlay() {
    if (bottomOverlay) {
      return (
        <Box
          style={{
            // width: '100%',
            paddingBottom: '2rem',
            position: 'fixed',
            bottom: 0,
            right: '5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            zIndex: 3,
          }}
        >
          <IconButton
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#279EFF',
              border: 'solid 2px #279EFF',
              width: '5rem',
              height: '5rem',
              color: 'white',
              fontSize: '0.8rem',
            }}
            onClick={() => {
              APIUtils.LoggerLog(
                authReducer.user_id,
                'keewit',
                'info',
                window.location.pathname,
                'click_event_open_essay_editor',
              );
              setBottomOverlay(false);
            }}
          >
            <img src={PencilIcon2} alt="PencilIcon2" />
            수정하기
          </IconButton>
          <IconButton
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#35D2BB',
              border: 'solid 2px #4DC8B6',
              width: '5rem',
              height: '5rem',
              color: 'white',
              fontSize: '0.8rem',
              marginTop: '1rem',
            }}
            onClick={() => {
              APIUtils.LoggerLog(
                authReducer.user_id,
                'keewit',
                'info',
                window.location.pathname,
                'click_event_save_essay',
              );
              saveResult();
            }}
          >
            <img src={SaveIcon} alt="SaveIcon" />
            저장하기
          </IconButton>
        </Box>
      );
    }
    return (
      <EssayEditor
        currentText={currentText}
        analyzeEvent={newText => {
          setCurrentText(newText);
          window.localStorage.setItem('text', newText);
          analyzeEvent(newText);
        }}
        cancelEvent={() => {
          setBottomOverlay(true);
        }}
        highlightWordList={highlightWords}
      />
    );
  }
  function showFooter() {
    if (bottomOverlay) {
      return <FooterView />;
    }
  }
  function analyzeEvent(text) {
    setSavedComment('');
    const trimmedText = StringUtils.getTrimmedBody(text);
    setLoading(true);
    setCurrentText(text);
    const fetAnalasisResult = async (inputTitle, inputJanre, inputText) => {
      try {
        const response = await APIUtils.EvalAnalyze(
          authReducer.user_id,
          inputTitle,
          inputJanre,
          inputText,
        );
        setAnalysisInfoFromKeewiApiResult(response, inputText, text.length);
      } catch (err) {
        console.log(err);
        setOnError(true);
      }
    };
    fetAnalasisResult(titleText, writingJanre, trimmedText).then(r => {
      setLoading(false);
      setBottomOverlay(true);
    });
  }
  function genralReview() {
    if (checkSaveable()) {
      return;
    }
    setLoadingComment(true);
    const fetchGenralReviewResult = async text => {
      try {
        const score_list = [
          toggle1,
          toggle2,
          toggle3,
          toggle4,
          toggle5,
          toggle6,
        ];
        const response = await APIUtils.GeneralReviewKeewi(
          text,
          score_list,
          writingJanre,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    fetchGenralReviewResult(originalData.originalText).then(r => {
      if (r.data.ret_code == 1000) {
        setSavedComment(r.data.text);
        setSavedScore(tScore);
      }
      setLoadingComment(false);
    });
  }
  const [warningVisibility, setWarningVisibility] = useState(false);
  function warningCancel() {
    setWarningVisibility(false);
  }
  function warningConfirm() {
    setWarningVisibility(false);
    navigate('/mypage', {
      replace: true,
      state: {
        fragmentNum: 3,
      },
    });
  }
  useEffect(() => {
    setOverallCommentFromJson();
  }, [toggle1, toggle2, toggle3, toggle4, toggle5, toggle6, savedComment]);
  useEffect(() => {
    let inputEvalID = 0;
    if (
      location.state == null &&
      window.localStorage.getItem('eval_id') == null
    ) {
      navigate('/', {replace: true});
      return;
    } else if (location.state == null) {
      inputEvalID = window.localStorage.getItem('eval_id');
    } else {
      inputEvalID = location.state.eval_id;
      window.localStorage.setItem('eval_id', location.state.eval_id);
    }
    setEvalID(inputEvalID);
    setDateText(StringUtils.getTodayDateKr());
    // const setAnalasisResult = async (task_id, student_id) => {
    //   try {
    //     const response = await APIUtils.TaskEvalEdit(
    //       authReducer.user_id,
    //       task_id,
    //       student_id,
    //     );
    //     setAnalysisInfoFromKeewiApiLoad(response);
    //   } catch (err) {
    //     console.log(err);
    //     setOnError(true);
    //   }
    // };
    // setAnalasisResult(inputTaskID, inputStudentID).then(r => {
    //   setLoading(false);
    // });
    initialLoad(inputEvalID);

    const backspace = e => {
      if (
        e.keyIdentifier == 'U+0008' ||
        e.keyIdentifier == 'Backspace' ||
        e.keyCode == 8
      ) {
        if (e.target == document.body) {
          e.preventDefault();
          setWarningVisibility(true);

          return false;
        }
      }
    };
    window.addEventListener('keydown', backspace, true);
    const preventGoBack = () => {
      history.pushState(null, '', location.href);
      setWarningVisibility(true);
    };
    history.pushState(null, '', location.href);
    window.addEventListener('popstate', preventGoBack);
    return () => {
      window.removeEventListener('keydown', backspace, true);
      window.removeEventListener('popstate', preventGoBack);
    };
  }, []);
  // if (onError) return <ServerError />;
  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <WarningPopup
        visibility={warningVisibility}
        cancelEvent={warningCancel}
        confirmEvent={warningConfirm}
      />
      <SavePopup
        visibility={saveVisibility}
        cancelEvent={saveCancel}
        completeEvent={saveComplete}
      />
      <LoginPopup
        visibility={loginVisibility}
        cancelEvent={loginCancel}
        completeEvent={loginComplete}
      />
      <HeaderView />
      <TitleComponent2
        text1="평가 결과"
        text2="키위 AI 분석을 토대로 선생님의 평가와 피드백을 더하세요."
      />
      {showGPTValidation()}
      <MarginBoxComponent>
        <>
          <Box
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              color: '#68737d',
              fontWeight: '700',
              fontSize: '1rem',
              marginTop: '6.5rem',
            }}
          >
            <Box>학생 이름 | {nameText}</Box>
            <Box style={{flex: 1}}></Box>
            <Box>평가일 | {dateText}</Box>
          </Box>
          <Box
            style={{
              color: '#68737d',
              fontWeight: '700',
              fontSize: '1rem',
              marginTop: '0.5rem',
            }}
          >
            {'글 종류 | '}
            {writingJanre}
          </Box>
          <Box
            style={{
              fontSize: '2rem',
              fontWeight: 'bold',
              color: '#2F3941',
              marginTop: '1.5rem',
              marginBottom: '3.5rem',
              paddingBottom: '2rem',
              borderBottom: '2px solid #E9EBED',
            }}
          >
            {'“'}
            {titleText}
            {'”'}
          </Box>
          <Grid container spacing={'1rem'}>
            <Grid
              item
              xs={7}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  fontSize: '1.375rem',
                  fontWeight: '700',
                  marginBottom: '1.5rem',
                }}
              >
                내용 분석
              </Box>
              <WhiteBox>
                <>
                  {labelText}
                  {analyText}
                </>
              </WhiteBox>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  fontSize: '1.375rem',
                  fontWeight: '700',
                  marginBottom: '1.5rem',
                }}
              >
                글 통계 자료
              </Box>
              <WhiteBox>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    height: '100%',
                  }}
                >
                  {createWholeStatisticsText()}
                  <Box
                    style={{
                      width: '100%',
                      minHeight: '20rem',
                      backgroundColor: '#F7F8FA',
                      borderRadius: '1.25rem',
                      padding: '1.25rem 0rem',
                    }}
                  >
                    <RadarChart radarData={radarData} />
                  </Box>
                </Box>
              </WhiteBox>
            </Grid>
          </Grid>
          <Box
            style={{
              marginTop: '5.5rem',
              marginBottom: '1.5rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              style={{
                fontSize: '1.375rem',
                fontWeight: '700',
              }}
            >
              점수와 총평
            </Box>
            <Box
              style={{
                color: '#4dc8b6',
                fontSize: '0.9rem',
                marginLeft: '1rem',
                padding: '0.5rem 1.5rem',
                backgroundColor: '#F0FAF9',
                borderRadius: '6.25rem',
              }}
            >
              지표별 등급을 수정하여, 점수와 총평을 바꿀 수 있습니다. 총평은
              개별 수정도 가능합니다.
            </Box>
          </Box>
          <WhiteBox>
            <>
              <Grid container>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <OverallScore tScore={tScore} handleTscore={handleTScore} />
                </Grid>
                <Grid container item xs={9}>
                  <Grid item xs={2} sx={categoryTextBox}>
                    문법
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingTop: '0.5rem',
                      paddingRight: '2rem',
                    }}
                  >
                    <CustomToggleGrade
                      toggleVal={toggle1}
                      handleChange={handleToggle1}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingBottom: '1rem',
                      paddingRight: '2rem',
                    }}
                  >
                    <ToggleComment
                      visible={toggleComment[0]}
                      text={DetailComment['문법'][toggle1]}
                      toggleEvent={toggleDetailComment}
                      index={0}
                    />
                  </Grid>
                  <Grid item xs={2} sx={categoryTextBox}>
                    단어
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingTop: '0.5rem',
                      paddingRight: '2rem',
                    }}
                  >
                    <CustomToggleGrade
                      toggleVal={toggle2}
                      handleChange={handleToggle2}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingBottom: '1rem',
                      paddingRight: '2rem',
                    }}
                  >
                    <ToggleComment
                      visible={toggleComment[1]}
                      text={DetailComment['어휘'][toggle2]}
                      toggleEvent={toggleDetailComment}
                      index={1}
                    />
                  </Grid>
                  <Grid item xs={2} sx={categoryTextBox}>
                    표현
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingTop: '0.5rem',
                      paddingRight: '2rem',
                    }}
                  >
                    <CustomToggleGrade
                      toggleVal={toggle3}
                      handleChange={handleToggle3}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingBottom: '1rem',
                      paddingRight: '2rem',
                    }}
                  >
                    <ToggleComment
                      visible={toggleComment[2]}
                      text={DetailComment['표현'][toggle3]}
                      toggleEvent={toggleDetailComment}
                      index={2}
                    />
                  </Grid>
                  <Grid item xs={2} sx={categoryTextBox}>
                    문장
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingTop: '0.5rem',
                      paddingRight: '2rem',
                    }}
                  >
                    <CustomToggleGrade
                      toggleVal={toggle4}
                      handleChange={handleToggle4}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingBottom: '1rem',
                      paddingRight: '2rem',
                    }}
                  >
                    <ToggleComment
                      visible={toggleComment[3]}
                      text={DetailComment['문장'][toggle4]}
                      toggleEvent={toggleDetailComment}
                      index={3}
                    />
                  </Grid>
                  <Grid item xs={2} sx={categoryTextBox}>
                    구성
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingTop: '0.5rem',
                      paddingRight: '2rem',
                    }}
                  >
                    <CustomToggleGrade
                      toggleVal={toggle5}
                      handleChange={handleToggle5}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingBottom: '1rem',
                      paddingRight: '2rem',
                    }}
                  >
                    <ToggleComment
                      visible={toggleComment[4]}
                      text={DetailComment['조직'][toggle5]}
                      toggleEvent={toggleDetailComment}
                      index={4}
                    />
                  </Grid>
                  <Grid item xs={2} sx={categoryTextBox}>
                    아이디어
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingTop: '0.5rem',
                      paddingRight: '2rem',
                    }}
                  >
                    <CustomToggleGrade
                      toggleVal={toggle6}
                      handleChange={handleToggle6}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingBottom: '1rem',
                      paddingRight: '2rem',
                    }}
                  >
                    <ToggleComment
                      visible={toggleComment[5]}
                      text={DetailComment['주제'][toggle6]}
                      toggleEvent={toggleDetailComment}
                      index={5}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {createOverallComment()}
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginTop: '1rem',
                  color: '#68737D',
                  fontSize: '0.8rem',
                }}
              >
                <Box
                  style={{
                    backgroundColor: '#F0FBFA',
                    padding: '0.25rem 0.625rem',
                    borderRadius: '6.25rem',
                    fontWeight: '500',
                  }}
                >
                  <img
                    src={WandImg2}
                    alt={'wandImg2'}
                    style={{marginRight: '0.5rem'}}
                  />
                  마법봉 기능을 사용하여 총평을 생성한 경우, GPT로 생성된
                  내용이기 때문에 선생님의 검수가 필요합니다.
                </Box>
              </Box>
            </>
          </WhiteBox>
          <Box>
            {commentParagraph.map((paragraph, paragraphIndex) => {
              if (paragraph.length <= 2) {
                return;
              }
              return paragraph.map((value, idx) => {
                return (
                  <Box
                    key={'commentbox' + paragraphIndex + '_' + idx}
                    style={{margin: '2rem 0rem'}}
                  >
                    <CommentItem
                      paragraphIndex={paragraphIndex}
                      idx={idx}
                      removed={value.removed}
                      type={value.type}
                      commentID={value.commentID}
                      A={value.A}
                      B={value.B}
                      C={value.C}
                      subtype={value.subtype}
                      text={value.text}
                      removeEvent={removeComment}
                      cancelEvent={cancelRemoveComment}
                      addEvent={addComment}
                      refineEvent={refineParagraph}
                      saveEvent={saveComment}
                      modifying={
                        modifyingParagraph == paragraphIndex &&
                        modifyingIndex == idx
                      }
                      startModifyingEvent={startModifying}
                      endModifyingEvent={endModifying}
                    />
                  </Box>
                );
              });
            })}
          </Box>
        </>
      </MarginBoxComponent>
      {showSaveOverlay()}
      {showFooter()}
    </Box>
  );
};
export default MainPage;
