import {Box} from '@mui/material';
import React from 'react';
import StudentTable from 'view/mypage/StudentTable';

export const FragmentView = () => {
  return (
    <Box>
      <StudentTable />
    </Box>
  );
};
export default FragmentView;
