import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React from 'react';
import {StringUtils} from 'common/utils/StringUtils';

export const TaskView = props => {
  const dateLeft = StringUtils.getDateDiff(props.task.end_time);

  function showLabel() {
    if (props.task.status == '진행중') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: 'white',
              backgroundColor: '#68737D',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            D-{dateLeft}
          </Box>
          <Box
            style={{
              color: '#FF9900',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #D8DCDE',
            }}
          >
            진행중
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
        </Box>
      );
    } else if (props.task.status == '대기') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#68737D',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #68737D',
            }}
          >
            대기
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
        </Box>
      );
    } else if (props.task.status == '종료') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#87929D',
              backgroundColor: '#E9EBED',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            종료
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
          <Box
            style={{
              display: props.task.task_share == 1 ? '' : 'none',
              color: 'white',
              backgroundColor: '#ff9900',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            {'공유 완료'}
          </Box>
        </Box>
      );
    }
  }

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        paddingBottom: '1.7rem',
        borderBottom: '2px solid #A8B1B7',
      }}
    >
      <Box style={{display: 'flex', flexDirection: 'column', gap: '0.75rem'}}>
        <Box style={{display: 'flex', gap: '0.5rem'}}>{showLabel()}</Box>
        <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>
          {props.task.task_name}
        </Box>
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          {props.task.class_name_list
            .sort((a, b) => {
              if (a > b) return 1;
              if (a < b) return -1;
              return 0;
            })
            .map((value, idx) => {
              let classBreak = '';
              if (idx != props.task.class_name_list.length - 1) {
                classBreak = (
                  <Box
                    style={{
                      backgroundColor: '#A8B1B7',
                      width: '1px',
                      height: '0.5rem',
                      marginLeft: '0.5rem',
                    }}
                  />
                );
              }
              return (
                <Box
                  key={'class' + idx}
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  <Box
                    style={{
                      color: '#636C73',
                      fontSize: '0.875rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {value}
                  </Box>
                  {classBreak}
                </Box>
              );
            })}
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <Button
          style={{
            display:
              props.task.status == '종료' || props.task.status == '대기'
                ? ''
                : 'none',
            color: '#A8B1B7',
            fontSize: '0.875rem',
            border: '1.5px solid #A8B1B7',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1.5rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            props.removeEvent(props.task);
          }}
        >
          {'삭제'}
        </Button>
        <Button
          style={{
            color: 'black',
            fontSize: '0.875rem',
            border: '1.5px solid var(--gray-2, #E6E8EB)',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1rem',
            backgroundColor: props.task.status == '종료' ? '#E9EBED' : 'white',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            props.detailEvent(props.task);
          }}
        >
          {props.task.status == '종료' ? '결과 확인' : '과제 관리'}
        </Button>
      </Box>
    </Box>
  );
};
TaskView.propTypes = {
  task: PropTypes.object,
  detailEvent: PropTypes.func,
  removeEvent: PropTypes.func,
};
export default TaskView;
