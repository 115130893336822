import React from 'react';
import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';

const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box style={{textAlign: 'right'}}>
          <Button
            onClick={() => {
              props.cancelEvent();
            }}
            style={{color: '#717171', fontSize: '1.5rem'}}
          >
            ✖
          </Button>
        </Box>
        <Box
          style={{
            fontSize: '1.75rem',
            fontWeight: 'bold',
            marginBottom: '1.5rem',
            color: '#35D2BB',
            textAlign: 'center',
          }}
        >
          결과를 학생들에게 공유합니다.
        </Box>
        <Box style={{color: '#68737D', textAlign: 'center'}}>
          글쓰기 과제 결과와 리포트가 학생들에게 전달됩니다.
        </Box>
        <Box style={{color: '#68737D', textAlign: 'center'}}>
          결과 공유 후에는 피드백 수정이 불가능합니다.
        </Box>

        <Box style={{marginTop: '2rem', display: 'flex'}}>
          <Button
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: '#35D2BB',
              backgroundColor: 'white',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
              border: '1px solid #35D2BB',
            }}
            onClick={() => {
              props.confirmEvent(false);
            }}
          >
            점수 제거 공유하기
          </Button>
          <Button
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: '#35D2BB',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => {
              props.confirmEvent(true);
            }}
          >
            점수 포함 공유하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  cancelEvent: PropTypes.func,
  confirmEvent: PropTypes.func,
};
export default ItemView;
