import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React, {useState, useEffect} from 'react';

export const LabelView = props => {
  const ageSelectOption = ['전체', '초등', '초등', '중등', '고등', '일반'];
  const [bgColor, setBgColor] = useState('');
  useEffect(() => {
    switch (props.ageSelect) {
      case 0:
        setBgColor('');
        break;
      case 1:
      case 2:
        setBgColor('#FFE4E4');
        break;
      case 3:
        setBgColor('#CEEAFF');
        break;
      case 4:
        setBgColor('#FFD6B0');
        break;
      case 5:
        setBgColor('#E9E9E9');
        break;
      default:
        setBgColor('');
    }
  }, [props.ageSelect]);
  return (
    <Box
      style={{
        display: 'flex',
        border: 'solid 1px #D8DCDE',
        padding: '0.2rem 0.5rem',
        backgroundColor: bgColor,
        borderRadius: '0.5rem',
        color: '#68737D',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1rem',
        textTransform: 'none',
        whiteSpace: 'nowrap',
      }}
    >
      {ageSelectOption[props.ageSelect]}
    </Box>
  );
};
LabelView.propTypes = {
  ageSelect: PropTypes.number,
};
export default LabelView;
