import React from 'react';
import {Box} from '@mui/material';
import AutoSliderItem from 'view/common/AutoSliderItem';

const SliderView = () => {
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        height: '100%',
        marginTop: '4rem',
      }}
    >
      <Box
        style={{
          position: 'absolute',
          display: 'flex',
        }}
      >
        {[1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6].map((val, idx) => {
          return (
            <AutoSliderItem key={'slider' + val + idx} contentIndex={val} />
          );
        })}
      </Box>
    </Box>
  );
};
export default SliderView;
