import {Palette} from 'common/utils/ColorUtils';
export const mainWholePage = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'white',
};
export const mainBox1 = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  backgroundColor: '#3DB49E',
  paddingLeft: '5%',
  paddingRight: '5%',
};
export const mainBox2 = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: '5%',
  paddingRight: '5%',
};
export const iconBox = {
  width: '2.5rem',
  height: '2.5rem',
  borderRadius: '1.5625rem',
  border: '1px solid #F3F3F3',
  background: '#FFF',
  boxShadow: '0px 4px 10px 0px rgba(40, 173, 141, 0.15)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const mainText1 = {
  fontSize: '1.5rem',
  color: '#fef9ec',
  fontWeight: 'bold',
};
export const mainText2 = {
  color: 'white',
  fontSize: '2.2rem',
  fontWeight: 'bold',
};
export const mainText3 = {
  fontSize: 'calc(1rem + 1.2vw)',
  color: '#000000',
};
export const mainText4 = {
  color: '#717171',
  fontSize: 'calc(1rem + 1.2vw)',
};
