import PropTypes from 'prop-types';
import {Box, IconButton} from '@mui/material';
import React, {useState} from 'react';
import {StringUtils} from 'common/utils/StringUtils';

export const TicketView = props => {
  const [expanded, setExpanded] = useState(false);
  const beforeActive = StringUtils.beforeToday(props.start_time);
  const usable = StringUtils.activeToday(props.start_time, props.end_time);
  function showLabel() {
    if (beforeActive) {
      return (
        <Box
          style={{
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: '#68737D',
            borderRadius: '0.3rem',
            padding: '0.125rem 0.375rem',
            marginRight: '1rem',
            whiteSpace: 'nowrap',
          }}
        >
          예정
        </Box>
      );
    }
    if (usable) {
      return (
        <Box
          style={{
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: '#ff9900',
            borderRadius: '0.3rem',
            padding: '0.125rem 0.375rem',
            marginRight: '1rem',
            whiteSpace: 'nowrap',
          }}
        >
          이용
        </Box>
      );
    }
    return (
      <Box
        style={{
          fontSize: '0.875rem',
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: '#68737D',
          borderRadius: '0.3rem',
          padding: '0.125rem 0.375rem',
          marginRight: '1rem',
          whiteSpace: 'nowrap',
        }}
      >
        만료
      </Box>
    );
  }
  function showDetail() {
    if (expanded) {
      return (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '0.7rem',
          }}
        >
          <Box>
            <Box>잔여 이용권</Box> <Box>나의 소진 건수</Box>
          </Box>
          <Box>
            {/* <Box style={{color: '#FF9900'}}>{props.available + '건'}</Box>
            <Box style={{color: '#35D2BB'}}>{props.used + '건'}</Box> */}
          </Box>
        </Box>
      );
    }
  }
  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1.5rem',
          paddingBottom: '0.7rem',
          borderBottom: '2px solid #A8B1B7',
        }}
      >
        <Box style={{display: 'flex', alignItems: 'flex-start'}}>
          {showLabel()}
          {props.group_name}
        </Box>
        <Box style={{display: 'flex'}}>
          {StringUtils.getDateString(props.start_time) +
            '~' +
            StringUtils.getDateString(props.end_time)}
          {/* <IconButton
            style={{
              height: '1.5rem',
              width: '1.5rem',
              padding: 0,
              marginLeft: '0.5rem',
            }}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <img
              src={iconArrowDown}
              alt="iconArrowDown"
              style={{
                width: '1.5rem',
                height: '1.5rem',
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </IconButton> */}
        </Box>
      </Box>
      {/* <Box>{showDetail()}</Box> */}
    </Box>
  );
};
TicketView.propTypes = {
  group_name: PropTypes.string,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
};
export default TicketView;
