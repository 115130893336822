import React, {useState} from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Switch,
  Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import {styled} from '@mui/material/styles';

const CustomSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
  width: '2.875rem',
  height: '1.25rem',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(1.5rem)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#35D2BB',
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: '1.1rem',
    height: '1.1rem',
    marginTop: '0.1rem',
    marginLeft: '0.1rem',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#A7A7A7',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ItemView = props => {
  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '1rem',
          gap: '5rem',
        }}
      >
        <Box
          style={{
            flex: 1,
            fontSize: '1.5rem',
            fontWeight: 'bold',
            borderBottom: '2px solid #A7A7A7',
            paddingRight: '1rem',
          }}
        >
          {props.titleText}
        </Box>
        <IconButton onClick={props.cancelEvent}>
          <img src={IconCancel} />
        </IconButton>
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '0.5rem',
          fontWeight: 'bold',
        }}
      >
        <Box style={{fontWeight: '500'}}>AI 피드백 기능</Box>
        <CustomSwitch
          checked={props.feedbackChecked}
          onChange={props.handleFeedbackChange}
        />
      </Box>
      <Box
        style={{
          marginTop: '0.5rem',
          fontSize: '0.75rem',
          color: '#68737D',
        }}
      >
        {props.feedbackChecked
          ? '키위 점수와 AI 피드백을 제공합니다. 글을 수정하여 보다 완성된 과제를 제출할 수 있습니다.'
          : ' AI 피드백 없이 과제를 수행하므로 시험이나 대회 목적에 적합합니다.'}
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '0.5rem',
          fontWeight: 'bold',
        }}
      >
        <Box style={{fontWeight: '500'}}>키위챗 기능</Box>
        <CustomSwitch
          checked={props.keewichatChecked}
          onChange={props.handleKeewichatChange}
        />
      </Box>
      <Box
        style={{
          marginTop: '0.5rem',
          fontSize: '0.75rem',
          color: '#68737D',
        }}
      >
        {'과제의 조건과 아이디어에 대해 이야기를 나눌 수 있는 키위챗입니다.'}
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '0.5rem',
          fontWeight: 'bold',
        }}
      >
        <Box style={{fontWeight: '500'}}>필수 글자 수(공백 포함)</Box>
        <CustomSwitch
          checked={props.textCountChecked}
          onChange={props.handleTextCountChange}
        />
      </Box>
      <Box style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
        <TextField
          disabled={!props.textCountChecked}
          autoComplete="off"
          sx={{
            width: '7rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&:hover fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
            },
          }}
          variant="outlined"
          InputProps={{
            style: {
              backgroundColor: '#F7F8FA',
              fontWeight: 500,
            },
          }}
          value={props.textCountMin}
          onChange={props.handleTextCountMin}
        ></TextField>
        <Box
          style={{
            whiteSpace: 'nowrap',
            marginLeft: '1rem',
            fontWeight: 'bold',
          }}
        >
          {'자'}
        </Box>
        <Box
          style={{
            whiteSpace: 'nowrap',
            marginLeft: '2rem',
            marginRight: '2rem',
            fontWeight: 'bold',
          }}
        >
          {'~'}
        </Box>
        <TextField
          disabled={!props.textCountChecked}
          autoComplete="off"
          sx={{
            width: '7rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&:hover fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
            },
          }}
          variant="outlined"
          InputProps={{
            style: {
              backgroundColor: '#F7F8FA',
              fontWeight: 500,
            },
          }}
          value={props.textCountMax}
          onChange={props.handleTextCountMax}
        ></TextField>
        <Box
          style={{whiteSpace: 'nowrap', marginLeft: '1rem', fontWeight: 'bold'}}
        >
          자
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '0.5rem',
          fontWeight: 'bold',
        }}
      >
        <Box style={{fontWeight: '500'}}>필수 키워드(최대 5개)</Box>
        <CustomSwitch
          checked={props.requiredWordChecked}
          onChange={props.handleRequiredWordChange}
        />
      </Box>
      <Box
        style={{
          marginTop: '1rem',
        }}
      >
        <TextField
          disabled={!props.requiredWordChecked}
          autoComplete="off"
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&:hover fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
            },
          }}
          variant="outlined"
          fullWidth
          InputProps={{
            style: {
              backgroundColor: '#F7F8FA',
              fontWeight: 500,
            },
          }}
          label={
            props.requiredWord == ''
              ? '쉼표로 단어를 구분해 주세요. (예) 감사, 혐오, 핵가족 등 최대 5개'
              : ''
          }
          InputLabelProps={{
            style: {
              color: 'rgba(108, 108, 108, 0.721)',
              fontSize: '0.875rem',
            },
            shrink: false,
          }}
          value={props.requiredWord}
          onChange={props.handelRequiredWord}
        ></TextField>
      </Box>
      <Box style={{marginTop: '4rem', display: 'flex'}}>
        <Button
          style={{
            flex: '1',
            whiteSpace: 'nowrap',
            color: '#35D2BB',
            backgroundColor: 'white',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            padding: '0.9rem 0rem',
            borderRadius: '6.25rem',
            border: '1px solid #35D2BB',
          }}
          onClick={() => props.previousEvent()}
        >
          {'< 이전'}
        </Button>
        <Button
          style={{
            flex: '3',
            whiteSpace: 'nowrap',
            color: 'white',
            backgroundColor: '#35D2BB',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            padding: '0.9rem 0rem',
            marginLeft: '1rem',
            borderRadius: '6.25rem',
          }}
          onClick={() => props.nextEvent()}
        >
          {'step 03. 학생 선택하기 '}
        </Button>
      </Box>
      <Box
        style={{
          fontSize: '0.75rem',
          color: '#68737D',
          textAlign: 'end',
          marginTop: '0.5rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        과제를 수행할 학생을 선택합니다. class별/개인별로 리스트를 확인할 수
        있습니다.
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  titleText: PropTypes.string,
  feedbackChecked: PropTypes.bool,
  handleFeedbackChange: PropTypes.func,
  keewichatChecked: PropTypes.bool,
  handleKeewichatChange: PropTypes.func,
  textCountChecked: PropTypes.bool,
  handleTextCountChange: PropTypes.func,
  textCountMin: PropTypes.string,
  handleTextCountMin: PropTypes.func,
  textCountMax: PropTypes.string,
  handleTextCountMax: PropTypes.func,
  requiredWordChecked: PropTypes.bool,
  handleRequiredWordChange: PropTypes.func,
  requiredWord: PropTypes.string,
  handelRequiredWord: PropTypes.func,
  previousEvent: PropTypes.func,
  nextEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
};
export default ItemView;
