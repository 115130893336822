export const loginBox = {
  coloredBox: function (mycolor) {
    return {
      color: 'black',
      width: '100%',
      backgroundColor: mycolor,
      paddingTop: '1rem',
      paddingBottom: '1rem',
      paddingLeft: '5rem',
      paddingRight: '5rem',
      fontSize: 'calc(0.5rem + 0.5vw)',
    };
  },
};
export const headerBox1 = {
  display: 'flex',
  flexWrap: 'nowrap',
  width: '90%',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '2rem',
  paddingBottom: '2rem',
  paddingLeft: '5%',
  paddingRight: '5%',
};
export const headerBox2 = {
  display: 'flex',
  flexWrap: 'wrap',
  paddingLeft: '15%',
  paddingRight: '15%',
  width: '70%',
  maxWidth: '80rem',
  alignItems: 'center',
  backgroundColor: 'white',
  justifyContent: 'center',
  paddingTop: '2rem',
  paddingBottom: '2rem',
  borderBottom: '1px solid rgba(233, 235, 237, 1)',
};
export const headerBox3 = {
  display: 'flex',
  flexWrap: 'wrap',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
};
export const footerBox1 = {
  paddingLeft: '10%',
  paddingRight: '10%',
  width: '80%',
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  paddingTop: '3rem',
  paddingBottom: '3rem',
  borderTop: '1px solid #D8DCDE',
};
export const wholeLoginPage = {
  width: '100%',
  minWidth: '100vw',
  minHeight: '100vh',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
export const loginTitle1 = {
  marginTop: '10rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  fontWeight: 'normal',
  fontSize: 'calc(0.7rem + 0.7vw)',
  width: '40rem',
};
export const loginTitle2 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  fontWeight: 'bold',
  fontSize: 'calc(1rem + 2vw)',
  width: '40rem',
};

export const loginKakaoBox = {
  marginTop: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
export const loginNaverBox = {
  marginTop: '4rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
export const loginGoogleBox = {
  marginTop: '4rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
