import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {NumberUtils} from 'common/utils/NumberUtils';

const ItemView = props => {
  return (
    <Box
      style={{
        display: 'flex',
        borderRadius: '10px',
        border: '2px solid #00B0A6',
        width: '80%',
        backgroundColor: props.clicked ? 'rgba(53, 210, 187, 0.1)' : 'white',
        marginTop: '1rem',
        padding: '1rem',
        boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.25)',
      }}
      onClick={() => {
        props.clickEvent(props.index);
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          style={{
            color: '#87929D',
            fontSize: '0.7rem',
          }}
        >
          {props.evalDuration + '개월'}
        </Box>
        <Box
          style={{
            color: '#2F3941',
            // color: props.clicked ? '#ffffff' : '#00B0A6',
            fontSize: '1.4rem',
            fontWeight: 'bold',
          }}
        >
          {'학생 ' + props.studentCount + '명'}
        </Box>
        <Box
          style={{
            fontSize: '1.2rem',
            fontWeight: 'bold',
            color: '#4DC8B6',
          }}
        >
          {NumberUtils.getThousandComma(props.evalCost)}
          <span
            style={{color: '#49545C', fontSize: '0.8rem', marginLeft: '0.2rem'}}
          >
            원
          </span>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  index: PropTypes.number,
  clicked: PropTypes.bool,
  clickEvent: PropTypes.func,
  studentCount: PropTypes.number,
  evalCost: PropTypes.number,
  evalDuration: PropTypes.number,
};
export default ItemView;
