import React, {useState, useEffect} from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import IconWand from 'asset/imageV2/icon_wand1.svg';
import APIUtils from 'common/utils/APIUtils';
import writingSelectOption from 'asset/json/writing_select_option1.json';
import ageSelectOption from 'asset/json/age_select_option2.json';

const ItemView = props => {
  const [loading, setLoading] = useState(false);
  const [topicText, setTopicText] = useState('');
  const handleTopic = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setTopicText(inputVal);
  };
  const [titleText, setTitleText] = useState('');
  const handleTitle = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 25) {
      inputVal = inputVal.substring(0, 25);
    }
    setTitleText(inputVal);
  };
  const [memoText, setMemoText] = useState('');
  const handleMemo = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 1000) {
      inputVal = inputVal.substring(0, 1000);
    }
    setMemoText(inputVal);
  };

  const [writingSelect, setWritingSelect] = useState(0);

  const handleWritingSelectInput = event => {
    setWritingSelect(event.target.value);
  };

  const [ageSelect, setAgeSelect] = useState(0);

  const handleAgeSelectInput = event => {
    setAgeSelect(event.target.value);
  };

  function tryCreateLibrary() {
    props.confirmEvent(
      titleText,
      memoText,
      writingSelectOption[writingSelect],
      ageSelect + 1,
    );
  }
  function libraryGPTContext() {
    setLoading(true);
    const createLibraryContextApi = async () => {
      try {
        const response = await APIUtils.LibraryContext(
          writingSelectOption[writingSelect],
          ageSelect + 1,
          topicText,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    createLibraryContextApi().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setTitleText(res.data.task_title);
        setMemoText(res.data.task_message);
      }
      setLoading(false);
    });
  }
  function createGptButton() {
    if (loading) {
      return (
        <Box style={{color: '#35D2BB', marginLeft: '1rem'}}>
          <CircularProgress size="2.5rem" color="inherit" thickness={5} />
        </Box>
      );
    } else {
      return (
        <IconButton
          onClick={() => {
            libraryGPTContext();
          }}
        >
          <img src={IconWand} style={{width: '2.5rem'}} />
        </IconButton>
      );
    }
  }
  useEffect(() => {
    setTitleText('');
    setMemoText('');
    setTopicText('');
    setWritingSelect(0);
    setAgeSelect(0);
  }, [props.visibility]);

  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '0.5rem',
            }}
          >
            <Box
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: '1.5rem',
                marginTop: '1rem',
              }}
            >
              GPT 과제 생성
            </Box>
            <IconButton onClick={props.cancelEvent}>
              <img src={IconCancel} />
            </IconButton>
          </Box>
          <Box
            style={{
              backgroundColor: '#35D2BB2E',
              padding: '1rem',
              borderRadius: '0.5rem',
              marginTop: '1rem',
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box style={{fontWeight: '500'}}>평가 장르</Box>
              <Box style={{width: '30%'}}>
                <Select
                  disableUnderline
                  fullWidth
                  labelId="select-writing"
                  id="wrintingType"
                  variant="filled"
                  hiddenLabel
                  onChange={handleWritingSelectInput}
                  value={writingSelect}
                  style={{
                    backgroundColor: '#F7F8FA',
                    border: '0px solid black',
                    borderRadius: '0.625rem',
                    fontSize: '0.875rem',
                  }}
                >
                  {writingSelectOption.map((value, index) => {
                    return (
                      <MenuItem
                        key={'key' + index}
                        value={index}
                        style={{fontSize: '0.875rem'}}
                      >
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box style={{fontWeight: '500'}}>대상</Box>
              <Box style={{width: '30%'}}>
                <Select
                  disableUnderline
                  fullWidth
                  labelId="select-writing"
                  id="ageSelection"
                  variant="filled"
                  hiddenLabel
                  onChange={handleAgeSelectInput}
                  value={ageSelect}
                  style={{
                    backgroundColor: '#F7F8FA',
                    border: '0px solid black',
                    borderRadius: '0.625rem',
                    fontSize: '0.875rem',
                  }}
                >
                  {ageSelectOption.map((value, index) => {
                    return (
                      <MenuItem
                        key={'key' + index}
                        value={index}
                        style={{fontSize: '0.875rem'}}
                      >
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Box>
            <Box style={{marginTop: '1rem', fontWeight: '500'}}>과제 주제</Box>
            <Box style={{marginTop: '1rem', display: 'flex', gap: '1rem'}}>
              <TextField
                autoComplete="off"
                fullWidth
                variant="outlined"
                label={
                  topicText == '' ? '과제 주제를 간단하게 작성해 주세요.' : ''
                }
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #EBEEF4',
                      borderRadius: '0.625rem',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #EBEEF4',
                      borderRadius: '0.625rem',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #EBEEF4',
                      borderRadius: '0.625rem',
                    },
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: '1rem',
                    color: '#A8B1B7',
                  },
                  shrink: false,
                }}
                InputProps={{
                  style: {
                    fontSize: '1rem',
                    backgroundColor: 'white',
                    fontWeight: 500,
                    borderRadius: '0.625rem',
                  },
                }}
                value={topicText}
                onChange={handleTopic}
              ></TextField>
              {createGptButton()}
            </Box>
          </Box>
          <Box
            style={{
              marginTop: '2rem',
              fontWeight: '500',
            }}
          >
            과제 제목
          </Box>
          <Box
            style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}
          >
            <TextField
              autoComplete="off"
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #EBEEF4',
                    borderRadius: '0.625rem',
                  },
                  '&:hover fieldset': {
                    border: '1px solid #EBEEF4',
                    borderRadius: '0.625rem',
                  },
                  '&.Mui-focused fieldset': {
                    border: '1px solid #EBEEF4',
                    borderRadius: '0.625rem',
                  },
                },
              }}
              InputProps={{
                style: {
                  backgroundColor: '#F7F8FA',
                  fontWeight: 500,
                },
              }}
              value={titleText}
              onChange={handleTitle}
            ></TextField>
          </Box>
          <Box
            style={{
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box style={{fontWeight: '500'}}>과제 내용(문항, 전달 사항)</Box>
            <Box>
              <span style={{color: '#35D2BB'}}>{memoText.length}</span>
              /1000
            </Box>
          </Box>
          <Box
            style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}
          >
            <TextField
              autoComplete="off"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #EBEEF4',
                    borderRadius: '0.625rem',
                  },
                  '&:hover fieldset': {
                    border: '1px solid #EBEEF4',
                    borderRadius: '0.625rem',
                  },
                  '&.Mui-focused fieldset': {
                    border: '1px solid #EBEEF4',
                    borderRadius: '0.625rem',
                  },
                },
              }}
              variant="outlined"
              fullWidth
              InputProps={{
                style: {
                  backgroundColor: '#F7F8FA',
                  fontWeight: 500,
                },
              }}
              rows={10}
              multiline
              value={memoText}
              onChange={handleMemo}
            ></TextField>
          </Box>
          <Box style={{marginTop: '2rem', display: 'flex'}}>
            <Button
              disabled={titleText == '' || memoText == ''}
              style={{
                width: '100%',
                whiteSpace: 'nowrap',
                color: 'white',
                backgroundColor:
                  titleText == '' || memoText == '' ? '#A8B1B7' : '#35D2BB',
                fontSize: '1.125rem',
                fontWeight: 'bold',
                padding: '0.9rem 0rem',
                marginLeft: '1rem',
                borderRadius: '6.25rem',
              }}
              onClick={() => tryCreateLibrary()}
            >
              {'과제 저장하기'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  cancelEvent: PropTypes.func,
  confirmEvent: PropTypes.func,
};
export default ItemView;
