import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React, {useState} from 'react';
import iconExclamation from 'asset/imageV2/icon_exclamation2.png';
import GroupCodePopup from 'view/popup/GroupCodePopup';
import CodePopup from 'view/popup/Code';
import {useNavigate} from 'react-router-dom';
import CryptoJS from 'crypto-js';
import ClipboardCopy from 'view/common/ClipboardCopy';
import ToastPopup from 'view/popup/ToastPopup';
import {StringUtils} from 'common/utils/StringUtils';
import APIUtils from 'common/utils/APIUtils';
import {saveGroup} from 'common/reducer/auth';
import {useDispatch, useSelector} from 'react-redux';
import NoticketPopup from 'view/popup/NoTicket';

export const SidebarView = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  //code popup values
  const [codeVisibility, setCodeVisibility] = useState(false);
  function codeCancel() {
    setCodeVisibility(false);
    props.getPassInfo();
  }

  const [groupCodeVisibility, setGroupCodeVisibility] = useState(false);
  function groupCodeCancel() {
    setGroupCodeVisibility(false);
  }
  const [toastText, setToastText] = useState('');
  const [toastVisibility, setToastVisibility] = useState(false);
  function groupCodeConfirm() {
    getGroupInfo();
    setGroupCodeVisibility(false);
  }

  const [noticketVisibility, setNoticketVisibility] = useState(false);
  function noticketCancel() {
    setNoticketVisibility(false);
  }
  function noticketComplete() {
    APIUtils.LoggerLog(
      authReducer.user_id,
      'keewit',
      'info',
      window.location.pathname,
      'navigate_purchase',
    );
    setNoticketVisibility(false);
    navigate('/purchase');
  }
  function getGroupInfo() {
    const getGroupInfo = async () => {
      try {
        const response = await APIUtils.GroupInfo(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getGroupInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        dispatch(
          saveGroup({
            group_name: res.data.group_name,
          }),
        );
        props.getPassInfo();
        // getPassInfo();
      }
    });
  }
  function getStudentLink() {
    const encryptedID = CryptoJS.AES.encrypt(
      authReducer.user_id.toString(),
      process.env.REACT_APP_AES_KEY,
    )
      .toString()
      .replaceAll('/', '$keewi$');
    const link =
      process.env.REACT_APP_BASE_URI + '/writing/student/' + encryptedID;
    return link;
  }
  function handleCopyResult(text) {
    setToastText(text);
    setToastVisibility(true);
    setTimeout(() => {
      setToastVisibility(false);
    }, 2500);
  }
  function showGroupInfo() {
    if (authReducer.group_name == null || authReducer.group_name == '') {
      return (
        <Box
          style={{
            borderRadius: '0.625rem',
            backgroundColor: '#f7f8fa',
            padding: '1.2rem 0.3rem 1.2rem 0.8rem',
            fontSize: '0.8rem',
            color: '#87929d',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={iconExclamation} alt="iconExclamation" />
          그룹 정보를 등록해주세요
          <Button
            style={{
              fontSize: '0.875rem',
              color: '#49545c',
              whiteSpace: 'nowrap',
            }}
            onClick={() => {
              setGroupCodeVisibility(true);
            }}
          >
            등록하기
          </Button>
        </Box>
      );
    }
    return (
      <Box style={{color: '#49545C', fontWeight: 'bold'}}>
        {authReducer.group_name}
      </Box>
    );
  }
  function showTicketInfo() {
    if (props.passStatus == 'none') {
      return (
        <Box
          style={{
            color: '#87929d',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            marginTop: '0.5rem',
          }}
        >
          KEEwiT 이용권 미결제
        </Box>
      );
    }
    let statusText = '그룹';
    if (props.passStatus == 'individual') {
      statusText = '개인';
    } else if (props.passStatus == 'free') {
      statusText = '무료';
    }
    return (
      <Box
        style={{
          color: '#87929d',
          fontSize: '0.875rem',
          fontWeight: 'bold',
          marginTop: '0.5rem',
        }}
      >
        {'[' + statusText + '] ' + props.passStudentCount + '명'}
        <span style={{color: '#ff9900', marginLeft: '0.2rem'}}>
          {'~' + StringUtils.getDateKr(props.passEndTime)}
        </span>
      </Box>
    );
  }
  return (
    <Box style={{display: 'flex', flexDirection: 'column', minWidth: '11rem'}}>
      <GroupCodePopup
        visibility={groupCodeVisibility}
        confirmEvent={groupCodeConfirm}
        cancelEvent={groupCodeCancel}
      />
      <ToastPopup text={toastText} visibility={toastVisibility} />
      <CodePopup visibility={codeVisibility} cancelEvent={codeCancel} />
      <NoticketPopup
        visibility={noticketVisibility}
        cancelEvent={noticketCancel}
        completeEvent={noticketComplete}
      />
      {showGroupInfo()}
      <Box
        style={{
          fontWeight: 'bold',
          fontSize: '1.75rem',
          marginTop: '1rem',
          marginBottom: '0.5rem',
        }}
      >
        {authReducer.export_name + ' 님'}
      </Box>
      <Box style={{color: '#68737d'}}>{authReducer.user_email}</Box>
      <Box
        style={{
          color: '#68737d',
          display: 'flex',
          alignItems: 'center',
          gap: '0.2rem',
          fontWeight: '500',
        }}
      >
        <Box>인증 번호:</Box>
        <Box
          style={{
            padding: '0.2rem',
          }}
        >
          {authReducer.auth_key == '' || authReducer.auth_key == undefined
            ? '0000'
            : authReducer.auth_key.substring(0, 4)}
        </Box>
        -{' '}
        <Box
          style={{
            padding: '0.2rem',
          }}
        >
          {authReducer.auth_key == '' || authReducer.auth_key == undefined
            ? '0000'
            : authReducer.auth_key.substring(4, 8)}
        </Box>
      </Box>
      {showTicketInfo()}
      <Box
        style={{
          marginTop: '2.5rem',
          border: '1px solid var(--01-gray-06, #D8DCDE)',
          borderRadius: '0.625rem',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          minWidth: '15rem',
        }}
      >
        <Button
          style={{color: '#49545c', fontWeight: 'bold'}}
          onClick={() => setCodeVisibility(true)}
        >
          무료 이용권
        </Button>
        <Box
          style={{width: '1px', height: '0.5rem', backgroundColor: '#D8DCDE'}}
        ></Box>
        <Button
          style={{color: '#49545c', fontWeight: 'bold'}}
          onClick={() => {
            APIUtils.LoggerLog(
              authReducer.user_id,
              'keewit',
              'info',
              window.location.pathname,
              'navigate_purcahse',
            );
            navigate('/purchase');
          }}
        >
          이용권 구매
        </Button>
      </Box>
      <Box
        style={{
          marginTop: '1rem',
          border: '1px solid var(--01-gray-06, #D8DCDE)',
          borderRadius: '0.625rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: '15rem',
        }}
      >
        <ClipboardCopy
          text={'학생 글 제출 링크'}
          link={getStudentLink()}
          resultEvent={handleCopyResult}
          buttonType={0}
        />
      </Box>
      <Box
        style={{
          marginTop: '3rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'start',
        }}
      >
        <Button
          style={{
            color: props.currentFragment == 1 ? '#35D2BB' : '#2f3941',
            backgroundColor:
              props.currentFragment == 1 ? 'rgba(53, 210, 187, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(1);
          }}
        >
          마이페이지
        </Button>
        <Button
          style={{
            color: props.currentFragment == 2 ? '#35D2BB' : '#2f3941',
            backgroundColor:
              props.currentFragment == 2 ? 'rgba(53, 210, 187, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(2);
          }}
        >
          계정 관리
        </Button>
        <Button
          style={{
            color: props.currentFragment == 3 ? '#35D2BB' : '#2f3941',
            backgroundColor:
              props.currentFragment == 3 ? 'rgba(53, 210, 187, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(3);
          }}
        >
          평가 관리
        </Button>
        <Button
          style={{
            color: props.currentFragment == 4 ? '#35D2BB' : '#2f3941',
            backgroundColor:
              props.currentFragment == 4 ? 'rgba(53, 210, 187, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
            textTransform: 'none',
          }}
          onClick={() => {
            props.clickEvent(4);
          }}
        >
          학생/Class 관리
        </Button>
        <Button
          style={{
            color: props.currentFragment == 5 ? '#35D2BB' : '#2f3941',
            backgroundColor:
              props.currentFragment == 5 ? 'rgba(53, 210, 187, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(5);
          }}
        >
          과제 관리
        </Button>
        <Button
          style={{
            color: props.currentFragment == 8 ? '#35D2BB' : '#2f3941',
            backgroundColor:
              props.currentFragment == 8 ? 'rgba(53, 210, 187, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            if (props.passStatus == 'none') {
              setNoticketVisibility(true);
            } else {
              props.clickEvent(8);
            }
          }}
        >
          라이브러리
        </Button>
      </Box>
    </Box>
  );
};
SidebarView.propTypes = {
  currentFragment: PropTypes.number,
  passStatus: PropTypes.string,
  passEndTime: PropTypes.string,
  passStudentCount: PropTypes.number,
  clickEvent: PropTypes.func,
  getPassInfo: PropTypes.func,
};

export default SidebarView;
