import React from 'react';
import {Box} from '@mui/material';
import backgroundSlider from 'asset/imageV2/background_slider.png';
import PropTypes from 'prop-types';
import SliderBox from './AutoSliderStyle';

const SliderView = props => {
  function getTitleText() {
    switch (props.contentIndex) {
      case 1:
        return '의도의 표현';
      case 2:
        return '어휘의 풍부성';
      case 3:
        return '문법의 정확성';
      case 4:
        return '문장 구사력';
      case 5:
        return '조직과 전개';
      case 6:
        return '주제의 명확성';
      default:
        return '';
    }
  }
  function getFirstLineText() {
    switch (props.contentIndex) {
      case 1:
        return '글의 응집성을 높이는 방법';
      case 2:
        return '다양한 단어의 정확한 의미 전달';
      case 3:
        return '정확한 어문 규범 준수';
      case 4:
        return '완급을 조절하는 글쓰기';
      case 5:
        return '목적에 맞는 글의 형태와 구조';
      case 6:
        return '글의 통일성을 높이는 방법';
      default:
        return '';
    }
  }
  function getSecondLineText() {
    switch (props.contentIndex) {
      case 1:
        return '글쓴이의 의도를 드러내는';
      case 2:
        return '중복된 어휘 사용 지양';
      case 3:
        return '한글 맞춤법, 띄어쓰기';
      case 4:
        return '단문과 복문의 균형';
      case 5:
        return '장르에 맞는 문단 전개';
      case 6:
        return '글의 중심 내용 표현';
      default:
        return '';
    }
  }
  function getThirdLineText() {
    switch (props.contentIndex) {
      case 1:
        return '접속 표지 활용';
      case 2:
        return '문맥에 맞는 정확한 단어 사용';
      case 3:
        return '정확한 문장 부호 활용';
      case 4:
        return '지나치게 긴 문장 사용 지양';
      case 5:
        return '내용 전개 순서';
      case 6:
        return '하나의 주제에 대한 글쓰기';
      default:
        return '';
    }
  }
  return (
    <SliderBox>
      <Box
        style={{
          color: 'white',
          backgroundColor: '#35D2BB',
          borderRadius: '2.5rem',
          padding: '0.25rem 1.125rem',
          fontSize: '700',
        }}
      >
        {/* 풍부 */}
        {getTitleText()}
      </Box>
      <Box
        style={{
          whiteSpace: 'nowrap',
          backgroundColor: 'white',
          borderRadius: '1rem',
          padding: '0rem 0.5rem 2rem 0.5rem',
          marginTop: '1.25rem',
          textAlign: 'center',
          width: '15rem',
        }}
      >
        <img
          src={backgroundSlider}
          alt="backgroundSlider"
          style={{width: '100%'}}
        />
        <Box style={{marginTop: '1rem', fontWeight: '700'}}>
          {getFirstLineText()}
        </Box>
        <Box style={{color: '#68737D', marginTop: '0.5rem'}}>
          {getSecondLineText()}
        </Box>
        <Box style={{color: '#68737D'}}>{getThirdLineText()}</Box>
      </Box>
    </SliderBox>
  );
};
SliderView.propTypes = {
  contentIndex: PropTypes.number,
};
export default SliderView;
