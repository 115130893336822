import {Box, Button, Select, MenuItem, TextField} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState} from 'react';
import Pagination from 'view/mypage/Pagination2';
import APIUtils from 'common/utils/APIUtils';
import LibraryView2 from './LibraryView2';
import {StringUtils} from 'common/utils/StringUtils';
import PropTypes from 'prop-types';
import {useNavigate, useLocation} from 'react-router-dom';
import ApiLoading from 'view/common/ApiLoading2';
import LibraryDetailPopup from 'view/popup/LibraryDetailPopup';
import writingSelectOption from 'asset/json/writing_select_option2.json';
import ageSelectOption from 'asset/json/age_select_option3.json';

const FragmentView = () => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);

  const [loading, setLoading] = useState(true);
  const [libraryList, setLibraryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [tabStatus, setTabStatus] = useState(1);
  const [selectedLibrary, setSelectedLibrary] = useState(undefined);

  const [writingSelect, setWritingSelect] = useState(0);
  const handleWritingSelectInput = event => {
    setCurrentPage(1);
    setWritingSelect(event.target.value);
  };

  const [ageSelect, setAgeSelect] = useState(0);
  const handleAgeSelectInput = event => {
    setCurrentPage(1);
    setAgeSelect(event.target.value);
  };

  const [searchText, setSearchText] = useState('');
  const handleSearchText = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setSearchText(inputVal);
  };

  function paginationClickEvent(page) {
    setCurrentPage(page);
    // getLibraryList(tabStatus, page);
  }

  const [libraryDetailVisibility, setLibraryDetailVisibility] = useState(false);

  function libraryDetail(inputLibrary) {
    setSelectedLibrary(inputLibrary);
    setLibraryDetailVisibility(true);
  }
  function libraryDetailCancel() {
    setSelectedLibrary(undefined);
    setLibraryDetailVisibility(false);
  }
  function libraryDetailConfirm() {
    const downloadApi = async (userID, libraryID) => {
      try {
        const response = await APIUtils.LibraryDownload(userID, libraryID);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    downloadApi(authReducer.user_id, selectedLibrary.library_id).then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        // getLibraryList(tabStatus, 1);
        setCurrentPage(1);
        alert("'저장한 과제'에 저장되었습니다.");
      }
      setSelectedLibrary(undefined);
      setLibraryDetailVisibility(false);
    });
  }

  function getLibraryList() {
    setLoading(true);
    const getLibrary = async (
      input_age,
      input_janre,
      input_tag,
      input_sort,
      input_page,
    ) => {
      try {
        const response = await APIUtils.LibrarySharedList(
          input_age,
          input_janre,
          input_tag,
          input_sort,
          input_page,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const janre = writingSelect == 0 ? '' : writingSelectOption[writingSelect];
    getLibrary(
      ageSelect,
      janre,
      searchText.trim(),
      tabStatus,
      currentPage,
    ).then(res => {
      console.log(res);
      if (res.status == 200 && res.data.ret_code == 1000) {
        setTotalPage(res.data.total_page);
        setLibraryList(res.data.library_list);
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    getLibraryList();
  }, [tabStatus, currentPage, writingSelect, ageSelect]);
  if (loading) {
    return <ApiLoading />;
  }
  return (
    <Box>
      <LibraryDetailPopup
        visibility={libraryDetailVisibility}
        selectedLibraryID={
          selectedLibrary == undefined ? 0 : selectedLibrary.library_id
        }
        cancelEvent={libraryDetailCancel}
        downloadEvent={libraryDetailConfirm}
      />
      <Box
        style={{
          marginTop: '2rem',
          alignItems: 'center',
          backgroundColor: '#35D2BB2E',
          border: '1px solid #35D2BB',
          padding: '1rem',
        }}
      >
        <Box style={{color: '#35D2BB', fontWeight: 'bold'}}>
          다른 선생님이 공유한 과제를 검색해 보세요!
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Select
            labelId="select-age"
            id="ageType"
            variant="outlined"
            onChange={handleAgeSelectInput}
            value={ageSelect}
            style={{
              backgroundColor: 'white',
              // border: '0px solid black',
              borderRadius: '0.825rem',
              padding: '0rem 1rem',
              fontSize: '0.9rem',
              height: '3rem',
            }}
          >
            {ageSelectOption.map((value, index) => {
              return (
                <MenuItem key={'key' + index} value={index}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
          <Select
            labelId="select-writing"
            id="wrintingType"
            variant="outlined"
            onChange={handleWritingSelectInput}
            value={writingSelect}
            style={{
              backgroundColor: 'white',
              // border: '0px solid black',
              borderRadius: '0.825rem',
              padding: '0rem 1rem',
              fontSize: '0.9rem',
              height: '3rem',
            }}
          >
            {writingSelectOption.map((value, index) => {
              return (
                <MenuItem key={'key' + index} value={index}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
          <Box style={{flex: 1}}>
            <TextField
              fullWidth
              autoComplete="off"
              size="small"
              variant="outlined"
              sx={{
                marginLeft: '0.5rem',
                backgroundColor: 'white',
                borderRadius: '0.5rem',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #E9EBED',
                    borderRadius: '0.5rem',
                  },
                  '&:hover fieldset': {
                    border: '1px solid #E9EBED',
                    borderRadius: '0.5rem',
                  },
                  '&.Mui-focused fieldset': {
                    border: '1px solid #E9EBED',
                    borderRadius: '0.5rem',
                  },
                },
              }}
              label={searchText == '' ? '관련 태그' : ''}
              InputLabelProps={{
                style: {
                  color: 'rgba(108, 108, 108, 0.721)',
                  fontSize: '1rem',
                },
                shrink: false,
              }}
              InputProps={{style: {fontSize: '1rem'}}}
              value={searchText}
              onChange={handleSearchText}
            ></TextField>
          </Box>
          <Button
            style={{
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: '#35D2BB',
              borderRadius: '1.5rem',
              padding: '0.5rem 1.5rem',
            }}
            onClick={() => {
              setCurrentPage(1);
              getLibraryList();
            }}
          >
            {'🔍 검색'}
          </Button>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          marginTop: '2rem',
          alignItems: 'center',
        }}
      >
        <Button
          style={{
            color: tabStatus == 1 ? '#35D2BB' : '#ADB4BA',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            border: tabStatus == 1 ? '2px solid #35D2BB' : '2px solid #ADB4BA',
            borderRadius: 0,
            padding: '0rem 1rem',
            height: '2.5rem',
            minWidth: 0,
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            if (tabStatus != 1) {
              setTabStatus(1);
              setCurrentPage(1);
            }
          }}
        >
          최신순
        </Button>
        <Button
          style={{
            color: tabStatus == 2 ? '#35D2BB' : '#ADB4BA',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            border: tabStatus == 2 ? '2px solid #35D2BB' : '2px solid #ADB4BA',
            borderRadius: 0,
            padding: '0rem 1rem',
            height: '2.5rem',
            minWidth: 0,
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            if (tabStatus != 2) {
              setTabStatus(2);
              setCurrentPage(1);
            }
          }}
        >
          인기순
        </Button>
      </Box>
      <Box>
        {libraryList.map((value, index) => {
          return (
            <LibraryView2
              key={'task' + index}
              detailEvent={libraryDetail}
              library={value}
            />
          );
        })}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};
FragmentView.displayName = 'LibraryTab2';
export default FragmentView;
