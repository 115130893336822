import moment from 'moment/moment';
export const StringUtils = {
  getTrimmedBody: function (input = '') {
    const splited = input.split('\n');
    // 24.10.31 문단 들여쓰기 허용, CHOYH
    // .map(val => val.trim())
    // .filter(val => val != '')
    let res = splited.join('\n');
    // 24.10.31 문단 들여쓰기 허용, CHOYH
    // res = res.replace(/  */g, ' ');
    return res;
  },

  getDateString: function (input = '') {
    if (input == null) {
      return '';
    }
    const temp1 = input.split(' ')[0];
    return temp1;
  },
  getTodayDateKr: function () {
    const date = new Date();
    const dateText =
      date.getFullYear() +
      '년   ' +
      (date.getMonth() + 1) +
      '월 ' +
      date.getDate() +
      '일';
    return dateText;
  },
  getDateKr: function (inputDate) {
    const date = new Date(inputDate);
    const dateText =
      date.getFullYear() +
      '년   ' +
      (date.getMonth() + 1) +
      '월 ' +
      date.getDate() +
      '일';
    return dateText;
  },
  getDateTimeKr: function (inputDate) {
    const date = new Date(inputDate);
    const dateText =
      date.getFullYear() +
      '년   ' +
      (date.getMonth() + 1) +
      '월 ' +
      date.getDate() +
      '일 ' +
      (date.getHours() < 10 ? '0' : '') +
      date.getHours() +
      ':' +
      (date.getMinutes() < 10 ? '0' : '') +
      date.getMinutes();
    return dateText;
  },
  getTodayDate: function () {
    const date = new Date();
    const dateText =
      date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate();
    return dateText;
  },
  getDateOnly: function (date) {
    const dateText =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 < 10 ? '0' : '') +
      (date.getMonth() + 1) +
      '-' +
      date.getDate();
    return dateText;
  },
  getTodayDateOnly: function () {
    const date = new Date();
    return this.getLocalISOString(date).substring(0, 10);
  },
  getTodayDateMinOnly: function () {
    const date = new Date();
    return this.getLocalISOString(date).substring(0, 16);
  },
  getMonthPlusDate: function (number) {
    const today = new Date();
    const date = new Date(today.setMonth(today.getMonth() + number));
    const dateText =
      date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate();
    return dateText;
  },
  getDatePlusDate: function (fromDate, number) {
    const date = new Date(fromDate).setDate(fromDate.getDate() + number);
    return new Date(date);
  },
  getDatePlusMoth: function (fromDate, number) {
    const date = new Date(fromDate).setMonth(fromDate.getMonth() + number);
    return new Date(date);
  },
  getDateDiff: function (endDate) {
    const date = new Date(endDate);
    const today = new Date();
    const dateDiff = moment(date).diff(today, 'days');
    return dateDiff;
  },
  getHourDiff: function (endDate) {
    const date = new Date(endDate);
    const now = new Date();
    const dateDiff = (now - date) / 3600000;
    return dateDiff;
  },
  getFormattedString: function (newDate) {
    let dateString =
      newDate.getFullYear() +
      '-' +
      (newDate.getMonth() + 1) +
      '-' +
      newDate.getDate() +
      ' ' +
      newDate.getHours() +
      ':' +
      newDate.getMinutes() +
      ':' +
      newDate.getSeconds();
    return dateString;
  },
  activeToday: function (startDate, endDate) {
    const today = new Date();
    const end = new Date(endDate);
    if (startDate == '') {
      if (today < end) {
        return true;
      }
      return false;
    }
    const start = new Date(startDate);
    if (start < today && today < end) {
      return true;
    }
    return false;
  },
  beforeToday: function (startDate) {
    const today = new Date();
    const start = new Date(startDate);
    if (start < today) {
      return false;
    }
    return true;
  },
  compareDate: function (inputDate1, inputDate2) {
    if (inputDate1 == '' || inputDate2 == '') {
      return true;
    }
    const date1 = new Date(inputDate1);
    const date2 = new Date(inputDate2);
    if (date1 > date2) {
      return false;
    }
    return true;
  },
  getLocalISOString: function (date) {
    const offset = date.getTimezoneOffset();
    const offsetAbs = Math.abs(offset);
    const isoString = new Date(
      date.getTime() - offset * 60 * 1000,
    ).toISOString();
    return `${isoString.slice(0, -1)}${offset > 0 ? '-' : '+'}${String(
      Math.floor(offsetAbs / 60),
    ).padStart(2, '0')}:${String(offsetAbs % 60).padStart(2, '0')}`;
  },
};
