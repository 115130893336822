import PropTypes from 'prop-types';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ArrowDown from 'asset/image/icon_arrow_down.png';

export const NoticeItem = props => {
  return (
    <Box>
      <Box>
        <Accordion>
          <AccordionSummary
            style={{padding: '2rem'}}
            expandIcon={
              <img
                src={ArrowDown}
                alt={'iconArrow'}
                style={{
                  width: '3rem',
                  height: '3rem',
                  transform: 'rotate(180deg)',
                }}
              />
            }
          >
            <Box style={{fontSize: '1.5rem'}}>
              {props.title}
              <br />
              <br />
              <span style={{fontSize: '1rem'}}>{props.time}</span>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: '3rem',
              backgroundColor: '#f3f3f3',
              whiteSpace: 'pre-wrap',
              lineHeight: '1.8rem',
            }}
          >
            <div dangerouslySetInnerHTML={{__html: props.content}}></div>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};
NoticeItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  time: PropTypes.string,
};
