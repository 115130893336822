import {Box, TextField, Checkbox, Button} from '@mui/material';
import {useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import APIUtils from 'common/utils/APIUtils';
import Pagination from 'view/mypage/Pagination';
import AuthTableItem from './AuthTableItem';
import AuthAcceptPopup from 'view/popup/AuthAcceptPopup';
import AuthRejectPopup from 'view/popup/AuthRejectPopup';
import StudentExceedPopup from 'view/popup/StudentExceed';

export const FragmentView = () => {
  const authReducer = useSelector(state => state.authReducer);
  const [originalAuthenticationTable, setOriginalAuthenticationTable] =
    useState([]);
  const [authenticationTable, setAuthenticationTable] = useState([]);

  const [availableStudentCount, setAvailableStudentCount] = useState(0);
  const [registeredStudentList, setRegisteredStudentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkList, setCheckList] = useState([]);
  const numPerPage = 15;
  const totalPage = Math.floor(
    (authenticationTable.length - 1) / numPerPage + 1,
  );
  function handleCheck(idx) {
    const temp = [...checkList];
    const removeIdx = temp.indexOf(idx);
    if (removeIdx == -1) {
      temp.push(idx);
    } else {
      temp.splice(removeIdx, 1);
    }
    setCheckList(temp);
  }
  function clickEvent(page) {
    setCheckList([]);
    setCurrentPage(page);
  }
  const [searchText, setSearchText] = useState('');
  const handleSearchText = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setSearchText(inputVal);
  };

  //auth accept popup
  const [authAcceptVisibility, setAuthAcceptVisibility] = useState(false);
  function confirmAuthAccept() {
    authAccept();
  }
  function cancelAuthAccept() {
    setAuthAcceptVisibility(false);
    setCheckList([]);
  }
  //auth reject popup
  const [authRejectVisibility, setAuthRejectVisibility] = useState(false);
  function confirmAuthReject() {
    authReject();
  }
  function cancelAuthReject() {
    setAuthRejectVisibility(false);
    setCheckList([]);
  }

  //student exceed popup
  const [studentExceedVisibility, setStudentExceedVisibility] = useState(false);

  function cancelStudentExceed() {
    setStudentExceedVisibility(false);
    setCheckList([]);
  }
  function handleCheckAll() {
    if (
      authenticationTable.filter((value, index) => {
        if (Math.floor(index / 15) + 1 == currentPage) {
          return true;
        }
        return false;
      }).length == checkList.length
    ) {
      setCheckList([]);
    } else {
      const checkLength = authenticationTable.filter((value, index) => {
        if (Math.floor(index / 15) + 1 == currentPage) {
          return true;
        }
        return false;
      }).length;
      setCheckList([...Array(checkLength).keys()]);
    }
  }
  function authAccept() {
    const studentList = checkList.map(
      i => authenticationTable[i + (currentPage - 1) * numPerPage].student_id,
    );
    const authAcceptApi = async inputStudents => {
      try {
        const response = await APIUtils.ClassAuthAccept(
          authReducer.user_id,
          inputStudents,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    authAcceptApi(studentList).then(res => {
      if (res.data.ret_code == 1000) {
        getAuthList();
      }
      setAuthAcceptVisibility(false);
      setCheckList([]);
    });
  }
  function authReject() {
    const studentList = checkList.map(
      i => authenticationTable[i + (currentPage - 1) * numPerPage].student_id,
    );
    const authRejectApi = async inputStudents => {
      try {
        const response = await APIUtils.ClassAuthReject(
          authReducer.user_id,
          inputStudents,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    authRejectApi(studentList).then(res => {
      if (res.data.ret_code == 1000) {
        getAuthList();
      }
      setAuthRejectVisibility(false);
      setCheckList([]);
    });
  }
  function getAuthList() {
    const authHistory = async () => {
      try {
        const response = await APIUtils.ClassAuth(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    authHistory().then(res => {
      setOriginalAuthenticationTable(res.data.auth_student_list);
    });
  }

  function filterAuthTable(searchValue) {
    if (
      originalAuthenticationTable == undefined ||
      originalAuthenticationTable.length == 0
    ) {
      setAuthenticationTable([]);
      return;
    }
    let filtered = [...originalAuthenticationTable];
    if (searchValue != undefined && searchValue.trim() != '') {
      filtered = filtered.filter(item => {
        return (
          item.student_name != undefined &&
          item.student_name.includes(searchValue)
        );
      });
    }
    setAuthenticationTable(filtered);
  }
  function acceptEvent(index) {
    const studentList = checkList.map(
      i => authenticationTable[i + (currentPage - 1) * numPerPage].student_id,
    );
    const addingStudents = studentList.filter(
      val => !registeredStudentList.includes(val),
    );
    if (addingStudents.length > availableStudentCount) {
      setStudentExceedVisibility(true);
    } else {
      setCheckList([index]);
      setAuthAcceptVisibility(true);
    }
  }
  function denyEvent(index) {
    setCheckList([index]);
    setAuthRejectVisibility(true);
  }
  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.PassCheck(authReducer.user_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          if (response.data.status != 'none') {
            setAvailableStudentCount(
              response.data.max_student_count -
                response.data.student_id_list.length,
            );
            setRegisteredStudentList(response.data.student_id_list);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }
  useEffect(() => {
    filterAuthTable(searchText);
  }, [originalAuthenticationTable, searchText]);

  useEffect(() => {
    getAuthList();
    getPassInfo();
  }, []);

  return (
    <Box style={{marginTop: '1rem'}}>
      <AuthAcceptPopup
        visibility={authAcceptVisibility}
        cancelEvent={cancelAuthAccept}
        confirmEvent={confirmAuthAccept}
      />
      <AuthRejectPopup
        visibility={authRejectVisibility}
        cancelEvent={cancelAuthReject}
        confirmEvent={confirmAuthReject}
      />
      <StudentExceedPopup
        visibility={studentExceedVisibility}
        cancelEvent={cancelStudentExceed}
      />
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          gap: '0.5rem',
        }}
      >
        <TextField
          autoComplete="off"
          size="small"
          variant="outlined"
          sx={{
            backgroundColor: 'white',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px solid black',
              },
              '&:hover fieldset': {
                border: '1px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid black',
              },
            },
          }}
          label={searchText == '' ? '🔍 검색(학생 이름)' : ''}
          InputLabelProps={{
            style: {
              color: 'rgba(108, 108, 108, 0.721)',
              fontSize: 'calc(0.3rem + 0.5vw)',
            },
            shrink: false,
          }}
          InputProps={{style: {fontSize: 'calc(0.3rem + 0.5vw)'}}}
          value={searchText}
          onChange={handleSearchText}
        ></TextField>
      </Box>
      <Box
        style={{
          backgroundColor: '#F7F8FA',
          borderRadius: '0.625rem',
          marginTop: '1rem',
        }}
      >
        <Checkbox
          checked={
            authenticationTable.filter((value, index) => {
              if (Math.floor(index / 15) + 1 == currentPage) {
                return true;
              }
              return false;
            }).length == checkList.length && checkList.length != 0
          }
          onChange={() => {
            handleCheckAll();
          }}
          style={{color: '#35D2BB'}}
        />
      </Box>
      <Box>
        {authenticationTable
          .filter((value, index) => {
            if (Math.floor(index / numPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((val, idx) => {
            return (
              <AuthTableItem
                key={'authItem' + idx}
                student_email={val.student_email}
                student_name={val.student_name}
                student_memo={val.student_memo}
                handleCheck={handleCheck}
                acceptEvent={acceptEvent}
                denyEvent={denyEvent}
                index={idx}
                checked={checkList.includes(idx)}
              />
            );
          })}
      </Box>
      <Box style={{marginTop: '1rem', display: 'flex', gap: '0.5rem'}}>
        <Button
          style={{
            border: '1px solid #E9EBED',
            height: '2.5rem',
            borderRadius: '0.9rem',
            color: 'black',
            fontSize: '0.875rem',
            whiteSpace: 'nowrap',
            padding: '0rem 1rem',
          }}
          onClick={() => {
            if (checkList.length != 0) {
              const studentList = checkList.map(
                i =>
                  authenticationTable[i + (currentPage - 1) * numPerPage]
                    .student_id,
              );
              const addingStudents = studentList.filter(
                val => !registeredStudentList.includes(val),
              );
              if (addingStudents.length > availableStudentCount) {
                setStudentExceedVisibility(true);
              } else {
                setAuthAcceptVisibility(true);
              }
            }
            // setStudentRemoveVisibility(true);
          }}
        >
          선택 수락
        </Button>
        <Button
          style={{
            border: '1px solid #E9EBED',
            height: '2.5rem',
            borderRadius: '0.9rem',
            color: 'black',
            fontSize: '0.875rem',
            whiteSpace: 'nowrap',
            padding: '0rem 1rem',
          }}
          onClick={() => {
            if (checkList.length != 0) {
              setAuthRejectVisibility(true);
            }
          }}
        >
          선택 거절
        </Button>
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          paddingBottom: '2rem',
          fontWeight: 'bold',
          fontSize: 'calc(0.8rem + 0.5vw)',
        }}
      >
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          clickEvent={clickEvent}
        />
      </Box>
    </Box>
  );
};
export default FragmentView;
