import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React from 'react';

export const SidebarView = props => {
  return (
    <Box style={{display: 'flex', flexDirection: 'column', minWidth: '15rem'}}>
      <Box style={{fontWeight: 'bold', fontSize: '1.5rem', marginTop: '1rem'}}>
        {'그룹 관리자 메뉴'}
      </Box>

      <Box
        style={{
          marginTop: '3rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'start',
        }}
      >
        <Button
          style={{
            color: props.currentFragment == 1 ? '#35D2BB' : '#2f3941',
            backgroundColor:
              props.currentFragment == 1 ? 'rgba(53, 210, 187, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(1);
          }}
        >
          그룹 관리
        </Button>
        <Button
          style={{
            color: props.currentFragment == 2 ? '#35D2BB' : '#2f3941',
            backgroundColor:
              props.currentFragment == 2 ? 'rgba(53, 210, 187, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(2);
          }}
        >
          계정 관리
        </Button>
      </Box>
    </Box>
  );
};
SidebarView.propTypes = {
  currentFragment: PropTypes.number,
  clickEvent: PropTypes.func,
};

export default SidebarView;
