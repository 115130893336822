import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {
  subtitleBox,
  subtitleLeftBox,
  subtitleRightBox,
} from 'view/result/Styles';

const ItemView = props => {
  return (
    <Box style={subtitleBox}>
      <Box style={subtitleLeftBox}>{props.A}</Box>

      <Box style={subtitleRightBox}>{props.B}</Box>
    </Box>
  );
};
ItemView.propTypes = {
  A: PropTypes.string,
  B: PropTypes.string,
  // C: PropTypes.string,
};
export default ItemView;
