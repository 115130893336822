import PropTypes from 'prop-types';
import {Box, Button, Checkbox} from '@mui/material';
import React from 'react';

const AuthTableItem = props => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #68737D',
        paddingBottom: '1.5rem',
        paddingTop: '1.5rem',
      }}
    >
      <Box style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
        <Box>
          <Checkbox
            checked={props.checked}
            onChange={() => {
              props.handleCheck(props.index);
            }}
            inputProps={{'aria-label': 'controlled'}}
            style={{color: '#35D2BB'}}
          />
        </Box>
        <Box style={{display: 'flex', gap: '0.5rem', flexDirection: 'column'}}>
          <Box style={{color: 'black', fontSize: '1rem', fontWeight: 'bold'}}>
            {props.student_name}
            <span
              style={{
                color: '#636C73',
                fontSize: '0.875rem',
                marginLeft: '0.5rem',
              }}
            >
              {props.student_email}
            </span>
          </Box>
          <Box style={{color: '#636C73', fontSize: '0.875rem'}}>
            {props.student_memo}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '1rem',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Button
          style={{
            backgroundColor: '#35D2BB',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: 'white',
            borderRadius: '1.9rem',
            whiteSpace: 'nowrap',
            padding: '0rem 2.5rem',
            height: '3rem',
          }}
          onClick={() => {
            props.acceptEvent(props.index);
          }}
        >
          수락
        </Button>
        <Button
          style={{
            border: '1.5px solid #E9EBED',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#262c31',
            borderRadius: '1.9rem',
            whiteSpace: 'nowrap',
            padding: '0rem 2.5rem',
            height: '3rem',
          }}
          onClick={() => {
            props.denyEvent(props.index);
          }}
        >
          거절
        </Button>
      </Box>
    </Box>
  );
};
export default AuthTableItem;
AuthTableItem.propTypes = {
  student_email: PropTypes.string,
  student_name: PropTypes.string,
  student_memo: PropTypes.string,
  handleCheck: PropTypes.func,
  acceptEvent: PropTypes.func,
  denyEvent: PropTypes.func,
  index: PropTypes.number,
  checked: PropTypes.bool,
};
