import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, TextField, Button} from '@mui/material';
import backgroundWings from 'asset/imageV2/background_wings.png';

const ItemView = props => {
  const [editScore, setEditScore] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const handleEditScore = event => {
    let inputVal = event.target.value;
    const reg = new RegExp('^$|^[0-9.]+$');
    if (reg.test(inputVal)) {
      if (inputVal >= 100) {
        setEditScore('100');
      } else if (inputVal.length > 4) {
        setEditScore(inputVal.toString().slice(0, 4));
      } else {
        // setTScoreComment('');
        setEditScore(inputVal.toString());
      }
    }
  };
  useEffect(() => {
    setEditScore(props.tScore);
  }, [props.tScore]);

  function showEditScore() {
    if (showEdit) {
      return (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <TextField
            autoComplete="off"
            variant="standard"
            InputProps={{
              disableUnderline: true,
              style: {
                fontSize: '1rem',
                fontWeight: '700',
                textAlign: 'center',
                color: '#FF9900',
              },
            }}
            value={editScore}
            onChange={handleEditScore}
            style={{
              width: '2.5rem',
              textAlign: 'center',
            }}
          ></TextField>
          <Button
            style={{
              fontSize: '1rem',
              fontWeight: '500',
              textAlign: 'center',
              color: '#FF9900',
              textDecoration: 'underline',
            }}
            onClick={() => {
              props.handleTscore(Number(editScore));
              setShowEdit(false);
            }}
          >
            수정
          </Button>
        </Box>
      );
    }
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <Button
          style={{
            fontSize: '1rem',
            fontWeight: '500',
            textAlign: 'center',
            color: '#FF9900',
            textDecoration: 'underline',
          }}
          onClick={() => {
            setShowEdit(true);
          }}
        >
          총점 수정
        </Button>
      </Box>
    );
  }
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        style={{
          backgroundColor: 'rgba(255, 153, 0, 0.1)',
          boxShadow: '2px 5px 25px 4px rgba(224, 161, 65, 0.12)',
          border: '2px solid #FCE9CE',
          borderRadius: '1.25rem',
          maxWidth: '20rem',
          width: '100%',
          // aspectRatio: '1',
          height: '15rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          style={{
            color: 'white',
            backgroundColor: '#FF9900',
            borderRadius: '6.25rem',
            padding: '0.625rem 1.5625rem',
          }}
        >
          총점
        </Box>
        <Box
          style={{
            backgroundImage: `url(${backgroundWings})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            // backgroundColor: 'red',
            padding: '1rem 0rem 2rem 0rem',
            maxWidth: '20rem',
            width: '100%',
            fontWeight: '700',
            color: '#ff9900',
            fontSize: '2.5vw',
            marginBottom: '1rem',
            textAlign: 'center',
          }}
        >
          {props.tScore}
        </Box>
      </Box>
      {showEditScore()}
    </Box>
  );
};
ItemView.propTypes = {
  tScore: PropTypes.number,
  handleTscore: PropTypes.func,
};
export default ItemView;
