import React from 'react';
import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';

const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box
          style={{
            fontSize: '1.75rem',
            fontWeight: 'bold',
            marginBottom: '0.5rem',
            marginTop: '1.5rem',
            textAlign: 'center',
            color: '#35D2BB',
          }}
        >
          선택한 학생을 확인하세요
        </Box>
        <Box style={{color: 'black', textAlign: 'center'}}>
          선택한 학생이 과제에 포함되어 있습니다.
        </Box>
        <Box style={{color: 'black', textAlign: 'center'}}>
          과제 삭제/종료 후 학생을 삭제해 주세요.
        </Box>
        <Box
          style={{
            color: '#68737D',
            textAlign: 'center',
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'center',
            gap: '0.3rem',
          }}
        >
          {props.deniedStudentList.map((val, idx) => {
            if (idx + 1 == props.deniedStudentList.length) {
              return <span key={'deniedNames' + idx}>{val.name}</span>;
            }
            return <span key={'deniedNames' + idx}>{val.name + ', '}</span>;
          })}
        </Box>
        <Box
          style={{marginTop: '1rem', display: 'flex', justifyContent: 'center'}}
        >
          <Button
            style={{
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: '#35D2BB',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 6rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => props.cancelEvent()}
          >
            확인
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  cancelEvent: PropTypes.func,
  deniedStudentList: PropTypes.array,
};
export default ItemView;
