import React, {useState, useEffect} from 'react';
import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';
const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '28rem',
          textAlign: 'left',
          borderRadius: '20px',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
          maxHeight: '70vh',
        }}
      >
        <Box
          style={{
            marginBottom: '1rem',
            marginTop: '0.5rem',
          }}
        >
          <div
            style={{
              maxWidth: '100%',
              overflowX: 'hidden',
              maxHeight: 'calc(70vh - 7rem)',
              overflowY: 'auto',
            }}
            dangerouslySetInnerHTML={{__html: props.popupInfo.content}}
          ></div>
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '3rem',
          }}
        >
          <Button
            style={{
              border: '1px solid black',
              width: '47%',
              color: 'black',
              fontSize: 'calc(1rem + 0.2vw)',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
            }}
            onClick={() => {
              props.cancelEvent();
            }}
          >
            닫기
          </Button>
          <Button
            style={{
              backgroundColor: '#4db9b6',
              width: '47%',
              color: 'white',
              fontSize: 'calc(1rem + 0.2vw)',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
            }}
            onClick={() => {
              props.cancelDayEvent();
            }}
          >
            하루동안 보지 않기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  popupInfo: PropTypes.object,
  cancelEvent: PropTypes.func,
  cancelDayEvent: PropTypes.func,
};
export default ItemView;
