import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Tooltip, IconButton} from '@mui/material';
import AddImg from 'asset/image/icon_comment_add.png';
import WandImg from 'asset/imageV2/icon_wand1.svg';
import PlusImg from 'asset/imageV2/icon_plus1.svg';

const ItemView = props => {
  function showWand() {
    if (props.paragraphIndex != 0) {
      return (
        <Tooltip title="[문단 수정 제안] AI가 이 문단을 자연스럽게 고쳐드려요!">
          <span>
            <IconButton
              onClick={() => {
                props.refineEvent(props.paragraphIndex, props.idx);
              }}
              style={{
                height: '3rem',
                width: '3rem',
                padding: 0,
                marginLeft: '1rem',
              }}
            >
              <img
                src={WandImg}
                alt={'wandImg'}
                style={{
                  objectFit: 'contain',
                  width: '3rem',
                  height: '3rem',
                  backgroundColor: 'white',
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
      );
    }
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        spacing: '10rem',
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Tooltip title="[첨삭 추가] 새로운 평가를 더해보세요.">
          <span>
            <IconButton
              onClick={() => {
                props.addEvent(props.paragraphIndex, props.idx);
              }}
              style={{
                height: '3rem',
                width: '3rem',
                padding: 0,
              }}
            >
              <img
                src={PlusImg}
                alt={'PlusImg'}
                style={{
                  objectFit: 'contain',
                  width: '3rem',
                  height: '3rem',
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
        {showWand()}
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  paragraphIndex: PropTypes.number,
  idx: PropTypes.number,
  addEvent: PropTypes.func,
  refineEvent: PropTypes.func,
};
export default ItemView;
