import React from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {ToggleButton, Box} from '@mui/material';

const CustomToggleButton = styled(ToggleButton)({
  height: '2.5rem',
  width: '2.5rem',
  fontSize: '1.125rem',
  color: '#A8B1B7',
  backgroundColor: '#E9EBED',
  borderRadius: '6.25rem',
  border: '1px solid #E9EBED !important',
  fontWeight: 'bold',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#35D2BB',
  },
});
const ItemView = props => {
  function handleToggle(newItem) {
    if (newItem != null) {
      props.handleChange(newItem);
    }
  }
  return (
    <Box
      style={{
        display: 'flex',
        whiteSpace: 'nowrap',
        justifyContent: 'space-between',
        backgroundColor: '#F7F8FA',
        padding: '0.8rem 1.5625rem',
        borderRadius: '6.25rem',
        width: '100%',
      }}
    >
      <CustomToggleButton
        aria-label="toggle A+"
        selected={props.toggleVal == 'A+'}
        value={'A+'}
        onClick={() => {
          handleToggle('A+');
        }}
      >
        A+
      </CustomToggleButton>
      <CustomToggleButton
        aria-label="toggle A"
        selected={props.toggleVal == 'A'}
        value={'A'}
        onClick={() => {
          handleToggle('A');
        }}
      >
        A
      </CustomToggleButton>
      <CustomToggleButton
        aria-label="toggle B+"
        selected={props.toggleVal == 'B+'}
        value={'B+'}
        onClick={() => {
          handleToggle('B+');
        }}
      >
        B+
      </CustomToggleButton>
      <CustomToggleButton
        aria-label="toggle B"
        selected={props.toggleVal == 'B'}
        value={'B'}
        onClick={() => {
          handleToggle('B');
        }}
      >
        B
      </CustomToggleButton>
      <CustomToggleButton
        aria-label="toggle C+"
        selected={props.toggleVal == 'C+'}
        value={'C+'}
        onClick={() => {
          handleToggle('C+');
        }}
      >
        C+
      </CustomToggleButton>
      <CustomToggleButton
        aria-label="toggle C"
        selected={props.toggleVal == 'C'}
        value={'C'}
        onClick={() => {
          handleToggle('C');
        }}
      >
        C
      </CustomToggleButton>
      <CustomToggleButton
        aria-label="toggle D+"
        selected={props.toggleVal == 'D+'}
        value={'D+'}
        onClick={() => {
          handleToggle('D+');
        }}
      >
        D+
      </CustomToggleButton>
      <CustomToggleButton
        aria-label="toggle D"
        selected={props.toggleVal == 'D'}
        value={'D'}
        onClick={() => {
          handleToggle('D');
        }}
      >
        D
      </CustomToggleButton>
      <CustomToggleButton
        aria-label="toggle F"
        selected={props.toggleVal == 'F'}
        value={'F'}
        onClick={() => {
          handleToggle('F');
        }}
      >
        F
      </CustomToggleButton>

      {/* <CustomToggleButton value="A" aria-label="toggle A">
        A
      </CustomToggleButton>
      <CustomToggleButton value="B+" aria-label="toggle B+">
        B+
      </CustomToggleButton>
      <CustomToggleButton value="B" aria-label="toggle B">
        B
      </CustomToggleButton>
      <CustomToggleButton value="C+" aria-label="toggle C+">
        C+
      </CustomToggleButton>
      <CustomToggleButton value="C" aria-label="toggle C">
        C
      </CustomToggleButton>
      <CustomToggleButton value="D+" aria-label="toggle D+">
        D+
      </CustomToggleButton>
      <CustomToggleButton value="D" aria-label="toggle D">
        D
      </CustomToggleButton>
      <CustomToggleButton value="F" aria-label="toggle F">
        F
      </CustomToggleButton> */}
    </Box>
  );
};
ItemView.propTypes = {
  toggleVal: PropTypes.string,
  handleChange: PropTypes.func,
};
export default ItemView;
