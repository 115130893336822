import React from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';

const CustomBar = props => {
  const percentage = props.total == 0 ? 0 : (props.value / props.total) * 100;
  return (
    <Box
      style={{
        flex: 1,
        backgroundColor: '#E9EBED',
        display: 'relative',
        height: '0.6rem',
        borderRadius: '1.125rem',
      }}
    >
      <Box
        style={{
          display: 'absolute',
          backgroundColor: props.color,
          borderRadius: '1.125rem',
          width: percentage + '%',
          height: '0.6rem',
          top: '0',
          left: '0',
        }}
      ></Box>
    </Box>
  );
};

CustomBar.propTypes = {
  value: PropTypes.number,
  total: PropTypes.number,
  color: PropTypes.string,
};

export default CustomBar;
