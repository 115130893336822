import React, {useState, useEffect} from 'react';
import {Box, Button, IconButton, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import TaskCreateSub1 from 'view/popup/TaskCreateSub1';
import TaskCreateSub2 from 'view/popup/TaskCreateSub2';
import TaskCreateSub3 from 'view/popup/TaskCreateSub3';
import APIUtils from 'common/utils/APIUtils';
import {useSelector} from 'react-redux';
import {StringUtils} from 'common/utils/StringUtils';
import writingSelectOption from 'asset/json/writing_select_option1.json';

const ItemView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const [createStep, setCreateStep] = useState(1);
  const [titleText, setTitleText] = useState('');
  const handleTitle = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 25) {
      inputVal = inputVal.substring(0, 25);
    }
    setTitleText(inputVal);
  };
  const [memoText, setMemoText] = useState('');
  const handleMemo = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 1000) {
      inputVal = inputVal.substring(0, 1000);
    }
    setMemoText(inputVal);
  };

  const today = new Date();
  const [maxDate, setMaxDate] = useState(new Date());
  const [passEndDate, setPassEndDate] = useState(new Date());

  const [startDate, setStartDate] = useState(new Date());
  const handleStartDateChange = newDate => {
    setStartDate(newDate);
    const newMaxDate = StringUtils.getDatePlusMoth(newDate, 3);
    const smallerMax = new Date(Math.min(newMaxDate, passEndDate));
    setMaxDate(smallerMax);
    const newEndDate = StringUtils.getDatePlusDate(newDate, 7);
    if (smallerMax < newEndDate) {
      setEndDate(smallerMax);
    } else {
      setEndDate(newEndDate);
    }
  };

  const [endDate, setEndDate] = useState(new Date());
  const handleEndDateChange = newDate => {
    setEndDate(newDate);
  };

  const [writingSelect, setWritingSelect] = useState(0);

  const handleWritingSelectInput = event => {
    setWritingSelect(event.target.value);
  };
  const [feedbackChecked, setFeedbackChecked] = useState(true);

  const handleFeedbackChange = event => {
    setFeedbackChecked(event.target.checked);
  };
  const [keewichatChecked, setKeewichatChecked] = useState(true);

  const handleKeewichatChange = event => {
    setKeewichatChecked(event.target.checked);
  };

  const [textCountChecked, setTextCountChecked] = useState(false);
  const handleTextCountChange = event => {
    setTextCountChecked(event.target.checked);
  };
  const [textCountMin, setTextCountMin] = useState('0');
  const [textCountMax, setTextCountMax] = useState('10000');

  const [requiredWordChecked, setRequiredWordChecked] = useState(false);
  const handleRequiredWordChange = event => {
    setRequiredWordChecked(event.target.checked);
  };
  const [requiredWord, setRequiredWord] = useState('');

  const [totalStudentCount, setTotalStudentCount] = useState([]);
  const [classList, setClassList] = useState([]);
  const [selectedClassList, setSelectedClassList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedStudentList, setSelectedStudentList] = useState([]);
  function classCheckHandle(index) {
    const selectedVal = classList[index];
    if (selectedClassList.includes(selectedVal.class_id)) {
      const tempClassList = [...selectedClassList];
      setSelectedClassList(
        tempClassList.filter(val => val != selectedVal.class_id),
      );
    } else {
      const tempClassList = [...selectedClassList];
      tempClassList.push(selectedVal.class_id);
      setSelectedClassList(tempClassList);
    }
  }
  function studentCheckHandle(index) {
    const selectedVal = studentList[index];
    if (selectedStudentList.includes(selectedVal.student_id)) {
      const tempStudentList = [...selectedStudentList];
      setSelectedStudentList(
        tempStudentList.filter(val => val != selectedVal.student_id),
      );
    } else {
      const tempStudentList = [...selectedStudentList];
      tempStudentList.push(selectedVal.student_id);
      setSelectedStudentList(tempStudentList);
    }
  }

  function tryCreateTask() {
    props.confirmEvent(
      titleText,
      memoText,
      writingSelectOption[writingSelect],
      startDate,
      endDate,
      feedbackChecked ? 1 : 2,
      selectedClassList,
      selectedStudentList,
      textCountChecked ? 1 : 0,
      textCountChecked && textCountMin != '' ? textCountMin : 0,
      textCountChecked && textCountMax != '' ? textCountMax : 0,
      requiredWordChecked ? 1 : 0,
      requiredWordChecked
        ? requiredWord
            .split(',')
            .map(val => val.trim())
            .filter(val => val != '')
            .slice(0, 5)
            .join(',')
        : '',
      keewichatChecked ? 1 : 0,
    );
  }
  function getStudentAndClass() {
    const getStudentApi = async () => {
      try {
        const response = await APIUtils.ClassStudent(authReducer.user_id);
        setStudentList(
          response.data.student_list.sort((a, b) => {
            if (a.student_name > b.student_name) {
              return 1;
            }
            if (a.student_name < b.student_name) {
              return -1;
            }
            return 0;
          }),
        );
      } catch (err) {
        console.log(err);
      }
    };
    getStudentApi();
    const getClassApi = async () => {
      try {
        const response = await APIUtils.ClassView(authReducer.user_id);
        setClassList(response.data.class_list);
      } catch (err) {
        console.log(err);
      }
    };
    getClassApi();
  }
  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.PassCheck(authReducer.user_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          if (response.data.status != 'none') {
            const threeMonth = new Date(today.setMonth(today.getMonth() + 3));
            const passEnd = new Date(response.data.end_time);
            if (threeMonth < passEnd) {
              setMaxDate(threeMonth);
            } else {
              setMaxDate(passEnd);
            }
            setPassEndDate(passEnd);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }

  function showCreatePage() {
    if (createStep == 1) {
      return (
        <TaskCreateSub1
          titleText={titleText}
          handleTitle={handleTitle}
          memoText={memoText}
          handleMemo={handleMemo}
          startDate={startDate}
          startDateChange={handleStartDateChange}
          startDateMax={passEndDate}
          endDate={endDate}
          endDateChange={handleEndDateChange}
          endDateMax={maxDate}
          writingSelect={writingSelect}
          handleWritingSelectInput={handleWritingSelectInput}
          cancelEvent={props.cancelEvent}
          nextStepEvent={() => {
            setCreateStep(2);
          }}
        />
      );
    } else if (createStep == 2) {
      return (
        <TaskCreateSub2
          titleText={titleText}
          feedbackChecked={feedbackChecked}
          handleFeedbackChange={handleFeedbackChange}
          keewichatChecked={keewichatChecked}
          handleKeewichatChange={handleKeewichatChange}
          textCountChecked={textCountChecked}
          handleTextCountChange={handleTextCountChange}
          textCountMin={textCountMin + ''}
          handleTextCountMin={event => {
            let inputVal = event.target.value;
            const reg = new RegExp('^[0-9]*$');
            if (reg.test(inputVal)) {
              if (Number(inputVal) > 10000) {
                setTextCountMin('10000');
                if (
                  textCountMax == '' ||
                  Number(textCountMax) < Number(inputVal)
                ) {
                  setTextCountMax('10000');
                }
              } else {
                setTextCountMin(inputVal);
                if (
                  textCountMax == '' ||
                  Number(textCountMax) < Number(inputVal)
                ) {
                  setTextCountMax(inputVal);
                }
              }
            }
          }}
          textCountMax={textCountMax + ''}
          handleTextCountMax={event => {
            let inputVal = event.target.value;
            const reg = new RegExp('^[0-9]*$');
            if (reg.test(inputVal)) {
              if (Number(inputVal) > 10000) {
                setTextCountMax('10000');
                if (
                  textCountMax == '' ||
                  Number(textCountMin) > Number(inputVal)
                ) {
                  setTextCountMin(inputVal);
                }
              } else {
                setTextCountMax(inputVal);
                if (
                  textCountMin == '' ||
                  Number(textCountMin) > Number(inputVal)
                ) {
                  setTextCountMin(inputVal);
                }
              }
            }
          }}
          requiredWordChecked={requiredWordChecked}
          handleRequiredWordChange={handleRequiredWordChange}
          requiredWord={requiredWord}
          handelRequiredWord={event => {
            let inputVal = event.target.value;
            setRequiredWord(inputVal);
          }}
          previousEvent={() => {
            setCreateStep(1);
          }}
          nextEvent={() => {
            setCreateStep(3);
          }}
          cancelEvent={props.cancelEvent}
        />
      );
    } else {
      return (
        <TaskCreateSub3
          titleText={titleText}
          totalStudentCount={totalStudentCount}
          classList={classList}
          selectedClassList={selectedClassList}
          classCheckEvent={classCheckHandle}
          studentList={studentList}
          selectedStudentList={selectedStudentList}
          studentCheckEvent={studentCheckHandle}
          previousEvent={() => {
            setCreateStep(2);
          }}
          cancelEvent={props.cancelEvent}
          confirmEvent={tryCreateTask}
        />
      );
    }
  }
  useEffect(() => {
    getStudentAndClass();
    getPassInfo();
  }, [props.visibility]);
  useEffect(() => {
    if (props.selectedLibrary != undefined) {
      setCreateStep(1);
      setTitleText(props.selectedLibrary.task_name);
      setMemoText(props.selectedLibrary.task_message);
      setWritingSelect(
        writingSelectOption.indexOf(props.selectedLibrary.task_janre),
      );
      setStartDate(new Date());
      setEndDate(StringUtils.getDatePlusDate(new Date(), 7));
      setFeedbackChecked(true);
      setSelectedClassList([]);
      setSelectedStudentList([]);
      setTextCountChecked(false);
      setTextCountMin(0);
      setTextCountMax(10000);
      setRequiredWordChecked(false);
      setRequiredWord('');
      setKeewichatChecked(true);
    }
  }, [props.selectedLibrary]);
  useEffect(() => {
    const totalStudentList = classList
      .filter(val => selectedClassList.includes(val.class_id))
      .map(val => {
        return val.student_list.map(item => item.student_id);
      })
      .flat()
      .concat(selectedStudentList);
    const uniqueTotalStudentList = [...new Set(totalStudentList)];
    setTotalStudentCount(uniqueTotalStudentList.length);
  }, [selectedClassList, selectedStudentList]);

  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        {showCreatePage()}
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  selectedLibrary: PropTypes.object,
  cancelEvent: PropTypes.func,
  confirmEvent: PropTypes.func,
};
export default ItemView;
