import PropTypes from 'prop-types';
import {useRef} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const BarChart = props => {
  const barChart = useRef();

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          // drawBorder: false,
          display: false,
        },
        ticks: {
          font: {
            size: '15',
          },
        },
      },
      y: {
        ticks: {display: false},
        grid: {
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        callbacks: {
          title: item => {
            return item[0].label + '점';
          },
          label: item => {
            return props.labelData[item.dataIndex];
          },
        },
      },
    },
  };

  return (
    <Bar
      id={'barChart'}
      ref={barChart}
      data={props.barData}
      options={options}
    />
  );
};

BarChart.propTypes = {
  barData: PropTypes.object,
  labelData: PropTypes.array,
};

export default BarChart;
