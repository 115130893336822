import PropTypes from 'prop-types';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ArrowDown from 'asset/image/icon_arrow_down.png';

export const NoticeItem = props => {
  return (
    <Box style={{marginTop: '0.5rem'}}>
      <Accordion>
        <AccordionSummary
          style={{paddingRight: '1rem'}}
          expandIcon={
            <img
              src={ArrowDown}
              alt={'iconArrow'}
              style={{
                width: '2rem',
                height: '2rem',
                transform: 'rotate(180deg)',
              }}
            />
          }
        >
          <Box
            style={{fontSize: '1.1rem', fontWeight: 'bold', padding: '1rem'}}
          >
            {props.title}
            <Box
              style={{
                fontSize: '0.8rem',
                marginTop: '1rem',
                fontWeight: 'normal',
              }}
            >
              {props.time}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          style={{
            paddingTop: '1rem',
            paddingBottom: '1rem',
            backgroundColor: '#f3f3f3',
            whiteSpace: 'pre-wrap',
            fontSize: '0.9rem',
            overflowX: 'hidden',
            overflowY: 'scroll',
          }}
        >
          <div dangerouslySetInnerHTML={{__html: props.content}}>
            {/* {props.content} */}
          </div>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
NoticeItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  time: PropTypes.string,
};
