import React from 'react';
import {Box} from '@mui/material';

const personalStyle1 = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginTop: '3rem',
};
const personalStyle2 = {
  fontSize: '1rem',
  marginTop: '1rem',
};

const personalStyle3 = {
  fontSize: '1rem',
  marginTop: '1rem',
  marginLeft: '2rem',
};
const personalStyle4 = {
  fontSize: '1rem',
  marginTop: '1rem',
  marginLeft: '3rem',
};
const personalStyle5 = {
  fontSize: '1rem',
  marginTop: '1rem',
  marginLeft: '4rem',
};
const PersonalPage = () => {
  return (
    <Box style={{width: '100%', minHeight: '100vh', backgroundColor: 'white'}}>
      <Box
        style={{
          paddingTop: '3rem',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '10rem',
        }}
      >
        <Box style={personalStyle2}>
          {
            '“KEEwiT, 키위티” (이하 “서비스”)를 운영하는 주식회사 투블럭에이아이(이하 “회사”)는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립․공개합니다.'
          }
        </Box>
        <Box style={personalStyle2}>
          {
            '본 개인정보처리방침은 서비스에 회원 가입한 이용자에 대하여 적용되며, 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 회사가 개인정보 보호를 위하여 어떠한 조치를 취하고 있는지 알리기 위한 목적으로 작성되었습니다.'
          }
        </Box>
        <Box style={personalStyle1}>{'제1조 개인정보의 처리 목적 '}</Box>
        <Box style={personalStyle2}>
          {
            '회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 ｢개인정보 보호법｣ 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다. '
          }
        </Box>
        <Box style={personalStyle2}>{'① 서비스 회원가입 및 관리 '}</Box>
        <Box style={personalStyle2}>
          {
            '회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별․인증, 회원자격 유지․관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보처리시 법정대리인의 동의여부 확인, 각종 고지․통지, 고충처리 등을 목적으로 개인정보를 처리합니다.'
          }
        </Box>
        <Box style={personalStyle2}>{'② 재화 또는 서비스 제공 '}</Box>
        <Box style={personalStyle2}>
          {
            '서비스 제공, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제 정산, 채권추심 등을 목적으로 개인정보를 처리합니다. '
          }
        </Box>
        <Box style={personalStyle2}>{'③ 고충처리 '}</Box>
        <Box style={personalStyle2}>
          {
            '민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락․통지, 처리결과 통보의 목적으로 개인정보를 처리합니다. '
          }
        </Box>
        <Box style={personalStyle1}>{'제2조 개인정보의 처리 및 보유 기간'}</Box>
        <Box style={personalStyle2}>
          {
            '① 회사는 법령에 따른 개인정보 보유․이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유․이용기간 내에서 개인정보를 처리․보유합니다. '
          }
        </Box>
        <Box style={personalStyle2}>
          {'② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다. '}
        </Box>
        <Box style={personalStyle3}>
          {'1. 서비스 회원 가입 및 관리 : 서비스 탈퇴 시까지 '}
        </Box>
        <Box style={personalStyle4}>
          {'다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지 '}
        </Box>
        <Box style={personalStyle5}>
          {
            '- 관계 법령 위반에 따른 수사․조사 등이 진행 중인 경우에는 해당 수사․조사 종료시 까지 '
          }
        </Box>
        <Box style={personalStyle5}>
          {
            '- 서비스 이용에 따른 채권․채무관계 잔존 시에는 해당 채권․채무관계 정산시까지 '
          }
        </Box>
        <Box style={personalStyle3}>
          {
            '2. 재화 또는 서비스 제공 : 재화․서비스 공급완료 및 요금결제․정산 완료시까지'
          }
        </Box>
        <Box style={personalStyle4}>
          {'다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지 '}
        </Box>
        <Box style={personalStyle5}>{'- 표시․광고에 관한 기록 : 6월 '}</Box>
        <Box style={personalStyle5}>
          {'- 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년 '}
        </Box>
        <Box style={personalStyle5}>
          {'- 소비자 불만 또는 분쟁처리에 관한 기록 : 3년 '}
        </Box>
        <Box style={personalStyle5}>
          {'- 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년'}
        </Box>
        <Box style={personalStyle5}>
          {'- 대금결제 및 재화 등의 공급에 관한 기록 : 5년'}
        </Box>
        <Box style={personalStyle1}>
          {'제3조 정보주체와 법정대리인의 권리·의무 및 그 행사방법'}
        </Box>
        <Box style={personalStyle2}>
          {
            '① 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.'
          }
        </Box>
        <Box style={personalStyle2}>
          {
            '② 제1항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 서비스는 이에 대해 지체 없이 조치하겠습니다.'
          }
        </Box>
        <Box style={personalStyle2}>
          {
            '③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우“개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. '
          }
        </Box>
        <Box style={personalStyle2}>
          {
            '④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.'
          }
        </Box>
        <Box style={personalStyle2}>
          {
            '⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.'
          }
        </Box>
        <Box style={personalStyle2}>
          {
            '⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.'
          }
        </Box>
        <Box style={personalStyle1}>
          {'제4조 처리하는 개인정보 항목 및 수집방법'}
        </Box>
        <Box style={personalStyle2}>
          {
            '① 회사는 원활한 서비스 제공과 서비스 상담을 위해 아래와 같은 최소한의 개인정보를 수집하고 있습니다. 거래 정보는 결제 발생시에만 수집하며, 인터넷 서비스 이용 정보는 자동으로 생성되어 수집될 수 있습니다. '
          }
        </Box>
        <Box style={personalStyle3}>
          {'1. 필수항목: 소셜 계정을 통해 수집한 이메일주소(회원ID), 닉네임'}
        </Box>
        <Box style={personalStyle3}>
          {
            '2. 거래정보: 신용카드정보(신용카드번호, 유효기간, 비밀번호 앞 두 자리), 세금계산서 발행을 위한 회계 담당자 정보(이름, 연락처, 이메일주소) 등'
          }
        </Box>
        <Box style={personalStyle3}>
          {
            '3. 인터넷 서비스 이용 정보 : IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등'
          }
        </Box>
        <Box style={personalStyle2}>
          {'② 위 개인정보 항목의 수집방법은 다음과 같습니다.'}
        </Box>
        <Box style={personalStyle3}>
          {
            '1. 서비스 홈페이지, 서면양식, 전화, 이메일, 이벤트 응모, 팩스, 상담 툴, 배송요청'
          }
        </Box>
        <Box style={personalStyle3}>{'2. 협력회사로부터의 제공'}</Box>
        <Box style={personalStyle3}>{'3. 생성정보 수집 툴을 통한 수집'}</Box>
        <Box style={personalStyle1}>{'제5조 개인정보의 파기'}</Box>
        <Box style={personalStyle2}>
          {
            '① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.  '
          }
        </Box>
        <Box style={personalStyle2}>
          {
            '② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. '
          }
        </Box>
        <Box style={personalStyle2}>
          {'③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.'}
        </Box>
        <Box style={personalStyle3}>{'1. 파기절차'}</Box>
        <Box style={personalStyle4}>
          {
            '회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. '
          }
        </Box>
        <Box style={personalStyle3}>{'2. 파기방법'}</Box>
        <Box style={personalStyle4}>
          {
            '서비스는 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록․저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다. '
          }
        </Box>
        <Box style={personalStyle1}>{'제6조 개인정보의 안전성 확보조치'}</Box>
        <Box style={personalStyle2}>
          {
            '회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.'
          }
        </Box>
        <Box style={personalStyle2}> </Box>
        <Box style={personalStyle2}>
          {'① 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등 '}
        </Box>
        <Box style={personalStyle2}>
          {
            '② 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치 '
          }
        </Box>
        <Box style={personalStyle2}>
          {'③ 물리적 조치 : 전산실, 자료보관실 등의 접근통제 '}
        </Box>
        <Box style={personalStyle1}>
          {'제7조 개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항 '}
        </Box>
        <Box style={personalStyle2}>
          {
            '① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.'
          }
        </Box>
        <Box style={personalStyle2}>
          {
            '② 쿠키는 서비스를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.'
          }
        </Box>
        <Box style={personalStyle2}>
          {
            '③ 회사는 쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 보다 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에 활용합니다.'
          }
        </Box>
        <Box style={personalStyle2}>
          {
            '④ 이용자는 쿠키 설정에 대한 선택권을 가지고 있습니다. 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.'
          }
        </Box>
        <Box style={personalStyle2}>
          {
            '⑤ 다만, 쿠키의 저장을 거부할 경우에는 맞춤형 서비스 이용에 어려움이 발생할 수 있으며, 이와 관련하여 회사는 책임지지 않습니다.'
          }
        </Box>
        <Box style={personalStyle1}>{'제8조 개인정보 열람 청구'}</Box>
        <Box style={personalStyle2}>
          {
            '① 정보주체 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한 경우 서비스의 일부 또는 전부 이용이 어려울 수 있습니다.'
          }
        </Box>
        <Box style={personalStyle2}>
          {
            '② 정보주체 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 "개인정보변경"(또는 "회원정보수정" 등)을, 가입해지(동의철회)를 위해서는 서비스에 공개된 회사 연락처에 직접 연락하거나 서비스내 회원 탈퇴 기능을 통해 파기할 수 있습니다.'
          }
        </Box>
        <Box style={personalStyle2}>
          {
            '③ 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.'
          }
        </Box>
        <Box style={personalStyle1}>
          {'제9조 개인정보 보호책임자 및 담당자의 연락처'}
        </Box>
        <Box style={personalStyle2}>
          {
            '회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.'
          }
        </Box>
        <Box style={personalStyle3}>{'개인정보 보호책임자 '}</Box>
        <Box style={personalStyle3}>{'성명 : 류기령'}</Box>
        <Box style={personalStyle3}>{'직책 : 서비스운영이사'}</Box>
        <Box style={personalStyle3}>{'연락처 : support@twoblockai.com'}</Box>
        <Box style={personalStyle2}>
          {
            '기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.'
          }
        </Box>
        <Box style={personalStyle3}>
          {'- 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)'}
        </Box>
        <Box style={personalStyle3}>
          {'- 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)'}
        </Box>
        <Box style={personalStyle3}>
          {'- 경찰청 사이버안전국 (www.police.go.kr / 국번없이 182)'}
        </Box>
        <Box style={personalStyle1}>{'제10조 고지의 의무'}</Box>
        <Box style={personalStyle2}>
          {
            '현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 이메일을 통해 고지할 것입니다. 다만 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.'
          }
        </Box>
        <Box style={personalStyle2}>{'부칙'}</Box>
        <Box style={personalStyle2}>
          {'이 개인정보 처리방침은 <2023년 1월 12일>부터 시행합니다.'}
        </Box>
      </Box>
    </Box>
  );
};
export default PersonalPage;
